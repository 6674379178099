export const WhatsappIconSvg: React.FC = () => {
  return (
    <svg
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1707480176" filter="url(#filter0_d_1181_17550)">
        <g clipPath="url(#clip0_1181_17550)">
          <rect x="24" y="18" width="60" height="60" rx="30" fill="white" />
          <g id="Group 99211">
            <g id="Group 99210" filter="url(#filter1_d_1181_17550)">
              <path
                id="shape 3"
                d="M36.7637 65.2392L39.2003 56.3823C37.315 53.1249 36.5621 49.3394 37.0582 45.6128C37.5544 41.8861 39.2719 38.4265 41.9444 35.7701C44.617 33.1138 48.0954 31.4091 51.8404 30.9204C55.5853 30.4317 59.3877 31.1862 62.658 33.067C65.9283 34.9478 68.4839 37.8498 69.9286 41.3231C71.3732 44.7965 71.6263 48.6472 70.6486 52.2783C69.6708 55.9093 67.5169 59.118 64.5206 61.4068C61.5244 63.6955 57.8532 64.9366 54.0761 64.9376C51.21 64.9372 48.3895 64.2224 45.8723 62.8584L36.7637 65.2392Z"
                fill="url(#paint0_linear_1181_17550)"
              />
              <g id="Group 14495">
                <g id="Group 14494">
                  <path
                    id="Path 11713"
                    d="M36.1426 65.8571L38.666 56.6882C36.7101 53.3132 35.9276 49.3903 36.4399 45.5276C36.9522 41.665 38.7305 38.0785 41.4993 35.3242C44.268 32.5699 47.8724 30.8017 51.7536 30.2939C55.6348 29.786 59.5759 30.5668 62.9659 32.5152C66.3558 34.4636 69.0052 37.4707 70.5032 41.0703C72.0012 44.6699 72.2641 48.6608 71.2511 52.4242C70.2382 56.1877 68.006 59.5134 64.9007 61.8856C61.7954 64.2579 57.9904 65.5442 54.0758 65.545C51.1068 65.5453 48.185 64.8051 45.5774 63.392L36.1426 65.8571ZM46.0054 60.1929L46.5457 60.5113C48.823 61.8533 51.4209 62.5613 54.0674 62.5611C57.3437 62.5603 60.5269 61.476 63.1166 59.4785C65.7062 57.481 67.5556 54.6837 68.3739 51.5261C69.1923 48.3686 68.9331 45.0299 67.6373 42.0349C66.3415 39.0399 64.0824 36.5584 61.2153 34.9805C58.3481 33.4025 55.0354 32.8176 51.7978 33.3176C48.5602 33.8176 45.5813 35.3742 43.3295 37.7428C41.0776 40.1114 39.6804 43.1576 39.3574 46.4026C39.0345 49.6476 39.8042 52.9074 41.5454 55.6696L41.8971 56.2263L40.414 61.6522L46.0054 60.1929Z"
                    fill="url(#paint1_linear_1181_17550)"
                  />
                </g>
              </g>
              <g id="Group 14496">
                <path
                  id="Path 11714"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M49.6329 40.4463C49.3002 39.7104 48.9506 39.6956 48.6328 39.6829C48.3743 39.6724 48.0777 39.6829 47.7853 39.6829C47.5603 39.6888 47.3389 39.741 47.1351 39.836C46.9313 39.9311 46.7494 40.0671 46.6009 40.2354C46.0985 40.709 45.7008 41.2815 45.433 41.9165C45.1652 42.5516 45.0333 43.2351 45.0457 43.9237C45.1903 45.5915 45.8206 47.181 46.8594 48.4976C48.7055 51.3908 51.3367 53.7047 54.4488 55.1719C58.2012 56.648 58.966 56.3528 59.7818 56.279C60.4026 56.1577 60.9907 55.9072 61.5074 55.5437C62.0241 55.1803 62.4577 54.7122 62.7798 54.1702C63.0715 53.5076 63.1613 52.7744 63.0383 52.0615C62.9281 51.878 62.6315 51.7662 62.1908 51.5448C61.7501 51.3234 59.5614 50.2542 59.1546 50.1066C58.7478 49.959 58.4512 49.8957 58.1545 50.328C57.8579 50.7603 57.0083 51.7662 56.7477 52.0615C56.4871 52.3567 56.2286 52.3946 55.7858 52.1732C54.4761 51.6533 53.2673 50.9104 52.2135 49.978C51.2417 49.0846 50.4085 48.0527 49.7409 46.916C49.4824 46.4732 49.7134 46.2349 49.9358 46.0135C50.1583 45.7921 50.3808 45.4968 50.6033 45.2396C50.786 45.0158 50.9359 44.7673 51.0482 44.5015C51.1073 44.3797 51.1349 44.2451 51.1286 44.11C51.1224 43.9748 51.0823 43.8434 51.0122 43.7276C50.8914 43.5083 50.0291 41.3215 49.6329 40.4463Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1181_17550"
          x="0"
          y="0"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1181_17550"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1181_17550"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1181_17550"
          x="35.1426"
          y="29.1428"
          width="37.7139"
          height="37.7144"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1181_17550"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1181_17550"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1181_17550"
          x1="53.9986"
          y1="65.2392"
          x2="53.9986"
          y2="30.7754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1181_17550"
          x1="53.9997"
          y1="65.8571"
          x2="53.9997"
          y2="30.1428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1181_17550">
          <rect x="24" y="18" width="60" height="60" rx="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
