export const FaqData = [
  {
    question: "What is Make My Wedding?",
    answer:
      "Make My Wedding is a new service offered by Matrimony.com designed to make wedding planning easy and stress-free. It connects you with top wedding service providers, including makeup artists, photographers, caterers, and decorators, through a dedicated Relationship Manager who handles everything from vendor selection to negotiating the best deals.",
  },
  {
    question: "How does Make My Wedding work?",
    steps: [
      "Contact your dedicated Make My Wedding Relationship Manager (RM).",
      "Share your wedding requirements, budget, date, and specific preferences.",
      "Your RM will provide a list of curated vendors that meet your criteria.",
      "You choose the vendor and service that suits you best and make the payment directly to the vendor.",
    ],
  },
  {
    question:
      "What makes Make My Wedding different from planning the wedding on my own?",
    answer:
      "Make My Wedding offers a personal touch by assigning you a dedicated RM who understands your specific needs, including local traditions and preferences. Your RM handles vendor shortlisting, negotiations, and ensures you get the best deals, saving you time and effort.",
  },
  {
    question: "What is the cost of the Make My Wedding service?",
    answer:
      "For help with one service: ₹999. For assistance with two services: ₹1,999. For the full package: ₹3,999.",
  },
  {
    question: "What exclusive offers are available to Make My Wedding customers?",
    answer:
      "As a Make My Wedding customer, you get exclusive deals from top brands in jewelry, clothing, electronics, sweets, and more. These partnerships ensure you receive the best prices and services, all from the comfort of your home.",
  },
  {
    question: "What should I do if I’m interested in the service?",
    answer:
      "If you’re interested, your next step is to schedule a follow-up call with a Relationship Manager. They will discuss your specific wedding needs and help you start planning your dream wedding.",
  },
  {
    question: "Who can I contact for more information?",
    answer:
      "For more details or to get started, you can contact your Make My Wedding Relationship Manager, who will guide you through the process and answer any further questions you may have.",
  },
];
