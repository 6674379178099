import React, { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { FrameSvg3 } from "./FrameSvg3";
import { FrameSvg1 } from "./FrameSvg1";
import howMain from "../../assets/images/howMain.png";

const SingleStackImg: React.FC = () => {
  return (
    <Box 
    sx={{ position: "relative", width: {md:"100%", xxl:"65%"}, marginLeft: "auto" }}
    >
    <Box
      className={`color-display desk-stacked`}
    sx={{ position: "relative", width: "100%", margin: "auto" }}

    >
      <Box
        sx={{
          display: "flex",
          marginTop: "15%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "429px",
          justifyContent:"center"
        }}
      >
        {" "}
        <Box
          sx={{
            borderRadius: "24px",
            border: "4px solid #FFF99E",
            background: "linear-gradient(180deg, #FFFDF8 0%, #F5E5B8 100%)",
            width: "380px",
            height: "374px",
            zIndex:2
          }}
        >
               <Card sx={{}}>
            <img
              src={howMain}
              alt={`Stacked img`}
              style={{ width: "100%", height: "100%", objectFit: "cover", position:"absolute", left:"0%", top:"-14%" }} // Ensure image sizing is consistent
            />
          </Card>   
        </Box>

      </Box>

    </Box>
          <Box
          sx={{ position: "absolute", top: "-15%", left: "5%" }}
        >
          <FrameSvg1 />
        </Box>
        <Box
          sx={{ position: "absolute", bottom: "0%", right: "0%" }}
        >
          <FrameSvg3 />
        </Box>
        </Box>
  );
};

export default SingleStackImg;
