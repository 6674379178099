export const FrameSvg2 : React.FC = ()=>{
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="86" height="81" viewBox="0 0 86 81" fill="none">
      <g opacity="0.3" clipPath="url(#clip0_654_13377)">
        <path d="M20.4044 16.702C22.4238 23.8467 19.3863 30.8899 16.8705 37.4844C14.5146 43.6387 12.5121 50.1485 11.2584 56.6245C10.1057 62.5925 10.0972 69.1616 12.8486 74.6979C14.9521 78.9475 19.176 81.3347 23.8542 80.1835C28.8101 78.9644 30.6696 73.1149 30.2657 68.5944C29.9376 64.9035 26.0587 59.9005 21.8685 60.9587C18.1495 61.8984 16.4919 66.4273 17.0893 69.9404C17.6699 73.335 23.0296 75.172 24.5862 71.4134C25.7389 68.6114 24.2244 66.6897 21.5319 66.1564C20.3371 65.9194 20.0006 67.7818 21.1869 68.0188C21.9106 68.1627 22.6173 68.1627 23.0128 68.9838C24.1739 71.3795 20.7915 72.0483 19.4789 71.1086C18.0401 70.0759 18.3346 67.5871 18.6543 66.1226C19.1003 64.0485 20.4718 62.2285 22.6931 61.983C25.4276 61.6867 27.8425 64.6327 28.7259 66.8675C30.5349 71.4472 28.1201 77.5507 23.484 79.1676C17.9896 81.0892 14.1023 75.79 12.613 71.3033C9.4073 61.619 13.6227 50.5802 16.4246 41.3276C18.8478 33.3364 23.4167 25.125 20.6148 16.6258C20.5727 16.4904 20.3708 16.5666 20.4129 16.702H20.4044Z" fill="url(#paint0_linear_654_13377)"/>
        <path d="M20.7569 26.1919C20.21 30.577 16.6256 34.3864 14.1266 37.8318C11.9053 40.8962 10.1973 44.113 9.57464 47.8886C8.51447 54.3307 14.581 57.3697 19.865 54.4323C22.5407 52.9508 24.03 49.7171 23.6093 46.7203C23.2559 44.2315 20.3951 42.6401 18.0728 43.3427C15.8347 44.0199 14.4043 46.1278 15.9609 48.2272C17.08 49.7255 19.6294 50.0641 20.4035 48.1256C20.841 47.0336 19.3433 46.3309 18.7712 47.3468C18.3926 48.0155 15.0774 46.2378 18.0223 44.8072C19.5116 44.0792 21.3375 45.0696 22.0442 46.4241C23.3484 48.9383 21.0093 52.0196 19.0909 53.3148C17.08 54.6693 14.3202 54.7539 12.2924 53.4333C10.3824 52.1974 10.4665 49.4885 10.7694 47.5245C11.4762 42.9702 14.3117 39.6518 16.6088 35.8509C18.443 32.8118 20.8326 29.8998 20.9336 26.1919C20.9336 26.0988 20.7737 26.0904 20.7653 26.1919H20.7569Z" fill="url(#paint1_linear_654_13377)"/>
        <path d="M12.9917 50.5972C11.7549 53.103 10.4675 55.4648 8.15364 57.1155C6.92519 57.9875 5.51164 58.4192 4.04759 58.6477C0.698805 59.1557 0.850258 54.9823 3.01267 55.7441C4.84693 56.396 6.03331 56.6076 7.98536 56.1674C9.69341 55.778 11.3762 54.813 12.2513 53.2723C12.3186 53.1622 12.1756 53.0776 12.083 53.1284C9.94583 54.3643 7.29541 55.7949 4.73754 54.9315C3.88773 54.6436 2.54148 53.7802 1.58228 54.1865C-0.142599 54.9145 -0.470746 57.2848 0.656735 58.7578C2.2554 60.8572 5.43591 59.9514 7.45528 59.0371C11.1743 57.3441 11.7801 54.0765 13.1263 50.6565C13.16 50.5718 13.0338 50.5041 12.9917 50.5972Z" fill="url(#paint2_linear_654_13377)"/>
        <path d="M12.2421 53.2385C10.9884 54.9739 8.26225 55.6765 6.25971 55.9643C4.61056 56.2013 2.58278 54.6014 1.30384 56.0405C1.2197 56.1336 1.21129 56.2945 1.28702 56.3961C2.4145 57.7759 5.51928 57.8436 7.14319 57.4204C9.5496 56.7855 11.2829 55.5834 12.4524 53.3739C12.5198 53.247 12.3262 53.12 12.2421 53.2385Z" fill="url(#paint3_linear_654_13377)"/>
        <path d="M1.37183 56.3282C1.91032 58.0551 5.86492 57.488 6.7484 56.4129C6.95033 56.1674 6.84095 55.6679 6.47915 55.651C5.6714 55.6087 4.80475 56.0404 3.99701 56.1589C3.14719 56.2859 2.30578 56.1166 1.46438 56.1928C1.39707 56.1928 1.355 56.269 1.38024 56.3367L1.37183 56.3282Z" fill="url(#paint4_linear_654_13377)"/>
        <path d="M12.2512 52.5867C10.1225 57.4204 9.93735 63.5323 5.39377 66.9862C4.59443 67.5957 0.269618 68.9501 1.59904 66.2243C2.44885 64.472 5.04879 63.2445 6.57173 62.1609C9.10436 60.3578 11.0228 58.0807 11.9651 55.0755C12.0072 54.9231 11.8053 54.8723 11.7211 54.957C9.80272 56.8193 8.67524 59.0457 6.48759 60.6964C4.59443 62.1271 2.38154 63.3122 0.909085 65.2169C-0.849449 67.4856 0.379001 69.5596 3.14722 69.2041C9.70176 68.366 12.0409 58.0299 12.5625 52.6967C12.5794 52.519 12.3438 52.4258 12.2596 52.5951L12.2512 52.5867Z" fill="url(#paint5_linear_654_13377)"/>
        <path d="M11.6954 54.6013C10.7446 57.4371 9.17964 59.8752 7.20234 62.11C6.33569 63.092 5.39332 64.0062 4.50143 64.9543C3.54223 65.9786 2.38951 66.5458 1.37141 67.4431C1.08533 67.6971 1.06009 68.1796 1.44713 68.3574C3.76099 69.3986 6.159 65.7078 7.41269 64.2263C9.72655 61.492 11.4514 58.2752 12.0236 54.7113C12.0572 54.5166 11.7543 54.4066 11.687 54.6013H11.6954Z" fill="url(#paint6_linear_654_13377)"/>
        <path d="M1.51453 68.1797C5.68789 69.4156 8.97778 64.1079 10.4082 60.9334C10.5933 60.5186 10.0548 60.1884 9.76028 60.5101C7.28656 63.1936 5.41864 66.8421 1.53977 67.604C1.21162 67.6718 1.19479 68.0866 1.51453 68.1797Z" fill="url(#paint7_linear_654_13377)"/>
        <path d="M10.3159 60.9843C9.81101 64.1334 9.97929 67.3079 9.6848 70.4824C9.42396 73.3013 9.09582 76.9668 6.75671 78.863C5.03184 78.5329 4.37554 77.771 4.79624 76.5859C4.9477 75.7309 5.39364 74.9013 5.7891 74.1224C6.48747 72.7341 7.2868 71.3966 7.95992 69.9914C9.18837 67.4348 10.4168 64.7344 10.644 61.8731C10.6524 61.7461 10.4757 61.6107 10.3832 61.7546C8.02723 65.3693 6.73147 69.7713 4.72052 73.6145C4.02215 74.952 2.14582 80.2005 5.16646 80.4376C8.17869 80.6746 9.51652 77.4662 10.0718 75.0028C11.0984 70.457 10.8207 65.6148 10.6692 61.0012C10.6692 60.798 10.3495 60.7896 10.3159 60.9843Z" fill="url(#paint8_linear_654_13377)"/>
        <path d="M10.6688 61.1957C9.15425 64.6835 9.25522 68.6452 7.96787 72.2515C7.31158 74.0884 6.51224 75.7307 5.33428 77.2799C4.81261 77.9655 4.72847 78.6851 4.90516 79.4893C4.92199 79.557 5.00613 79.5909 5.06503 79.5909C9.31412 79.4131 10.2144 73.9107 10.1387 70.4991C10.1387 70.1859 9.65909 70.1097 9.5413 70.3891C8.84293 72.006 8.43906 73.7075 7.87532 75.3752C7.51351 76.4333 7.01709 77.4492 6.31031 78.3126C6.27665 78.3465 4.7453 79.9295 6.0074 78.9391C6.40286 78.6343 6.70577 78.211 7.00867 77.8216C8.5232 75.8746 9.18791 73.4366 9.86103 71.0917C10.8034 67.8326 10.3575 64.5565 10.8539 61.2465C10.8707 61.145 10.7193 61.0942 10.6772 61.1873L10.6688 61.1957Z" fill="url(#paint9_linear_654_13377)"/>
        <path d="M20.4802 27.7747C19.7566 31.1693 18.8226 34.2845 20.0763 37.6537C20.7915 39.5754 22.1293 41.4208 24.2917 41.5224C26.3279 41.624 27.3713 39.3045 26.2438 37.7807C25.4865 36.7564 23.5008 36.2062 22.5248 35.4189C20.7074 33.9544 20.1773 30.2635 20.4886 28.0541C20.5054 27.9609 20.3708 27.9102 20.3287 28.0033C18.6207 31.7195 20.4297 35.7067 23.7869 37.6961C24.0898 37.8569 24.3927 38.0008 24.704 38.1278C24.5273 38.8897 24.3591 39.6431 24.1824 40.405C23.5261 40.3542 22.9876 40.1087 22.55 39.66C21.7255 38.9489 21.2543 37.7976 20.8756 36.8072C19.8155 34.0814 20.1773 30.6106 20.6316 27.8001C20.6485 27.6901 20.4886 27.6647 20.4634 27.7662L20.4802 27.7747Z" fill="url(#paint10_linear_654_13377)"/>
        <path d="M20.1851 33.4806C20.7236 34.708 21.0013 35.9609 21.6912 37.1545C22.4064 38.3904 23.4329 39.3978 24.6614 40.1004C25.5028 40.583 26.3947 39.1946 25.5785 38.6698C24.6193 38.0434 23.7358 37.3577 22.9365 36.5281C22.0951 35.6477 21.0433 34.471 20.488 33.379C20.3955 33.2012 20.1094 33.3028 20.1935 33.489L20.1851 33.4806Z" fill="url(#paint11_linear_654_13377)"/>
        <path d="M20.8256 26.3358C19.7234 29.0955 20.346 33.7175 24.0229 33.8699C25.4028 33.9292 27.3717 32.7779 26.3284 31.2033C25.8067 30.4161 25.1083 29.9928 24.2501 29.6542C23.5602 29.3833 22.9123 29.1293 22.3233 28.6722C21.3641 27.9272 21.2547 26.5813 21.0444 25.4723C21.0275 25.3792 20.9182 25.3792 20.8929 25.4723C20.447 26.8437 20.5311 28.4013 21.7007 29.4256C22.2392 29.8997 22.7861 30.1706 23.4592 30.4161C24.0061 30.6192 26.6818 31.3303 25.4281 32.4731C23.922 33.836 22.0709 32.143 21.524 30.7885C21.0191 29.5441 20.8508 27.7495 21.0275 26.412C21.0444 26.3019 20.8761 26.2342 20.834 26.3527L20.8256 26.3358Z" fill="url(#paint12_linear_654_13377)"/>
        <path d="M20.8166 26.5388C20.6904 28.181 21.0101 29.4932 22.0534 30.8138C22.777 31.728 23.8288 32.837 25.0488 32.9724C26.2184 33.0994 26.555 31.2793 25.3854 31.1524C24.5777 31.0677 23.8372 30.2127 23.2819 29.6794C22.4237 28.8498 21.3551 27.707 21.0522 26.5134C21.0269 26.3949 20.825 26.4118 20.8166 26.5303V26.5388Z" fill="url(#paint13_linear_654_13377)"/>
        <path d="M23.417 30.3649C23.9555 31.0252 24.8053 31.7024 25.4447 30.7966C25.4952 30.7289 25.5036 30.6104 25.4279 30.5511C25.1587 30.3649 25.0156 30.2125 24.6622 30.1617C24.3004 30.1194 23.9386 30.1871 23.5684 30.1363C23.4506 30.1194 23.3496 30.2548 23.4254 30.3564L23.417 30.3649Z" fill="url(#paint14_linear_654_13377)"/>
        <path d="M12.7893 73.115C14.1188 76.535 18.0733 79.1677 21.7503 78.7529C25.4777 78.3381 27.842 75.0959 28.4647 71.5997C29.0705 68.2136 27.0595 64.7597 23.7276 63.8285C20.4125 62.8974 17.9892 64.091 17.9135 67.6803C17.9135 67.7818 18.0649 67.8072 18.107 67.7141C19.2766 64.7005 21.7924 64.1502 24.5437 65.5809C27.3709 67.0454 27.6906 70.3299 26.7398 73.0303C24.4344 79.574 16.054 78.1011 12.9744 73.0303C12.9071 72.9203 12.7388 73.0134 12.7893 73.1319V73.115Z" fill="url(#paint15_linear_654_13377)"/>
        <path d="M17.9218 67.6633C19.4784 63.8539 23.5929 62.5672 26.1339 66.2666C28.2795 69.3818 27.4297 72.7087 26.4789 76.0101C26.4031 76.2726 26.7313 76.3996 26.9164 76.2895C30.77 74.1139 30.5681 69.3225 28.616 65.7756C27.5306 63.7947 25.3766 62.5672 23.2563 62.0847C22.7515 61.9662 22.2382 62.0339 21.7418 62.0847C21.4389 62.1186 20.5554 63.1259 21.1696 63.1259C22.4738 63.1175 23.0628 62.4741 24.4679 63.4899C25.2925 64.0825 25.688 65.1237 26.319 65.8856C26.6892 66.3428 27.4465 66.1142 27.4044 65.4878C27.295 63.9471 26.2433 62.6857 24.998 61.8646C23.3488 60.7895 21.868 62.0847 20.4123 62.8212C20.2609 62.8974 20.2946 63.1429 20.4797 63.1259C23.7107 62.9651 26.8491 65.0814 27.9177 68.0866C28.7338 70.3892 28.5067 74.0716 26.5967 75.8324C26.706 75.9848 26.8154 76.1371 26.9164 76.298C31.0898 73.3605 29.4154 66.8337 26.3779 63.6931C22.9618 60.1546 17.6694 63.1598 17.7283 67.6379C17.7283 67.7395 17.8797 67.7649 17.9218 67.6718V67.6633Z" fill="url(#paint16_linear_654_13377)"/>
        <path d="M28.0273 73.5044C27.3962 74.2832 26.8409 75.1128 26.2267 75.9086C25.2843 77.1276 23.509 77.5339 22.0954 77.8302C20.7323 78.118 19.3692 78.0842 18.0819 77.517C17.2153 77.1361 16.6094 76.5858 15.6671 76.3911C15.5156 76.3572 15.3642 76.5266 15.4315 76.6705C16.6094 79.4132 20.3284 79.7688 22.8611 79.2609C26.193 78.5921 27.716 76.8652 28.3218 73.6314C28.3554 73.4706 28.1451 73.369 28.0357 73.496L28.0273 73.5044Z" fill="url(#paint17_linear_654_13377)"/>
        <path d="M15.667 77.1529C17.7285 79.8025 23.6604 81.3347 25.8059 78.0079C25.9826 77.7285 25.8564 77.229 25.4357 77.2968C24.1315 77.5253 23.0882 78.2618 21.7251 78.3295C19.7058 78.4311 17.6275 77.847 15.8437 76.9328C15.7175 76.865 15.5745 77.0343 15.667 77.1444V77.1529Z" fill="url(#paint18_linear_654_13377)"/>
        <path d="M23.4506 70.3977C22.7186 71.1003 21.5238 71.0749 20.6151 70.9056C19.2772 70.6601 18.8397 69.3734 18.7135 68.1883C18.4947 66.055 19.6306 64.0572 21.2209 62.762C21.3134 62.6858 21.2377 62.5419 21.1199 62.5758C18.1245 63.5154 16.9718 66.8761 17.7207 69.7459C18.4863 72.6664 22.0539 72.6579 23.6441 70.567C23.7535 70.4316 23.56 70.2792 23.4422 70.3977H23.4506Z" fill="url(#paint19_linear_654_13377)"/>
        <path d="M21.4137 66.9015C21.4137 66.9015 21.38 66.8507 21.3632 66.8253C21.2875 66.6983 21.1276 66.6391 20.9846 66.6475C19.6888 66.7745 20.1432 68.1882 20.6228 68.9162C20.9172 69.3734 21.3127 69.712 21.7755 69.9829C22.2298 70.2453 22.3981 70.3553 22.0616 70.8717C21.8764 71.1595 21.7839 71.4643 21.6493 71.769C21.5735 71.9299 21.6745 72.0992 21.8344 72.1415C23.6097 72.5648 24.4764 70.3384 23.3321 69.1109C23.1049 68.8739 22.7094 68.7385 22.4234 68.6115C22.112 68.476 21.868 68.2136 21.624 67.985C21.4473 67.8157 21.1528 67.5533 21.3464 67.3332C21.4389 67.2232 21.4978 67.0285 21.4137 66.9015Z" fill="url(#paint20_linear_654_13377)"/>
        <path d="M21.818 71.8368C22.8193 71.6844 24.2833 70.8379 24.1571 69.6274C24.0898 68.9501 23.8542 68.4337 23.3325 67.9936C22.9876 67.7057 21.6666 67.4518 21.6581 66.9354C21.4394 67.1978 21.2122 67.4602 20.9934 67.7311C22.6089 67.6126 22.4659 71.0326 20.8083 70.3638C19.3695 69.7882 18.7805 68.3152 18.9993 66.8677C19.1171 66.0719 19.5715 65.3862 20.1689 64.8783C21.1112 64.0656 22.3313 64.4127 23.4419 64.2942C23.7112 64.2688 23.829 63.9217 23.6186 63.7355C21.431 61.8647 18.4692 63.8963 17.8129 66.2836C17.1819 68.5777 18.1242 71.295 20.5727 71.9977C24.3422 73.0727 24.7124 66.3513 20.8756 66.2751C20.5811 66.2751 20.2362 66.5714 20.2193 66.8761C20.1773 67.5872 20.3708 68.129 21.0018 68.5269C21.4057 68.7808 21.8433 68.9671 22.264 69.1787C23.3241 69.712 22.0031 71.0665 21.6666 71.5405C21.5824 71.659 21.6497 71.8707 21.8264 71.8453L21.818 71.8368Z" fill="url(#paint21_linear_654_13377)"/>
        <path d="M20.6308 16.4818C27.4967 19.2077 34.7664 16.8713 41.5313 15.0004C47.8503 13.2566 54.4889 11.8936 61.0182 11.2757C67.0343 10.7085 73.5299 11.3603 78.7382 14.6533C82.7349 17.1845 84.6701 21.6457 83.0714 26.2085C81.3802 31.0507 75.4062 32.3374 70.972 31.4824C67.354 30.7882 62.7936 26.4032 64.2492 22.3229C65.545 18.6913 70.1895 17.4808 73.6056 18.4289C76.9039 19.3431 78.1913 24.8964 74.3124 26.0815C71.4348 26.9619 69.6763 25.2519 69.4154 22.5007C69.2976 21.2817 71.174 21.1293 71.2918 22.3398C71.3591 23.0848 71.2918 23.779 72.0659 24.2615C74.3208 25.6667 75.3221 22.3398 74.5143 20.9346C73.6309 19.3939 71.1487 19.4447 69.6679 19.614C67.5644 19.851 65.6375 21.0447 65.1748 23.2456C64.611 25.9545 67.2867 28.6634 69.4154 29.7639C73.7655 32.0242 80.0424 30.2211 82.1038 25.7344C84.5439 20.4267 79.689 16.0078 75.3978 14.0777C66.1424 9.91276 54.8003 13.0365 45.3765 14.9242C37.2317 16.558 28.6578 20.3166 20.5299 16.6766C20.4037 16.6173 20.4962 16.4311 20.6224 16.4818H20.6308Z" fill="url(#paint22_linear_654_13377)"/>
        <path d="M29.9782 17.7687C34.3703 17.6586 38.4848 14.4418 42.1449 12.2831C45.4011 10.3615 48.7415 8.96473 52.5446 8.71924C59.0234 8.29597 61.4214 14.6704 57.9969 19.6734C56.2636 22.2045 52.9233 23.3727 50.0036 22.6532C47.5803 22.0606 46.2846 19.03 47.2101 16.7698C48.102 14.6026 50.3233 13.3752 52.2501 15.1444C53.6216 16.4057 53.7058 18.9961 51.7032 19.5802C50.5757 19.9104 50.0288 18.3443 51.0974 17.8702C51.7958 17.557 50.3654 14.0609 48.6573 16.8713C47.7907 18.2935 48.5816 20.2236 49.8521 21.0617C52.2081 22.6108 55.4895 20.5792 56.962 18.7845C58.5018 16.9052 58.8552 14.154 57.7529 11.9869C56.718 9.95518 54.0255 9.76894 52.065 9.87899C47.4962 10.1329 43.9287 12.6471 39.9404 14.5688C36.7515 16.101 33.6383 18.2089 29.9613 17.9464C29.8688 17.9464 29.8772 17.7771 29.9698 17.7771L29.9782 17.7687Z" fill="url(#paint23_linear_654_13377)"/>
        <path d="M54.8846 12.4102C57.4845 11.4198 59.9498 10.3701 61.8093 8.21145C62.7938 7.06863 63.3659 5.69725 63.7361 4.24968C64.5775 0.948203 60.421 0.677313 60.9679 2.92062C61.4307 4.82532 61.5233 6.02739 60.8922 7.94056C60.3369 9.60822 59.2178 11.1997 57.6107 11.9277C57.4929 11.9785 57.4256 11.8261 57.4845 11.7415C58.9149 9.72674 60.5977 7.21254 60.0003 4.57136C59.7984 3.69097 59.0832 2.26033 59.5796 1.33761C60.4715 -0.31313 62.8443 -0.414714 64.1989 0.86355C66.1173 2.67513 64.9057 5.76497 63.8035 7.69506C61.7588 11.2505 58.469 11.5383 54.9519 12.5372C54.8593 12.5626 54.8088 12.4272 54.9014 12.3933L54.8846 12.4102Z" fill="url(#paint24_linear_654_13377)"/>
        <path d="M57.5768 11.9193C59.4194 10.8357 60.3786 8.17758 60.8667 6.1967C61.2621 4.57136 59.8822 2.3873 61.4304 1.24449C61.5314 1.1683 61.6912 1.17676 61.7838 1.26142C63.0375 2.52275 62.8019 5.64645 62.2129 7.221C61.3463 9.5659 59.9832 11.1828 57.6861 12.1309C57.5515 12.1817 57.4506 11.9785 57.5684 11.9108L57.5768 11.9193Z" fill="url(#paint25_linear_654_13377)"/>
        <path d="M61.708 1.34588C63.3655 2.05696 62.4147 5.95948 61.2536 6.72982C60.9928 6.90759 60.5048 6.75522 60.53 6.38274C60.5721 5.57007 61.0769 4.74893 61.2788 3.94473C61.4892 3.10666 61.3966 2.25167 61.5649 1.4136C61.5817 1.34588 61.6491 1.31202 61.7164 1.33741L61.708 1.34588Z" fill="url(#paint26_linear_654_13377)"/>
        <path d="M56.9291 11.8685C61.9271 10.2178 67.9852 10.6326 71.8472 6.43379C72.5287 5.6973 74.2957 1.49851 71.4686 2.55667C69.6511 3.2339 68.1787 5.71424 66.9502 7.13641C64.914 9.49823 62.474 11.1913 59.4028 11.8262C59.2514 11.8601 59.2177 11.6484 59.3103 11.5722C61.3381 9.83685 63.6519 8.93106 65.5114 6.89938C67.1101 5.14706 68.5068 3.04766 70.5346 1.76093C72.9579 0.220243 74.8763 1.65935 74.2536 4.39365C72.7812 10.8781 62.3141 12.1817 56.988 12.1817C56.8113 12.1817 56.744 11.9278 56.9207 11.8685H56.9291Z" fill="url(#paint27_linear_654_13377)"/>
        <path d="M58.9737 11.5128C61.8766 10.844 64.4429 9.51498 66.8409 7.76266C67.8926 6.99232 68.8939 6.14579 69.9288 5.34158C71.0311 4.47812 71.7126 3.38609 72.697 2.45491C72.9747 2.19248 73.4627 2.20941 73.5973 2.62422C74.3967 5.0453 70.5094 7.07697 68.9191 8.18593C65.9826 10.2345 62.6339 11.6398 59.0495 11.8599C58.8559 11.8683 58.7718 11.5636 58.9737 11.5128Z" fill="url(#paint28_linear_654_13377)"/>
        <path d="M73.4121 2.65782C74.2199 6.9582 68.6414 9.72636 65.3599 10.8438C64.9308 10.9877 64.6615 10.4205 65.0065 10.1496C67.9093 7.94018 71.6957 6.42489 72.84 2.62396C72.9325 2.30228 73.3532 2.32767 73.4121 2.64935V2.65782Z" fill="url(#paint29_linear_654_13377)"/>
        <path d="M65.427 10.7596C68.5907 10.5649 71.7123 11.0474 74.8844 11.0643C77.6947 11.0812 81.3548 11.1151 83.4583 8.96491C83.2984 7.20413 82.6169 6.46764 81.3969 6.77239C80.5302 6.84012 79.672 7.20413 78.8642 7.51734C77.4254 8.08452 76.0203 8.74481 74.5647 9.27813C71.9142 10.2516 69.1208 11.2167 66.2768 11.1659C66.1422 11.1659 66.0328 10.9712 66.1843 10.895C69.9874 8.88872 74.4721 8.03373 78.4688 6.39992C79.8571 5.83274 85.2337 4.47829 85.1664 7.51734C85.1075 10.5564 81.8007 11.5807 79.3018 11.8939C74.7077 12.4696 69.9454 11.7161 65.4018 11.0982C65.1998 11.0728 65.2251 10.7596 65.4186 10.7426L65.427 10.7596Z" fill="url(#paint30_linear_654_13377)"/>
        <path d="M65.5956 11.1316C69.1884 9.9634 73.1009 10.4544 76.8031 9.51474C78.6879 9.04068 80.3875 8.39732 82.0366 7.37302C82.7687 6.91589 83.4839 6.89896 84.2664 7.16138C84.3337 7.18678 84.3589 7.27143 84.3505 7.33069C83.7531 11.5718 78.2251 11.9189 74.8595 11.5041C74.5482 11.4618 74.5229 10.9877 74.809 10.8946C76.4834 10.3528 78.1998 10.1158 79.9079 9.71791C80.9933 9.46395 82.0366 9.05762 82.9622 8.43965C83.0043 8.41425 84.7207 7.0344 83.6185 8.20262C83.2735 8.56663 82.8276 8.82905 82.4153 9.09148C80.337 10.4121 77.8633 10.8353 75.4821 11.284C72.167 11.902 68.9696 11.1401 65.6461 11.3009C65.5451 11.3009 65.5031 11.157 65.604 11.1232L65.5956 11.1316Z" fill="url(#paint31_linear_654_13377)"/>
        <path d="M31.5691 17.6503C35.002 17.2609 38.1741 16.6345 41.3798 18.2259C43.2057 19.1317 44.8969 20.6555 44.7875 22.8311C44.6866 24.8797 42.2886 25.7008 40.8918 24.4141C39.9495 23.5506 39.6045 21.519 38.9229 20.4608C37.6524 18.5053 34.0512 17.608 31.8383 17.7011C31.7458 17.7011 31.7121 17.5657 31.8047 17.5318C35.6499 16.1943 39.4194 18.3953 41.0517 21.9592C41.1863 22.2724 41.2957 22.5941 41.3883 22.9242C42.1539 22.8226 42.9196 22.7295 43.6853 22.6279C43.7021 21.9676 43.5086 21.4004 43.1047 20.9264C42.4821 20.0291 41.3883 19.445 40.4459 18.9709C37.8544 17.6418 34.3878 17.6588 31.5607 17.8365C31.4513 17.8365 31.4345 17.6757 31.5438 17.6672L31.5691 17.6503Z" fill="url(#paint32_linear_654_13377)"/>
        <path d="M37.2489 17.9209C38.4101 18.5897 39.6217 18.9876 40.7323 19.7918C41.8851 20.6214 42.777 21.7557 43.3491 23.0509C43.7362 23.9398 42.2889 24.6932 41.843 23.8297C41.3213 22.8054 40.7239 21.8488 39.9835 20.9685C39.1926 20.0373 38.1324 18.8691 37.1143 18.2003C36.946 18.0902 37.0722 17.8194 37.2489 17.9209Z" fill="url(#paint33_linear_654_13377)"/>
        <path d="M30.1139 17.8534C32.9494 17.0238 37.4677 18.1074 37.249 21.7983C37.1733 23.1866 35.8354 25.0405 34.3798 23.8469C33.6562 23.2459 33.3112 22.5094 33.0504 21.6121C32.8484 20.901 32.6633 20.2237 32.2679 19.5804C31.6284 18.5476 30.3074 18.3021 29.2304 17.9804C29.1463 17.955 29.1547 17.845 29.2304 17.8281C30.6355 17.5233 32.1585 17.7603 33.0588 19.0301C33.4711 19.6227 33.6898 20.1899 33.8665 20.8925C34.0096 21.4597 34.4555 24.2109 35.7008 23.0681C37.1985 21.6882 35.7008 19.6735 34.4219 18.9878C33.2355 18.3614 31.477 18.0143 30.1475 18.0566C30.0297 18.0566 29.9877 17.8873 30.1055 17.8534H30.1139Z" fill="url(#paint34_linear_654_13377)"/>
        <path d="M30.3158 17.8619C31.9482 17.8957 33.2187 18.3529 34.4219 19.5211C35.2549 20.3338 36.2477 21.502 36.2646 22.7294C36.2814 23.9146 34.4387 24.0754 34.4303 22.8818C34.4303 22.0691 33.6562 21.2395 33.185 20.63C32.453 19.6819 31.4265 18.5137 30.2738 18.082C30.1644 18.0397 30.198 17.845 30.3158 17.8534V17.8619Z" fill="url(#paint35_linear_654_13377)"/>
        <path d="M33.841 20.8416C34.4384 21.4426 35.0274 22.3653 34.0682 22.9071C33.9924 22.9495 33.883 22.9495 33.8326 22.8648C33.6727 22.577 33.5297 22.4161 33.5212 22.0521C33.5212 21.6797 33.6222 21.3326 33.6054 20.9516C33.6054 20.8331 33.7484 20.74 33.841 20.8331V20.8416Z" fill="url(#paint36_linear_654_13377)"/>
        <path d="M77.1735 14.4332C80.4214 16.1093 82.6343 20.3251 81.8602 23.9652C81.0777 27.656 77.6363 29.7046 74.1192 29.984C70.7116 30.2549 67.4974 27.9015 66.9 24.4731C66.311 21.0616 67.733 18.759 71.2837 19.0383C71.3847 19.0468 71.3931 19.2076 71.3005 19.233C68.2042 20.105 67.4133 22.5684 68.5492 25.4635C69.7187 28.4348 72.9329 29.0867 75.7011 28.401C82.3987 26.7333 81.776 18.2087 77.0558 14.6194C76.9548 14.5433 77.0642 14.3824 77.1735 14.4417V14.4332Z" fill="url(#paint37_linear_654_13377)"/>
        <path d="M71.2836 19.0385C67.3627 20.2236 65.6799 24.2108 69.0876 27.1228C71.9568 29.5778 75.3308 29.0614 78.688 28.435C78.9572 28.3842 79.0498 28.7228 78.9236 28.9005C76.391 32.5406 71.6707 31.8634 68.3556 29.5608C66.5045 28.2741 65.5032 26.0054 65.234 23.8298C65.1751 23.3134 65.2844 22.8055 65.3854 22.3061C65.4443 22.0098 66.5381 21.2225 66.4708 21.8405C66.3362 23.1441 65.6378 23.669 66.5045 25.1843C67.0093 26.0647 67.9938 26.5726 68.6921 27.2752C69.1044 27.69 68.8015 28.4265 68.1873 28.3249C66.6728 28.0625 65.5284 26.8858 64.8469 25.5568C63.9466 23.796 65.377 22.45 66.2521 21.0617C66.3446 20.9178 66.5802 20.977 66.5465 21.1632C66.0669 24.3801 67.8507 27.7323 70.7199 29.1037C72.916 30.1534 76.5845 30.2804 78.5113 28.545C78.6543 28.672 78.7974 28.7905 78.932 28.9175C75.6169 32.8031 69.3232 30.4751 66.5129 27.1228C63.3492 23.3473 66.8494 18.3528 71.2668 18.8607C71.3678 18.8691 71.3762 19.03 71.2836 19.0554V19.0385Z" fill="url(#paint38_linear_654_13377)"/>
        <path d="M76.0539 29.7386C76.8869 29.1799 77.762 28.7058 78.6118 28.1725C79.9076 27.3514 80.4881 25.6075 80.9257 24.2277C81.3464 22.8902 81.4473 21.5273 81.0098 20.1813C80.7153 19.2755 80.2357 18.6152 80.1347 17.6501C80.1179 17.4978 80.2946 17.3623 80.4376 17.4385C83.0376 18.8861 83.0123 22.6531 82.2635 25.1335C81.2706 28.4096 79.4196 29.7555 76.1549 30.0434C75.995 30.0603 75.9109 29.8317 76.0539 29.7471V29.7386Z" fill="url(#paint39_linear_654_13377)"/>
        <path d="M80.8923 17.7178C83.3071 20.0457 84.2326 26.1323 80.7324 27.9523C80.4379 28.1047 79.9583 27.9269 80.0677 27.5206C80.4295 26.2339 81.2541 25.2688 81.456 23.9059C81.7589 21.8996 81.3803 19.7494 80.6567 17.8871C80.6062 17.7516 80.7829 17.6246 80.8838 17.7262L80.8923 17.7178Z" fill="url(#paint40_linear_654_13377)"/>
        <path d="M73.4288 24.8372C74.1945 24.1769 74.2871 22.9748 74.2113 22.0521C74.1019 20.6892 72.8735 20.122 71.7124 19.885C69.6257 19.4533 67.5306 20.3929 66.1002 21.8574C66.0161 21.9421 65.8814 21.8574 65.9319 21.7389C67.1604 18.8353 70.5933 18.0057 73.3615 19.0469C76.1718 20.0966 75.81 23.6775 73.5887 25.0573C73.4372 25.1504 73.311 24.9473 73.4372 24.8372H73.4288Z" fill="url(#paint41_linear_654_13377)"/>
        <path d="M70.1808 22.4587C70.1808 22.4587 70.1303 22.4248 70.1051 22.3994C69.9873 22.3063 69.9368 22.1455 69.9704 22.0016C70.2229 20.7148 71.5775 21.3159 72.2506 21.8577C72.6713 22.2047 72.9742 22.628 73.193 23.119C73.4118 23.6015 73.4959 23.7793 74.0428 23.4915C74.3457 23.3306 74.6571 23.2714 74.9684 23.1613C75.1367 23.1021 75.2965 23.2206 75.3218 23.3899C75.5658 25.2099 73.2772 25.8533 72.1749 24.592C71.9562 24.3465 71.872 23.9317 71.771 23.6354C71.6701 23.3137 71.4345 23.0428 71.2325 22.7719C71.0811 22.5772 70.8539 22.264 70.6183 22.4248C70.5005 22.5095 70.307 22.5518 70.1808 22.4502V22.4587Z" fill="url(#paint42_linear_654_13377)"/>
        <path d="M75.0192 23.3558C74.7668 24.3378 73.7908 25.7177 72.6044 25.4806C71.9397 25.3452 71.4517 25.0574 71.073 24.4902C70.8206 24.1177 70.7028 22.7633 70.1896 22.7125C70.4672 22.5178 70.7533 22.3231 71.0394 22.1284C70.7617 23.7368 74.161 23.923 73.6646 22.2046C73.2354 20.7147 71.8387 19.9697 70.3831 20.0459C69.5838 20.0882 68.8686 20.4776 68.2964 21.0194C67.3961 21.8829 67.6149 23.1357 67.3961 24.2362C67.3372 24.5071 66.9838 24.5833 66.8239 24.3547C65.1916 21.9845 67.4887 19.2163 69.9203 18.793C72.251 18.3867 74.8425 19.6057 75.2969 22.1199C75.9868 25.997 69.2977 25.7092 69.609 21.8575C69.6342 21.5612 69.9708 21.248 70.2653 21.2649C70.9805 21.2903 71.4853 21.5443 71.8219 22.2046C72.0322 22.6278 72.1669 23.0849 72.3351 23.5336C72.7558 24.651 74.2283 23.4574 74.7331 23.1696C74.8594 23.1019 75.0613 23.1865 75.0192 23.3558Z" fill="url(#paint43_linear_654_13377)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_654_13377" x1="30.3192" y1="80.4721" x2="14.5249" y2="81.445" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint1_linear_654_13377" x1="23.6798" y1="55.5567" x2="12.3876" y2="56.6424" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint2_linear_654_13377" x1="13.132" y1="60.0394" x2="3.35292" y2="62.7327" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint3_linear_654_13377" x1="12.4664" y1="57.6496" x2="5.27566" y2="61.2534" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint4_linear_654_13377" x1="6.84874" y1="57.4289" x2="3.65643" y2="59.3821" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint5_linear_654_13377" x1="12.5634" y1="69.244" x2="2.78719" y2="70.6933" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint6_linear_654_13377" x1="12.0262" y1="68.5398" x2="3.5207" y2="69.8498" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint7_linear_654_13377" x1="10.446" y1="68.3628" x2="3.47041" y2="69.9557" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint8_linear_654_13377" x1="10.8209" y1="80.45" x2="4.98998" y2="80.883" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint9_linear_654_13377" x1="10.8552" y1="79.5909" x2="6.04206" y2="79.904" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint10_linear_654_13377" x1="26.7062" y1="41.5256" x2="20.9648" y2="42.1243" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint11_linear_654_13377" x1="25.901" y1="40.2007" x2="21.4377" y2="40.9356" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint12_linear_654_13377" x1="26.6218" y1="33.8721" x2="21.6926" y2="34.6001" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint13_linear_654_13377" x1="26.1122" y1="32.9788" x2="21.9579" y2="33.6506" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint14_linear_654_13377" x1="25.4837" y1="31.2346" x2="24.0218" y2="31.7859" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint15_linear_654_13377" x1="28.572" y1="78.796" x2="16.4354" y2="81.2929" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint16_linear_654_13377" x1="29.9802" y1="76.3292" x2="20.422" y2="77.8955" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint17_linear_654_13377" x1="28.3254" y1="79.4563" x2="19.5772" y2="83.1902" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint18_linear_654_13377" x1="25.8835" y1="79.9075" x2="20.2797" y2="83.7274" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint19_linear_654_13377" x1="23.6765" y1="72.0482" x2="18.8098" y2="72.6792" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint20_linear_654_13377" x1="23.8494" y1="72.1943" x2="20.9277" y2="72.5832" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint21_linear_654_13377" x1="24.1648" y1="72.1141" x2="19.0289" y2="72.8478" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint22_linear_654_13377" x1="83.6322" y1="31.7281" x2="46.7222" y2="54.2042" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint23_linear_654_13377" x1="59.555" y1="22.8626" x2="39.3101" y2="31.2967" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint24_linear_654_13377" x1="65.2077" y1="12.5404" x2="57.1301" y2="13.8665" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint25_linear_654_13377" x1="62.6913" y1="12.1389" x2="58.5822" y2="12.5254" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint26_linear_654_13377" x1="62.5994" y1="6.80392" x2="60.9503" y2="6.92799" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint27_linear_654_13377" x1="74.3721" y1="12.1817" x2="61.5865" y2="16.2223" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint28_linear_654_13377" x1="73.7044" y1="11.8601" x2="62.8437" y2="15.2089" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint29_linear_654_13377" x1="73.4911" y1="10.8668" x2="66.8422" y2="12.2161" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint30_linear_654_13377" x1="85.167" y1="12.0993" x2="73.5433" y2="19.187" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint31_linear_654_13377" x1="84.3521" y1="11.6366" x2="75.2133" y2="18.9956" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint32_linear_654_13377" x1="44.7926" y1="25.031" x2="35.2013" y2="28.2511" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint33_linear_654_13377" x1="43.4142" y1="24.2107" x2="38.5027" y2="25.1982" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint34_linear_654_13377" x1="37.2566" y1="24.2298" x2="31.1383" y2="25.7122" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint35_linear_654_13377" x1="36.2647" y1="23.7015" x2="31.61" y2="24.6606" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint36_linear_654_13377" x1="34.5755" y1="22.9349" x2="33.739" y2="23.0169" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint37_linear_654_13377" x1="82.0152" y1="30.0052" x2="70.2497" y2="32.2959" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint38_linear_654_13377" x1="78.9793" y1="31.4592" x2="67.9951" y2="33.9531" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint39_linear_654_13377" x1="82.7024" y1="30.0443" x2="77.3777" y2="30.6081" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint40_linear_654_13377" x1="83.0397" y1="28.0064" x2="80.6541" y2="28.1438" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint41_linear_654_13377" x1="75.3623" y1="25.0811" x2="68.388" y2="27.1075" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint42_linear_654_13377" x1="75.3398" y1="25.2477" x2="71.3165" y2="26.3204" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint43_linear_654_13377" x1="75.3468" y1="25.5075" x2="68.5591" y2="27.3114" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <clipPath id="clip0_654_13377">
          <rect width="85.1753" height="80.4713" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    )
}