export const BackgroundSvg1: React.FC = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="160" height="240" viewBox="0 0 160 240" fill="none">
      <g opacity="0.1" clipPath="url(#clip0_818_4376)">
        <path d="M165.63 73.1067C165.63 73.1067 146.271 88.5742 151.011 111.226C155.751 133.886 167.811 148.138 174.317 155.081C180.822 162.024 182.06 165.483 182.06 165.483C182.06 165.483 189.932 154.06 192.996 138.639C196.06 123.218 191.766 89.0573 165.622 73.1067H165.63Z" fill="url(#paint0_linear_818_4376)"/>
        <path d="M165.63 73.1067C165.63 73.1067 146.271 88.5742 151.011 111.226C155.751 133.886 167.811 148.138 174.317 155.081C180.822 162.024 182.06 165.483 182.06 165.483C182.06 165.483 189.932 154.06 192.996 138.639C196.06 123.218 191.766 89.0573 165.622 73.1067H165.63Z" stroke="url(#paint1_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M178.558 159.857C178.558 159.857 162.988 135.202 158.03 119.322C153.071 103.441 153.962 86.9453 162.95 75.8025" stroke="url(#paint2_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M126.068 147.85C126.068 147.85 111.66 159.359 115.192 176.221C118.717 193.083 127.698 203.689 132.536 208.855C137.381 214.021 138.302 216.592 138.302 216.592C138.302 216.592 144.158 208.091 146.438 196.613C148.717 185.135 145.524 159.709 126.068 147.842V147.85Z" fill="url(#paint3_linear_818_4376)"/>
        <path d="M126.068 147.85C126.068 147.85 111.66 159.359 115.192 176.221C118.717 193.083 127.698 203.689 132.536 208.855C137.381 214.021 138.302 216.592 138.302 216.592C138.302 216.592 144.158 208.091 146.438 196.613C148.717 185.135 145.524 159.709 126.068 147.842V147.85Z" stroke="url(#paint4_linear_818_4376)" strokeWidth="0.52" strokeMiterlimit="10"/>
        <path d="M126.067 147.85C126.067 147.85 118.912 164.696 126.837 183.889C134.761 203.081 138.301 216.6 138.301 216.6" stroke="url(#paint5_linear_818_4376)" strokeWidth="0.52" strokeMiterlimit="10"/>
        <path d="M138.927 215.665C138.927 215.665 139.886 195.18 134.369 178.029C128.852 160.871 126.92 148.302 126.92 148.302" stroke="url(#paint6_linear_818_4376)" strokeWidth="0.52" strokeMiterlimit="10"/>
        <path d="M135.69 212.408C135.69 212.408 124.105 194.057 120.415 182.237C116.724 170.416 117.388 158.135 124.075 149.852" stroke="url(#paint7_linear_818_4376)" strokeWidth="0.52" strokeMiterlimit="10"/>
        <path d="M142.242 209.4C142.242 209.4 144.823 185.813 140.347 170.533C135.872 155.244 131.336 151.839 131.336 151.839" stroke="url(#paint8_linear_818_4376)" strokeWidth="0.52" strokeMiterlimit="10"/>
        <path d="M165.29 1.9248C165.29 1.9248 179.985 45.7404 151.736 90.4287C123.479 135.117 112.815 177.881 112.105 192.795" stroke="url(#paint9_linear_818_4376)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M93.6446 81.3585C115.177 45.4364 153.267 52.3636 160.263 47.1429C167.267 41.9299 170.301 32.7584 168.731 26.252L168.95 26.3844C168.95 26.3844 185.433 61.6675 161.992 86.0338C138.55 110.408 121.109 99.8727 88.1805 123.195C88.1805 123.195 79.5993 104.774 93.6371 81.3585H93.6446Z" fill="url(#paint10_linear_818_4376)"/>
        <path d="M169.494 27.7402C169.494 27.7402 176.407 54.4441 160.815 68.0571C145.215 81.6701 114.445 92.9065 101.751 103.356C89.0489 113.805 88.1885 123.195 88.1885 123.195" stroke="url(#paint11_linear_818_4376)" strokeMiterlimit="10"/>
        <path d="M168.067 35.9531C168.067 35.9531 168.678 53.8518 155.297 61.6596C141.909 69.4674 110.044 85.1921 98.9425 94.6908C87.8481 104.182 86.1953 116.252 86.1953 116.252" stroke="url(#paint12_linear_818_4376)" strokeMiterlimit="10"/>
        <path d="M160.015 47.6104C160.015 47.6104 151.049 58.6675 123.026 68.7428C95.0034 78.8181 87.7959 93.9194 87.7959 93.9194" stroke="url(#paint13_linear_818_4376)" strokeMiterlimit="10"/>
        <path d="M174.656 55.1846C174.656 55.1846 167.765 76.3794 152.535 86.7196C140.188 95.0963 117.199 105.062 113.229 109.738" stroke="url(#paint14_linear_818_4376)" strokeMiterlimit="10"/>
        <path d="M93.6446 81.3585C115.177 45.4364 153.267 52.3636 160.263 47.1429C167.267 41.9299 170.301 32.7584 168.731 26.252L168.95 26.3844C168.95 26.3844 185.433 61.6675 161.992 86.0338C138.55 110.408 121.109 99.8727 88.1805 123.195C88.1805 123.195 79.5993 104.774 93.6371 81.3585H93.6446Z" stroke="url(#paint15_linear_818_4376)" strokeMiterlimit="10"/>
        <path d="M131.214 127.379C131.214 127.379 131.229 152.649 110.98 162.655C90.7313 172.66 72.4294 171.553 63.1313 170.494C53.8257 169.434 50.4219 170.54 50.4219 170.54C50.4219 170.54 54.3464 157.122 64.2709 145.177C74.203 133.224 102.988 115.808 131.207 127.372L131.214 127.379Z" fill="url(#paint16_linear_818_4376)"/>
        <path d="M131.214 127.379C131.214 127.379 131.229 152.649 110.98 162.655C90.7313 172.66 72.4294 171.553 63.1313 170.494C53.8257 169.434 50.4219 170.54 50.4219 170.54C50.4219 170.54 54.3464 157.122 64.2709 145.177C74.203 133.224 102.988 115.808 131.207 127.372L131.214 127.379Z" stroke="url(#paint17_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M131.214 127.379C131.214 127.379 119.773 149.088 93.5087 156.203C67.2445 163.309 50.4219 170.548 50.4219 170.548" stroke="url(#paint18_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M50.8594 169.099C50.8594 169.099 71.1386 151.185 93.3348 143.12C115.531 135.055 130.052 126.818 130.052 126.818" stroke="url(#paint19_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M56.875 169.972C56.875 169.972 85.2901 167.572 100.483 161.891C115.675 156.211 127.766 145.379 130.784 131.221" stroke="url(#paint20_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M54.5879 160.309C54.5879 160.309 76.7237 138.063 96.1351 130.387C115.546 122.72 122.777 124.886 122.777 124.886" stroke="url(#paint21_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M121.962 154.893C121.411 154.06 138.898 173.953 120.671 202.496C101.516 229.862 74.6787 234.974 63.124 234.818C51.0032 234.935 46.4825 235.543 46.4523 235.675C46.4145 235.597 54.2259 221.034 63.8636 207.234C74.9806 192.717 94.9881 172.597 121.954 154.893H121.962Z" fill="url(#paint22_linear_818_4376)"/>
        <path d="M121.962 154.893C121.411 154.06 138.898 173.953 120.671 202.496C101.516 229.862 74.6787 234.974 63.124 234.818C51.0032 234.935 46.4825 235.543 46.4523 235.675C46.4145 235.597 54.2259 221.034 63.8636 207.234C74.9806 192.717 94.9881 172.597 121.954 154.893H121.962Z" stroke="url(#paint23_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M121.962 154.894C121.834 153.429 127.736 184.216 98.9884 208.395C70.7167 230.252 46.1733 235.192 46.4526 235.668" stroke="url(#paint24_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M47.2451 234.063C47.864 234.141 75.4791 215.143 94.9281 195.647C115.721 175.972 120.604 155.135 120.762 155.572" stroke="url(#paint25_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M54.9661 234.912C54.8529 236.291 93.117 228.039 108.596 209.735C124.77 193.488 130.098 170.798 123.902 158.01" stroke="url(#paint26_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M52.8672 224.322C54.0068 224.525 79.4408 199.278 93.1691 182.416C108.15 165.561 113.637 160.169 114.022 160.426" stroke="url(#paint27_linear_818_4376)" strokeWidth="0.7" strokeMiterlimit="10"/>
        <path d="M172.837 166.886C172.422 135.164 145.305 124.41 142.754 118.262C140.196 112.106 141.675 104.992 145.124 101.431H144.928C144.928 101.431 121.101 117.569 127.071 142.613C133.034 167.665 148.211 167.844 160.686 196.005C160.686 196.005 173.109 187.566 172.837 166.886Z" fill="url(#paint28_linear_818_4376)"/>
        <path d="M144.075 102.086C144.075 102.086 129.622 116.517 134.573 131.509C139.516 146.501 155.124 166.021 159.388 177.818C163.652 189.616 160.694 196.005 160.694 196.005" stroke="url(#paint29_linear_818_4376)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M141.917 107.945C141.917 107.945 134.822 119.228 140.513 129.592C146.211 139.963 160.838 162.81 164.43 173.353C168.022 183.896 164.558 192.327 164.558 192.327" stroke="url(#paint30_linear_818_4376)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M142.747 118.66C142.747 118.66 144.377 129.358 158.649 147.031C172.921 164.704 171.909 177.304 171.909 177.304" stroke="url(#paint31_linear_818_4376)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M130.476 117.694C130.476 117.694 126.974 134.096 132.913 146.829C137.728 157.153 148.8 172.746 149.6 177.335" stroke="url(#paint32_linear_818_4376)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M172.837 166.886C172.422 135.164 145.305 124.41 142.754 118.262C140.196 112.106 141.675 104.992 145.124 101.431H144.928C144.928 101.431 121.101 117.569 127.071 142.613C133.034 167.665 148.211 167.844 160.686 196.005C160.686 196.005 173.109 187.566 172.837 166.886Z" stroke="url(#paint33_linear_818_4376)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M143.872 51.3818C143.992 51.6 125.087 53.4 118.543 67.9948C112.143 82.8312 114.4 96.2805 115.97 103.177C117.532 110.057 116.951 112.621 116.951 112.621C116.951 112.605 127.087 109.457 135.509 101.704C144.038 94.1299 154.619 71.2286 143.872 51.374V51.3818Z" fill="url(#paint34_linear_818_4376)"/>
        <path d="M143.872 51.3818C143.992 51.6 125.087 53.4 118.543 67.9948C112.143 82.8312 114.4 96.2805 115.97 103.177C117.532 110.057 116.951 112.621 116.951 112.621C116.951 112.605 127.087 109.457 135.509 101.704C144.038 94.1299 154.619 71.2286 143.872 51.374V51.3818Z" stroke="url(#paint35_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M143.872 51.3818C143.992 51.5922 128.574 61.4961 124.815 80.5948C121.2 99.9273 116.936 112.613 116.943 112.629" stroke="url(#paint36_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M118.037 112.27C118.037 112.27 130.377 96.8882 134.905 80.2752C139.464 63.7168 144.384 52.2077 144.377 52.1843" stroke="url(#paint37_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M116.875 107.813C116.853 107.766 116.521 86.7429 119.887 75.6234C123.215 64.4416 130.823 55.4961 141.072 52.0442" stroke="url(#paint38_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M124.573 109.309C124.573 109.309 140.128 92.4389 144.332 77.5012C148.566 62.6103 146.339 57.3427 146.354 57.3739" stroke="url(#paint39_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M85.6984 77.3378C85.6984 77.3378 70.7172 77.0962 66.3927 88.9014C62.0908 100.753 64.3474 111.764 65.7135 117.242C67.1021 122.751 66.8003 124.894 66.8003 124.894C66.8003 124.894 73.804 121.598 79.8569 115.442C85.8116 109.122 94.1361 92.8521 85.7059 77.3378H85.6984Z" fill="url(#paint40_linear_818_4376)"/>
        <path d="M85.6984 77.3378C85.6984 77.3378 70.7172 77.0962 66.3927 88.9014C62.0908 100.753 64.3474 111.764 65.7135 117.242C67.1021 122.751 66.8003 124.894 66.8003 124.894C66.8003 124.894 73.804 121.598 79.8569 115.442C85.8116 109.122 94.1361 92.8521 85.7059 77.3378H85.6984Z" stroke="url(#paint41_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M85.6979 77.3379C85.6979 77.3379 73.5017 83.4548 71.3734 98.821C69.2149 114.133 66.8074 124.902 66.7998 124.894" stroke="url(#paint42_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M67.5549 124.535C67.5021 124.442 75.7059 111.289 78.921 98.6573C82.0531 85.8859 86.1134 78.0002 86.0983 77.9846" stroke="url(#paint43_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M66.5433 120.966C66.5206 120.927 65.4112 103.777 67.5923 94.9325C69.7131 85.9949 75.2829 79.1689 83.4565 77.5559" stroke="url(#paint44_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M72.1059 121.792C72.0229 121.644 82.8078 107.72 86.1135 96.9041C89.3361 85.9404 87.6833 82.0365 87.6682 82.0054" stroke="url(#paint45_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M168.053 0.896122C167.811 0.483135 165.208 42.0468 127.751 56.3611C108.989 63.4676 92.4907 70.5663 80.5058 78.0857C68.4228 85.4416 60.868 93.2338 57.7812 97.8546" stroke="url(#paint46_linear_818_4376)" strokeWidth="2.29" strokeMiterlimit="10"/>
        <path d="M92.1434 26.2364C122.891 16.7222 143.887 33.6624 150.279 32.7118C156.589 31.6287 161.509 25.9559 162.664 20.6572C162.694 20.7118 162.725 20.7663 162.755 20.8209C162.649 20.6338 161.638 52.504 136.362 57.904C110.725 62.6962 103.049 48.7092 69.8642 46.1066C69.8114 46.0131 71.9925 32.2442 92.151 26.2364H92.1434Z" fill="url(#paint47_linear_818_4376)"/>
        <path d="M162.634 21.9663C162.634 21.9663 158.166 43.8235 142.732 46.426C127.487 49.3637 101.57 42.0468 88.0681 41.7196C74.6115 41.4702 69.8492 46.0754 69.8643 46.1066" stroke="url(#paint48_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M159.042 27.5066C159.042 27.5066 153.351 40.4806 141.653 40.3715C130.257 40.7689 101.985 36.0469 89.9324 35.7897C78.023 35.7897 71.4947 41.3923 71.5098 41.4235" stroke="url(#paint49_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M149.947 32.9377C149.887 32.8364 140.544 37.7377 117.585 31.9714C94.5058 26.0026 82.4379 30.1091 82.5209 30.2493" stroke="url(#paint50_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M156.348 42.9431C156.423 43.0678 144.333 54.8496 129.812 54.5223C118.227 54.5691 98.0685 49.7535 93.2383 50.4236" stroke="url(#paint51_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M92.1434 26.2364C122.891 16.7222 143.887 33.6624 150.279 32.7118C156.589 31.6287 161.509 25.9559 162.664 20.6572C162.694 20.7118 162.725 20.7663 162.755 20.8209C162.649 20.6338 161.638 52.504 136.362 57.904C110.725 62.6962 103.049 48.7092 69.8642 46.1066C69.8114 46.0131 71.9925 32.2442 92.151 26.2364H92.1434Z" stroke="url(#paint52_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M98.0379 68.2597C98.0379 68.2597 87.1624 82.2779 68.9888 78.9116C50.8001 75.522 38.6794 66.7402 32.6416 61.8467C26.6265 56.9921 23.8567 56.1817 23.8643 56.1817C23.8492 56.1506 31.6454 49.2856 43.6454 47.1194C55.5775 44.8441 83.5775 49.2233 98.0454 68.2675L98.0379 68.2597Z" fill="url(#paint53_linear_818_4376)"/>
        <path d="M98.0379 68.2597C98.0379 68.2597 87.1624 82.2778 68.9888 78.9116C50.8001 75.522 38.6794 66.7402 32.6416 61.8467C26.6265 56.9921 23.8567 56.1817 23.8643 56.1817C23.8492 56.1506 31.6454 49.2856 43.6454 47.1194C55.5775 44.8441 83.5775 49.2233 98.0454 68.2675L98.0379 68.2597Z" stroke="url(#paint54_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M98.0378 68.2596C98.0378 68.2596 80.7397 75.07 59.4945 67.0985C38.1813 59.018 23.8492 56.1505 23.8643 56.1816" stroke="url(#paint55_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M24.7172 55.4414C24.604 55.2388 45.7663 53.4699 64.8757 59.5245C83.8644 65.3686 97.4946 67.4336 97.4795 67.418" stroke="url(#paint56_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M28.5283 58.6985C28.5283 58.6985 49.0717 69.966 62 73.6439C74.9057 77.2751 87.9019 76.6829 96.083 70.192" stroke="url(#paint57_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M30.7175 51.6234C30.5665 51.3663 55.2382 48.6468 72.3024 53.7585C89.2156 58.6052 93.2609 63.039 93.2684 63.0546" stroke="url(#paint58_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M79.8642 79.4572C79.9699 78.9663 83.2454 97.4572 59.9925 107.907C36.8605 117.733 18.8303 113.197 11.3737 108.701C3.6001 104.447 0.641613 103.473 0.581235 103.566C0.535952 103.426 9.01142 93.2962 20.0152 87.2104C32.0001 80.7741 53.5397 76.9949 79.8642 79.4572Z" fill="url(#paint59_linear_818_4376)"/>
        <path d="M79.8642 79.4572C79.9699 78.9663 83.2454 97.4572 59.9925 107.907C36.8605 117.733 18.8303 113.197 11.3737 108.701C3.6001 104.447 0.641613 103.473 0.581235 103.566C0.535952 103.426 9.01142 93.2962 20.0152 87.2104C32.0001 80.7741 53.5397 76.9949 79.8642 79.4572Z" stroke="url(#paint60_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M79.8638 79.4571C80.5129 78.7792 71.1091 98.1194 43.1544 102.608C16.5506 106.067 0.671355 103.325 0.573242 103.558" stroke="url(#paint61_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M1.47168 102.429C1.65281 102.374 24.2113 96.1014 44.9736 92.1975C66.4302 88.2468 78.883 79.091 78.7396 79.2936" stroke="url(#paint62_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M6.14346 105.873C5.65289 106.894 32.0453 113.299 49.1623 107.603C65.7887 103.2 78.8454 92.3224 79.8944 82.1379" stroke="url(#paint63_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M7.62988 96.2182C7.97705 96.226 32.362 86.6182 48.7544 82.8389C65.6525 79.013 71.9318 78.7792 72.0299 78.9974" stroke="url(#paint64_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M110.037 109.364C122.95 90.7792 109.056 72.9663 109.909 68.3455C110.792 63.7792 114.852 60.4598 118.694 59.9065C118.648 59.8753 118.603 59.852 118.565 59.8208C118.686 60.0312 95.3048 58.2857 88.6256 74.8442C82.1954 91.8312 92.5652 98.7117 89.8633 121.348C89.8709 121.364 101.652 121.527 110.037 109.356V109.364Z" fill="url(#paint65_linear_818_4376)"/>
        <path d="M117.705 59.8362C117.758 59.9297 101.652 61.5816 98.5803 72.0699C95.5463 82.6206 98.135 100.847 96.2708 109.776C94.3916 118.683 89.8633 121.363 89.8633 121.363" stroke="url(#paint66_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M113.743 62.2053C113.743 62.2053 104.044 65.369 103.509 73.6832C102.965 81.9819 103.411 101.548 101.577 109.403C99.7049 117.187 93.9766 120.811 93.9766 120.818" stroke="url(#paint67_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M109.728 68.5715C109.728 68.5715 106.233 75.2105 108.581 91.6209C110.935 108.039 105.094 115.068 105.094 115.068" stroke="url(#paint68_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M101.705 62.5713C101.705 62.5713 92.1726 69.9973 90.882 79.8934C89.8178 87.8804 91.0178 101.984 89.7197 105.109" stroke="url(#paint69_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M110.037 109.364C122.95 90.7792 109.056 72.9663 109.909 68.3455C110.792 63.7792 114.852 60.4598 118.694 59.9065C118.648 59.8753 118.603 59.852 118.565 59.8208C118.686 60.0312 95.3048 58.2857 88.6256 74.8442C82.1954 91.8312 92.5652 98.7117 89.8633 121.348C89.8709 121.364 101.652 121.527 110.037 109.356V109.364Z" stroke="url(#paint70_linear_818_4376)" strokeWidth="1.52" strokeMiterlimit="10"/>
        <path d="M118.015 239.72C119.086 239.72 119.954 238.823 119.954 237.717C119.954 236.611 119.086 235.714 118.015 235.714C116.944 235.714 116.075 236.611 116.075 237.717C116.075 238.823 116.944 239.72 118.015 239.72Z" fill="url(#paint71_radial_818_4376)"/>
        <path d="M58.4982 194.969C59.5694 194.969 60.4378 194.072 60.4378 192.966C60.4378 191.86 59.5694 190.964 58.4982 190.964C57.427 190.964 56.5586 191.86 56.5586 192.966C56.5586 194.072 57.427 194.969 58.4982 194.969Z" fill="url(#paint72_radial_818_4376)"/>
        <path d="M37.1546 155.564C39.2971 155.564 41.0339 153.77 41.0339 151.558C41.0339 149.346 39.2971 147.553 37.1546 147.553C35.0122 147.553 33.2754 149.346 33.2754 151.558C33.2754 153.77 35.0122 155.564 37.1546 155.564Z" fill="url(#paint73_radial_818_4376)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_818_4376" x1="147.307" y1="92.7887" x2="202.542" y2="142.939" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint1_linear_818_4376" x1="147.048" y1="92.676" x2="202.73" y2="143.233" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_818_4376" x1="145.992" y1="94.1002" x2="196.623" y2="140.07" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint3_linear_818_4376" x1="112.433" y1="162.509" x2="153.543" y2="199.834" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint4_linear_818_4376" x1="112.233" y1="162.422" x2="153.677" y2="200.051" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint5_linear_818_4376" x1="112.125" y1="162.938" x2="153.264" y2="200.289" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint6_linear_818_4376" x1="113.348" y1="163.085" x2="153.664" y2="199.69" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint7_linear_818_4376" x1="111.454" y1="163.484" x2="149.134" y2="197.695" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint8_linear_818_4376" x1="119.971" y1="164.336" x2="154.736" y2="195.9" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint9_linear_818_4376" x1="178.969" y1="91.8673" x2="99.4454" y2="101.967" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint10_linear_818_4376" x1="176.958" y1="68.1226" x2="81.6133" y2="80.231" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint11_linear_818_4376" x1="175.429" y1="69.1469" x2="82.4516" y2="80.9547" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint12_linear_818_4376" x1="172.765" y1="69.9317" x2="81.428" y2="81.5312" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint13_linear_818_4376" x1="162.539" y1="65.4614" x2="85.1123" y2="75.2943" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint14_linear_818_4376" x1="177.888" y1="77.8991" x2="110.035" y2="86.5162" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint15_linear_818_4376" x1="177.313" y1="67.9174" x2="81.2187" y2="80.121" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint16_linear_818_4376" x1="127.36" y1="154.395" x2="54.6519" y2="143.141" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint17_linear_818_4376" x1="127.613" y1="154.54" x2="54.3076" y2="143.193" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint18_linear_818_4376" x1="127.169" y1="155.082" x2="54.4019" y2="143.818" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint19_linear_818_4376" x1="126.014" y1="153.858" x2="54.7015" y2="142.819" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint20_linear_818_4376" x1="127.169" y1="156.273" x2="60.5143" y2="145.956" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint21_linear_818_4376" x1="119.275" y1="147.625" x2="57.7927" y2="138.108" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint22_linear_818_4376" x1="131.865" y1="186.993" x2="40.3772" y2="205.392" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint23_linear_818_4376" x1="132.124" y1="186.938" x2="40.1172" y2="205.441" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint24_linear_818_4376" x1="128.439" y1="185.635" x2="39.7224" y2="203.476" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint25_linear_818_4376" x1="127.062" y1="185.532" x2="40.8938" y2="202.861" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint26_linear_818_4376" x1="130.929" y1="187.895" x2="48.576" y2="204.456" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint27_linear_818_4376" x1="119.097" y1="184.677" x2="47.6934" y2="199.037" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint28_linear_818_4376" x1="124.142" y1="125.113" x2="182.033" y2="169.633" gradientUnits="userSpaceOnUse">
          <stop stopColor="#40003C"/>
          <stop offset="1" stopColor="#871A6E"/>
        </linearGradient>
        <linearGradient id="paint29_linear_818_4376" x1="124.75" y1="126.377" x2="181.2" y2="169.789" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint30_linear_818_4376" x1="126.171" y1="127.95" x2="181.622" y2="170.594" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint31_linear_818_4376" x1="134.428" y1="129.157" x2="181.437" y2="165.309" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint32_linear_818_4376" x1="119.886" y1="131.036" x2="161.082" y2="162.717" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint33_linear_818_4376" x1="123.993" y1="124.831" x2="182.338" y2="169.701" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B37839"/>
          <stop offset="1" stopColor="#FFF99E"/>
        </linearGradient>
        <linearGradient id="paint34_linear_818_4376" x1="123.67" y1="57.2037" x2="138.745" y2="105.87" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint35_linear_818_4376" x1="123.505" y1="56.6364" x2="139.219" y2="107.366" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint36_linear_818_4376" x1="123.339" y1="57.5828" x2="138.377" y2="106.13" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint37_linear_818_4376" x1="124.269" y1="58.2626" x2="139.028" y2="105.912" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint38_linear_818_4376" x1="122.135" y1="57.2888" x2="135.995" y2="102.035" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint39_linear_818_4376" x1="129.444" y1="61.9966" x2="142.564" y2="104.353" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint40_linear_818_4376" x1="70.7432" y1="80.8469" x2="82.8446" y2="119.915" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint41_linear_818_4376" x1="70.5762" y1="80.2722" x2="83.3789" y2="121.604" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint42_linear_818_4376" x1="70.6827" y1="81.8337" x2="82.6853" y2="120.582" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint43_linear_818_4376" x1="71.3714" y1="82.3852" x2="82.9922" y2="119.902" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint44_linear_818_4376" x1="69.4994" y1="81.1506" x2="80.534" y2="116.774" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint45_linear_818_4376" x1="75.2136" y1="85.7102" x2="85.1813" y2="117.889" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint46_linear_818_4376" x1="146.495" y1="63.4825" x2="81.4348" y2="37.1488" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint47_linear_818_4376" x1="153.982" y1="49.6535" x2="80.1996" y2="19.7895" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint48_linear_818_4376" x1="152.652" y1="49.7468" x2="79.7129" y2="20.2241" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint49_linear_818_4376" x1="150.669" y1="49.7357" x2="79.234" y2="20.8218" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint50_linear_818_4376" x1="145.742" y1="44.2655" x2="86.0091" y2="20.0881" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint51_linear_818_4376" x1="150.896" y1="57.9665" x2="98.4583" y2="36.7419" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint52_linear_818_4376" x1="154.651" y1="49.5368" x2="79.7932" y2="19.2375" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint53_linear_818_4376" x1="86.4376" y1="82.9358" x2="34.9135" y2="42.2062" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint54_linear_818_4376" x1="86.5811" y1="83.0869" x2="34.4395" y2="41.8692" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint55_linear_818_4376" x1="86.5442" y1="83.7497" x2="34.1028" y2="42.295" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint56_linear_818_4376" x1="86.2419" y1="82.5796" x2="34.7178" y2="41.85" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint57_linear_818_4376" x1="85.6415" y1="84.3369" x2="37.5707" y2="46.3372" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint58_linear_818_4376" x1="83.6674" y1="75.522" x2="38.996" y2="40.2094" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint59_linear_818_4376" x1="74.0022" y1="104.137" x2="7.3269" y2="82.6297" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint60_linear_818_4376" x1="74.5464" y1="104.324" x2="6.77594" y2="82.4636" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint61_linear_818_4376" x1="73.073" y1="102.504" x2="7.16859" y2="81.2448" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint62_linear_818_4376" x1="72.0922" y1="101.797" x2="8.0035" y2="81.1237" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint63_linear_818_4376" x1="73.5012" y1="104.852" x2="12.244" y2="85.0924" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint64_linear_818_4376" x1="66.7112" y1="96.8256" x2="12.8609" y2="79.4551" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint65_linear_818_4376" x1="94.7957" y1="65.3884" x2="114.039" y2="112.948" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7BA79"/>
          <stop offset="1" stopColor="#FFFCD6"/>
        </linearGradient>
        <linearGradient id="paint66_linear_818_4376" x1="94.9979" y1="67.181" x2="113.325" y2="112.475" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint67_linear_818_4376" x1="95.398" y1="68.9612" x2="113.253" y2="113.09" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint68_linear_818_4376" x1="100.256" y1="72.2767" x2="116.028" y2="111.256" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint69_linear_818_4376" x1="89.6304" y1="67.3275" x2="102.923" y2="100.18" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <linearGradient id="paint70_linear_818_4376" x1="94.9588" y1="64.6863" x2="114.645" y2="113.339" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </linearGradient>
        <radialGradient id="paint71_radial_818_4376" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(117.064 236.868) scale(2.86038 2.95325)">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </radialGradient>
        <radialGradient id="paint72_radial_818_4376" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.5473 192.117) scale(2.86038 2.95325)">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </radialGradient>
        <radialGradient id="paint73_radial_818_4376" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.2527 149.86) scale(5.71321 5.8987)">
          <stop stopColor="#FFB566"/>
          <stop offset="1" stopColor="#B8790E"/>
        </radialGradient>
        <clipPath id="clip0_818_4376">
          <rect width="160" height="240" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    );
  };
  