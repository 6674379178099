export const LocationSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.9973 4C8.69097 4 6 6.63318 6 9.86724C6 15.5578 11.6597 19.8838 11.6597 19.8838C11.8585 20.0387 12.1404 20.0387 12.3392 19.8838C12.3392 19.8838 18 15.5578 18 9.86724C18 6.63318 15.3037 4 11.9973 4ZM11.9973 7.20135C13.4967 7.20135 14.7271 8.40066 14.7271 9.86724C14.7271 11.3338 13.4967 12.5362 11.9973 12.5362C10.498 12.5362 9.27185 11.3338 9.27186 9.86724C9.27186 8.40066 10.498 7.20135 11.9973 7.20135Z"
        stroke="white"
      />
    </svg>
  );
};
