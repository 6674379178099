import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import faq1 from "../../../src/assets/images/faq1.png";
import faq2 from "../../../src/assets/images/faq2.png";
import { FaqData } from "./FaqData";
import CustomContainer from "../CustomContainer/CustomContainer";
import "./style.css";

const FaqSection: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <CustomContainer  >
      <Box sx={{
        marginTop: "5%",
        width: { xs: "85%", md: "100%" },
        margin: "auto"
      }}>
        <Box
          sx={{
            display: "block",
            position: "relative",
            background: "#FFF",
            height: "auto",
          }}
        >
          {/* Isolation Mode Images */}
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              right: "0px",
              width: "10%",
            }}
          >
            <img src={faq2} alt="Isolation Mode" style={{ width: "100%" }} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "10%",
            }}
          >
            <img src={faq1} alt="Isolation Mode" style={{ width: "100%" }} />
          </Box>

          {/* FAQ Title */}
          <Box sx={{ width: { xs: "100%", md: "100%" }, margin: "auto" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: { xs: "20px", md: "34px" },
                fontWeight: { xs: "500", md: "600" },
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              FAQs
            </Typography>
          </Box>

          {/* FAQ Accordions */}
          <Box
            sx={{
              width: "100%",
              margin: "5% 0%",
              borderRadius: "16px",
              background: "#FFF",
              boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.06)",
            }}
          >
            {FaqData.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                sx={{ padding: "1% 3%" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                >
                  <Typography
                    sx={{
                      flexShrink: 0,
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize:
                        expanded === `panel${index + 1}`
                          ? { xs: "12px", md: "20px" }
                          : { xs: "12px", md: "16px" },
                      fontWeight: expanded === `panel${index + 1}` ? 500 : 400,
                      lineHeight:
                        expanded === `panel${index + 1}` ? "160%" : "normal",
                      width: "100%",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Display either steps or an answer */}
                  {faq.steps ? (
                    <ul style={{ listStyle: "none", paddingLeft: "0%" }}>
                      {faq.steps.map((step, i) => (
                        <li
                          key={i}
                          style={{
                            color: "#000",
                            fontFamily: "Poppins",
                            width: "100%",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            marginBottom: "2%",
                          }}
                          className="faq-item-answer"
                        >
                          Step {i + 1} : {step}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        width: "100%",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "160%",
                      }}
                      className="faq-item-answer"
                    >
                      {faq.answer}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default FaqSection;
