import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VerticalarStepper } from "../VerticalarStepper/VerticalarStepper";
import StackedImage from "../StackedImage/StackedImage";
import { BackgroundSvg1 } from "./BackgroundSvg1";
import { BackgroundSvg2 } from "./BackgroundSvg2";
import CustomContainer from "../CustomContainer/CustomContainer";
import EnterYourDetailsModal from "../EnterYourDetailsModal/EnterYourDetailsModal";
import "./style.css";
import SingleStackImg from "../SingleStackImg/SingleStackImg";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";

interface HowWorkSectionProps {
  activePanelNo: number; // Input number from another component
}

const HowWorkSection: React.FC<HowWorkSectionProps> = ({ activePanelNo }) => {
  const [activePanel, setActivePanel] = useState("blue");
  const panels = ["blue", "red", "violet", "violet", "green"];

  const [isFormModalOpen, setIsFormModalOpen] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();


  const handleOpenModal = () => {
    setIsFormModalOpen(true); // Explicitly set to true
  };

  const handleStep = (num: number) => {
    setStep(num);
  };

  const handleCloseModal = () => {
    setIsFormModalOpen(false); // Explicitly set to false
  };

  useEffect(() => {
    handleStep(activePanelNo);
  }, [activePanelNo]);


  const handleClick = async () => {
    try {
      // Open the modal first
      handleOpenModal();

      // Log the first event
      await createCustomerEventLog({
        event_name: `click_on_request_call_back_HIW_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event",
      });

      // Log the second event after the first one is completed
      await createCustomerEventLog({
        event_name: `enter_your_details_popup_mounted_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event",
      });
    } catch (error) {
      console.error("Error in event logging:", error);
    }
  };


  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          margin: "auto",
          width: "100% !important",
        }}
      >
        <Box
          sx={{
            position: "relative",
            background:
              "rgba(255, 249, 158, 0.10) 16.59%",
            width: "100%"
          }}
        >
          {/* Top-right corner flower */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <BackgroundSvg1 />
          </Box>

          {/* Bottom-left corner flower */}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <BackgroundSvg2 />
          </Box>
          <Box
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                width: {
                  xs: "90%",
                  md: "80%"
                },
                margin: "auto"
              }}
            >
              {/* Steps Instructions */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Box sx={{ paddingBottom: "6%", paddingTop: "6%" }}>
                  <Grid container justifyContent="center">
                    <Grid size={{ xs: 12 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "34px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                          marginTop: "35px",
                          marginBottom: "40px",
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        How it works?
                      </Typography>
                      <VerticalarStepper
                        step={activePanelNo}
                        handleStep={(data: number) => handleStep(data)}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          display: "flex",
                          width: "263px",
                          height: "52px",
                          padding: "12px 24px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4px",
                          flexShrink: 0,
                          borderRadius: "12px",
                          border: "1px solid #B37839",
                          background: "#631041",
                          position: "unset",
                          color: "#FFF",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          marginTop: {
                            xs: "40px",
                            md: "40px"
                          },
                          marginBottom: {
                            xs: "50px",
                            md: "30px"
                          },
                        }}
                        onClick={() => {
                          // handleOpenModal();
                          handleClick()
                        }}
                      >
                        Request call back
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 6L15 12L9 18"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Person Image */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    textAlign: "center",
                    width: { md: "100%", xl: "65%", margin: "auto" },
                  }}
                >
                  <SingleStackImg />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <EnterYourDetailsModal open={isFormModalOpen} onClos={handleCloseModal} />
    </>
  );
};

export default HowWorkSection;
