import React from "react";
import { WhatsappIconSvg } from "./WhatsappIconSvg";
import "./style.css";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";

const WhatsAppIcon: React.FC = () => {
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();

  return (
    <a
      href=" https://bit.ly/Make-My-Wedding"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
      onClick={() => {
        createCustomerEventLog({
          event_name: `clicked_on_whatsapp_cta_${isMobile ? "mobile" : "desktop"}`,
          event_type: "click_event"
        })
      }}
    >
      <WhatsappIconSvg />
    </a>
  );
};

export default WhatsAppIcon;
