import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Custom hook to check if the device is mobile
function useMobile() {
  const theme = useTheme();
  // Match against the 'sm' breakpoint (for mobile vs desktop)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return isMobile;
}

export default useMobile;
