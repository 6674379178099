export const FrameSvg1 : React.FC = ()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="163" height="154" viewBox="0 0 163 154" fill="none">
        <g opacity="0.3" clipPath="url(#clip0_727_4323)">
          <path d="M39.0481 31.9631C42.9126 45.6362 37.0997 59.1148 32.2852 71.7348C27.7766 83.5125 23.9442 95.9705 21.545 108.364C19.339 119.785 19.3229 132.356 24.5883 142.951C28.6139 151.084 36.6972 155.652 45.65 153.449C55.1341 151.116 58.6927 139.922 57.9198 131.271C57.2918 124.208 49.8687 114.633 41.8499 116.658C34.7327 118.456 31.5606 127.124 32.7038 133.847C33.8149 140.343 44.0719 143.859 47.0508 136.666C49.2568 131.303 46.3585 127.626 41.2058 126.605C38.9193 126.152 38.2752 129.716 40.5456 130.169C41.9304 130.445 43.2829 130.445 44.0397 132.016C46.2618 136.601 39.7888 137.881 37.2768 136.082C34.5234 134.106 35.0869 129.343 35.6988 126.54C36.5522 122.571 39.1769 119.088 43.4279 118.618C48.6611 118.051 53.2824 123.689 54.9731 127.966C58.4351 136.73 53.8138 148.411 44.9415 151.505C34.4268 155.183 26.9876 145.041 24.1375 136.455C18.0026 117.922 26.0697 96.7967 31.4318 79.0898C36.0692 63.7967 44.8126 48.0824 39.4506 31.8173C39.3701 31.5581 38.9837 31.7039 39.0642 31.9631H39.0481Z" fill="url(#paint0_linear_727_4323)"/>
          <path d="M39.7226 50.1242C38.676 58.5159 31.8165 65.8061 27.0341 72.3996C22.7832 78.2641 19.5144 84.4202 18.3229 91.6455C16.294 103.974 27.9036 109.79 38.0158 104.168C43.1363 101.333 45.9864 95.1448 45.1813 89.4099C44.505 84.647 39.0302 81.6013 34.586 82.946C30.3029 84.242 27.5655 88.2759 30.5444 92.2935C32.686 95.161 37.5649 95.809 39.0463 92.0991C39.8836 90.0093 37.0175 88.6647 35.9225 90.6087C35.1979 91.8885 28.8537 88.4865 34.4894 85.7486C37.3395 84.3554 40.8337 86.2508 42.1863 88.8429C44.6821 93.6544 40.2057 99.5513 36.5344 102.03C32.686 104.622 27.4045 104.784 23.5239 102.257C19.8687 99.8915 20.0297 94.7074 20.6094 90.9489C21.9619 82.2332 27.3884 75.8826 31.7843 68.6087C35.2945 62.7928 39.8675 57.2199 40.0608 50.1242C40.0608 49.946 39.7548 49.9298 39.7387 50.1242H39.7226Z" fill="url(#paint1_linear_727_4323)"/>
          <path d="M24.8619 96.8293C22.4949 101.625 20.0313 106.144 15.6032 109.303C13.2523 110.972 10.5471 111.798 7.74535 112.236C1.33669 113.208 1.62652 105.221 5.76478 106.679C9.27505 107.926 11.5455 108.331 15.2812 107.489C18.5499 106.744 21.7703 104.897 23.445 101.949C23.5738 101.738 23.3 101.576 23.1229 101.673C19.033 104.038 13.9608 106.776 9.06572 105.124C7.4394 104.573 4.86306 102.921 3.02741 103.698C-0.27353 105.091 -0.901515 109.627 1.25617 112.446C4.31559 116.464 10.4022 114.731 14.2667 112.981C21.3839 109.741 22.5432 103.488 25.1196 96.9427C25.184 96.7807 24.9425 96.6511 24.8619 96.8293Z" fill="url(#paint2_linear_727_4323)"/>
          <path d="M23.4276 101.884C21.0284 105.205 15.8113 106.549 11.979 107.1C8.82297 107.554 4.94235 104.492 2.49483 107.246C2.3338 107.424 2.3177 107.732 2.46262 107.927C4.62031 110.567 10.562 110.697 13.6697 109.887C18.2749 108.672 21.592 106.371 23.8302 102.143C23.959 101.9 23.5887 101.657 23.4276 101.884Z" fill="url(#paint3_linear_727_4323)"/>
          <path d="M2.625 107.797C3.65554 111.102 11.2236 110.016 12.9143 107.959C13.3007 107.489 13.0914 106.533 12.399 106.501C10.8532 106.42 9.19468 107.246 7.64887 107.473C6.02256 107.716 4.41234 107.392 2.80212 107.538C2.67331 107.538 2.5928 107.684 2.6411 107.813L2.625 107.797Z" fill="url(#paint4_linear_727_4323)"/>
          <path d="M23.445 100.636C19.3712 109.887 19.0169 121.583 10.3217 128.193C8.79204 129.359 0.515531 131.952 3.05967 126.735C4.68599 123.382 9.66156 121.033 12.576 118.959C17.4228 115.508 21.0941 111.15 22.8975 105.399C22.978 105.108 22.5916 105.01 22.4306 105.172C18.7593 108.737 16.6016 112.997 12.415 116.156C8.79204 118.894 4.55717 121.162 1.7393 124.807C-1.62606 129.149 0.724859 133.118 6.02247 132.438C18.5661 130.834 23.0425 111.053 24.0408 100.847C24.073 100.507 23.6221 100.329 23.4611 100.653L23.445 100.636Z" fill="url(#paint5_linear_727_4323)"/>
          <path d="M22.3815 104.492C20.562 109.919 17.567 114.585 13.783 118.862C12.1245 120.741 10.321 122.491 8.61418 124.305C6.77853 126.265 4.57254 127.351 2.62418 129.068C2.0767 129.554 2.0284 130.477 2.7691 130.818C7.19719 132.81 11.7863 125.747 14.1855 122.912C18.6136 117.679 21.9146 111.523 23.0095 104.703C23.0739 104.33 22.4942 104.119 22.3654 104.492H22.3815Z" fill="url(#paint6_linear_727_4323)"/>
          <path d="M2.89832 130.477C10.885 132.843 17.1809 122.685 19.9183 116.61C20.2725 115.816 19.242 115.184 18.6784 115.8C13.9444 120.935 10.3697 127.918 2.94662 129.376C2.31864 129.505 2.28644 130.299 2.89832 130.477Z" fill="url(#paint7_linear_727_4323)"/>
          <path d="M19.7416 116.707C18.7754 122.734 19.0975 128.809 18.5339 134.884C18.0347 140.279 17.4068 147.293 12.9304 150.922C9.62942 150.29 8.37345 148.832 9.17856 146.564C9.46839 144.928 10.3218 143.34 11.0786 141.85C12.4151 139.193 13.9448 136.633 15.233 133.944C17.5839 129.052 19.9348 123.884 20.3696 118.408C20.3857 118.165 20.0475 117.906 19.8704 118.181C15.3618 125.099 12.8821 133.523 9.03364 140.878C7.69716 143.438 4.10638 153.482 9.88705 153.935C15.6516 154.389 18.2119 148.249 19.2746 143.535C21.2391 134.835 20.7077 125.569 20.4179 116.74C20.4179 116.351 19.806 116.335 19.7416 116.707Z" fill="url(#paint8_linear_727_4323)"/>
          <path d="M20.4171 117.112C17.5187 123.786 17.7119 131.368 15.2483 138.269C13.9923 141.785 12.4626 144.928 10.2083 147.892C9.20995 149.205 9.04893 150.582 9.38707 152.121C9.41928 152.25 9.5803 152.315 9.69301 152.315C17.8246 151.975 19.5475 141.445 19.4026 134.916C19.4026 134.317 18.4848 134.171 18.2594 134.705C16.9229 137.8 16.15 141.056 15.0711 144.247C14.3787 146.272 13.4287 148.216 12.0761 149.869C12.0117 149.934 9.08113 152.963 11.4965 151.068C12.2533 150.484 12.8329 149.674 13.4126 148.929C16.311 145.203 17.5831 140.538 18.8712 136.05C20.6747 129.813 19.8213 123.543 20.7713 117.209C20.8035 117.015 20.5137 116.917 20.4332 117.096L20.4171 117.112Z" fill="url(#paint9_linear_727_4323)"/>
          <path d="M39.1927 53.1533C37.808 59.6496 36.0206 65.6113 38.4198 72.059C39.7885 75.7365 42.3488 79.2682 46.487 79.4626C50.3837 79.657 52.3804 75.2181 50.2227 72.302C48.7735 70.3418 44.9734 69.2888 43.1056 67.7821C39.6275 64.9795 38.6131 57.9162 39.2088 53.6879C39.2411 53.5097 38.9834 53.4125 38.9029 53.5907C35.6342 60.7026 39.0961 68.333 45.5209 72.14C46.1006 72.4478 46.6803 72.7232 47.276 72.9662C46.9379 74.4243 46.6158 75.8661 46.2777 77.3241C45.0217 77.2269 43.9912 76.7571 43.1539 75.8985C41.5759 74.5377 40.6741 72.3344 39.9495 70.439C37.9207 65.2225 38.6131 58.5804 39.4826 53.2019C39.5148 52.9913 39.2088 52.9427 39.1605 53.1371L39.1927 53.1533Z" fill="url(#paint10_linear_727_4323)"/>
          <path d="M38.6284 64.0725C39.659 66.4216 40.1903 68.8192 41.5107 71.1035C42.8794 73.4687 44.8438 75.3966 47.1948 76.7412C48.805 77.6646 50.5118 75.0078 48.9499 74.0033C47.1143 72.8045 45.4235 71.4923 43.8938 69.9047C42.2836 68.2198 40.2708 65.968 39.2081 63.8781C39.031 63.5379 38.4835 63.7323 38.6445 64.0888L38.6284 64.0725Z" fill="url(#paint11_linear_727_4323)"/>
          <path d="M39.8544 50.3994C37.745 55.6807 38.9366 64.526 45.9732 64.8176C48.614 64.931 52.3819 62.7278 50.3852 59.7145C49.3869 58.2079 48.0504 57.3979 46.408 56.7499C45.0876 56.2315 43.8478 55.7455 42.7206 54.8706C40.885 53.445 40.6756 50.8692 40.2731 48.7469C40.2409 48.5687 40.0315 48.5687 39.9832 48.7469C39.1298 51.3714 39.2908 54.3522 41.529 56.3125C42.5596 57.2197 43.6062 57.7381 44.8944 58.2079C45.941 58.5967 51.0615 59.9575 48.6623 62.1446C45.78 64.7528 42.2375 61.5128 41.1909 58.9207C40.2248 56.5393 39.9027 53.1048 40.2409 50.5452C40.2731 50.3346 39.951 50.205 39.8705 50.4318L39.8544 50.3994Z" fill="url(#paint12_linear_727_4323)"/>
          <path d="M39.837 50.7878C39.5954 53.9307 40.2073 56.4417 42.204 58.969C43.5888 60.7186 45.6015 62.8409 47.9364 63.1001C50.1746 63.3431 50.8186 59.86 48.5804 59.617C47.0346 59.455 45.6176 57.8188 44.5549 56.7982C42.9125 55.2105 40.8675 53.0235 40.2878 50.7392C40.2395 50.5124 39.8531 50.5448 39.837 50.7716V50.7878Z" fill="url(#paint13_linear_727_4323)"/>
          <path d="M44.8133 58.1103C45.8439 59.374 47.4702 60.67 48.694 58.9366C48.7906 58.807 48.8067 58.5801 48.6617 58.4667C48.1465 58.1103 47.8727 57.8187 47.1965 57.7215C46.5041 57.6405 45.8117 57.7701 45.1032 57.6729C44.8777 57.6405 44.6845 57.8997 44.8294 58.0941L44.8133 58.1103Z" fill="url(#paint14_linear_727_4323)"/>
          <path d="M24.4748 139.922C27.019 146.467 34.587 151.505 41.6236 150.711C48.7569 149.918 53.2816 143.713 54.4732 137.022C55.6325 130.542 51.7841 123.932 45.4076 122.15C39.0634 120.368 34.426 122.652 34.2811 129.521C34.2811 129.716 34.5709 129.764 34.6514 129.586C36.8896 123.819 41.7041 122.766 46.9696 125.504C52.3799 128.306 52.9918 134.592 51.1722 139.76C46.7602 152.283 30.7225 149.464 24.8291 139.76C24.7003 139.549 24.3782 139.728 24.4748 139.954V139.922Z" fill="url(#paint15_linear_727_4323)"/>
          <path d="M34.2971 129.489C37.276 122.199 45.1499 119.736 50.0128 126.816C54.1188 132.778 52.4925 139.144 50.673 145.463C50.528 145.965 51.156 146.208 51.5103 145.997C58.8851 141.834 58.4986 132.664 54.7629 125.876C52.6857 122.085 48.5636 119.736 44.5058 118.813C43.5397 118.586 42.5575 118.716 41.6074 118.813C41.0278 118.878 39.337 120.806 40.5125 120.806C43.0083 120.789 44.1355 119.558 46.8245 121.502C48.4026 122.636 49.1594 124.629 50.367 126.087C51.0755 126.962 52.5247 126.524 52.4442 125.326C52.2349 122.377 50.2221 119.963 47.839 118.392C44.683 116.334 41.849 118.813 39.0633 120.222C38.7735 120.368 38.8379 120.838 39.1921 120.806C45.3754 120.498 51.3815 124.548 53.4264 130.299C54.9883 134.706 54.5536 141.753 50.8984 145.122C51.1077 145.414 51.317 145.706 51.5103 146.013C59.4969 140.392 56.2926 127.901 50.4797 121.891C43.9423 115.119 33.814 120.87 33.9267 129.44C33.9267 129.635 34.2166 129.683 34.2971 129.505V129.489Z" fill="url(#paint16_linear_727_4323)"/>
          <path d="M53.6364 140.667C52.4287 142.158 51.366 143.745 50.1905 145.268C48.3871 147.601 44.9895 148.379 42.2844 148.946C39.6758 149.496 37.0673 149.432 34.6036 148.346C32.9451 147.617 31.7858 146.564 29.9823 146.192C29.6925 146.127 29.4026 146.451 29.5315 146.726C31.7858 151.975 38.9029 152.655 43.7497 151.683C50.1261 150.404 53.0406 147.099 54.2 140.91C54.2644 140.602 53.8618 140.408 53.6525 140.651L53.6364 140.667Z" fill="url(#paint17_linear_727_4323)"/>
          <path d="M29.9824 147.649C33.9274 152.72 45.2794 155.652 49.3855 149.286C49.7236 148.751 49.4821 147.795 48.677 147.925C46.1811 148.362 44.1845 149.772 41.5759 149.901C37.7114 150.096 33.7342 148.978 30.3205 147.228C30.079 147.099 29.8052 147.423 29.9824 147.633V147.649Z" fill="url(#paint18_linear_727_4323)"/>
          <path d="M44.878 134.722C43.4771 136.067 41.1906 136.018 39.4516 135.694C36.8913 135.224 36.054 132.762 35.8125 130.494C35.3938 126.411 37.5676 122.588 40.6109 120.109C40.788 119.963 40.6431 119.688 40.4177 119.753C34.6853 121.551 32.4793 127.983 33.9124 133.475C35.3777 139.064 42.205 139.047 45.2483 135.046C45.4577 134.787 45.0873 134.495 44.8619 134.722H44.878Z" fill="url(#paint19_linear_727_4323)"/>
          <path d="M40.9795 128.031C40.9795 128.031 40.915 127.934 40.8828 127.885C40.7379 127.642 40.432 127.529 40.1582 127.545C37.6785 127.788 38.548 130.493 39.4659 131.887C40.0294 132.761 40.7862 133.409 41.6718 133.928C42.5414 134.43 42.8634 134.641 42.2193 135.629C41.8651 136.18 41.688 136.763 41.4303 137.346C41.2854 137.654 41.4786 137.978 41.7846 138.059C45.1821 138.869 46.8406 134.608 44.6507 132.259C44.216 131.806 43.4592 131.546 42.9117 131.303C42.3159 131.044 41.849 130.542 41.382 130.105C41.0439 129.781 40.4803 129.278 40.8506 128.857C41.0278 128.647 41.1405 128.274 40.9795 128.031Z" fill="url(#paint20_linear_727_4323)"/>
          <path d="M41.7536 137.476C43.6697 137.184 46.4715 135.564 46.2299 133.248C46.1011 131.952 45.6503 130.963 44.6519 130.121C43.9917 129.57 41.4637 129.084 41.4476 128.096C41.029 128.598 40.5942 129.1 40.1755 129.619C43.2672 129.392 42.9934 135.937 39.8213 134.657C37.0678 133.556 35.9407 130.737 36.3593 127.966C36.5848 126.444 37.4543 125.131 38.5975 124.159C40.401 122.604 42.7358 123.268 44.8613 123.042C45.3765 122.993 45.602 122.329 45.1994 121.972C41.0129 118.392 35.3449 122.28 34.0889 126.849C32.8813 131.239 34.6847 136.439 39.3704 137.784C46.5842 139.841 47.2927 126.978 39.9501 126.832C39.3865 126.832 38.7263 127.399 38.6941 127.983C38.6136 129.343 38.984 130.38 40.1916 131.142C40.9645 131.628 41.8019 131.984 42.607 132.389C44.6358 133.41 42.1078 136.002 41.4637 136.909C41.3027 137.136 41.4315 137.541 41.7697 137.492L41.7536 137.476Z" fill="url(#paint21_linear_727_4323)"/>
          <path d="M39.4822 31.5417C52.6215 36.7582 66.5338 32.2869 79.4799 28.7067C91.5727 25.3694 104.277 22.7612 116.773 21.5785C128.286 20.4931 140.716 21.7405 150.684 28.0425C158.332 32.8864 162.036 41.4239 158.976 50.1559C155.74 59.4224 144.307 61.8849 135.821 60.2486C128.897 58.9202 120.17 50.5285 122.956 42.7199C125.436 35.77 134.324 33.4534 140.861 35.2678C147.173 37.0174 149.637 47.6448 142.214 49.9129C136.707 51.5977 133.342 48.3252 132.842 43.0601C132.617 40.7273 136.208 40.4357 136.433 42.7523C136.562 44.178 136.433 45.5064 137.915 46.4298C142.23 49.119 144.146 42.7523 142.6 40.0631C140.91 37.1146 136.16 37.2118 133.326 37.5358C129.3 37.9894 125.613 40.2737 124.727 44.4858C123.648 49.6699 128.769 54.854 132.842 56.96C141.167 61.2855 153.18 57.8348 157.125 49.2486C161.794 39.0911 152.503 30.6345 144.291 26.9408C126.579 18.9703 104.873 24.9482 86.8386 28.5609C71.2517 31.6875 54.8436 38.8805 39.2889 31.9143C39.0474 31.8009 39.2245 31.4445 39.4661 31.5417H39.4822Z" fill="url(#paint22_linear_727_4323)"/>
          <path d="M57.3702 34.0044C65.7755 33.7938 73.6494 27.6377 80.6539 23.5066C86.8854 19.8291 93.278 17.1561 100.556 16.6863C112.955 15.8762 117.544 28.0751 110.99 37.6494C107.673 42.4933 101.281 44.729 95.6933 43.3519C91.0559 42.2179 88.5761 36.4182 90.3474 32.0927C92.0542 27.9455 96.3052 25.5964 99.9926 28.9823C102.617 31.3961 102.778 36.3534 98.9459 37.4712C96.7882 38.1031 95.7416 35.106 97.7866 34.1988C99.1231 33.5994 96.3857 26.9086 93.117 32.2871C91.4584 35.0088 92.972 38.7025 95.4035 40.3063C99.9121 43.2709 106.192 39.3829 109.01 35.9484C111.956 32.3519 112.633 27.0869 110.523 22.9396C108.543 19.0515 103.39 18.6951 99.6383 18.9057C90.8949 19.3917 84.0675 24.2032 76.4351 27.8807C70.3324 30.8129 64.3746 34.8468 57.338 34.3446C57.1608 34.3446 57.1769 34.0206 57.3541 34.0206L57.3702 34.0044Z" fill="url(#paint23_linear_727_4323)"/>
          <path d="M105.034 23.7498C110.01 21.8544 114.728 19.8455 118.287 15.7145C120.17 13.5274 121.265 10.903 121.974 8.13272C123.584 1.81461 115.63 1.2962 116.676 5.58928C117.562 9.23434 117.739 11.5348 116.531 15.1961C115.469 18.3875 113.327 21.4332 110.252 22.8264C110.026 22.9236 109.897 22.632 110.01 22.47C112.747 18.6143 115.968 13.8028 114.825 8.74833C114.438 7.0635 113.069 4.32565 114.019 2.55982C115.726 -0.599239 120.267 -0.793642 122.86 1.6526C126.531 5.11947 124.212 11.0326 122.103 14.7262C118.19 21.5304 111.894 22.0812 105.163 23.9928C104.986 24.0414 104.89 23.7822 105.067 23.7174L105.034 23.7498Z" fill="url(#paint24_linear_727_4323)"/>
          <path d="M110.186 22.8105C113.712 20.7369 115.548 15.65 116.482 11.8591C117.239 8.74864 114.598 4.56897 117.561 2.38193C117.754 2.23612 118.06 2.25233 118.237 2.41433C120.636 4.82817 120.185 10.8061 119.058 13.8193C117.4 18.3068 114.791 21.4011 110.395 23.2155C110.138 23.3127 109.944 22.9239 110.17 22.7943L110.186 22.8105Z" fill="url(#paint25_linear_727_4323)"/>
          <path d="M118.092 2.57555C121.264 3.93637 119.445 11.4047 117.223 12.8789C116.723 13.2191 115.789 12.9275 115.838 12.2147C115.918 10.6595 116.884 9.08807 117.271 7.54904C117.673 5.94521 117.496 4.30898 117.818 2.70515C117.851 2.57555 117.979 2.51075 118.108 2.55935L118.092 2.57555Z" fill="url(#paint26_linear_727_4323)"/>
          <path d="M108.947 22.7127C118.511 19.5537 130.105 20.3475 137.496 12.3121C138.8 10.9027 142.182 2.86737 136.771 4.89241C133.293 6.18843 130.475 10.9351 128.124 13.6568C124.228 18.1767 119.558 21.4167 113.681 22.6317C113.391 22.6965 113.326 22.2915 113.504 22.1457C117.384 18.8247 121.812 17.0912 125.371 13.2032C128.43 9.8497 131.103 5.83203 134.984 3.36958C139.621 0.421128 143.293 3.17518 142.101 8.40787C139.283 20.8173 119.252 23.3121 109.059 23.3121C108.721 23.3121 108.592 22.8261 108.931 22.7127H108.947Z" fill="url(#paint27_linear_727_4323)"/>
          <path d="M112.859 22.0323C118.415 20.7525 123.326 18.2091 127.915 14.8556C129.928 13.3814 131.844 11.7613 133.824 10.2223C135.934 8.56989 137.238 6.48005 139.122 4.69802C139.653 4.19581 140.587 4.22821 140.845 5.02202C142.375 9.65531 134.935 13.5434 131.892 15.6656C126.272 19.5861 119.864 22.2753 113.004 22.6965C112.634 22.7127 112.473 22.1295 112.859 22.0323Z" fill="url(#paint28_linear_727_4323)"/>
          <path d="M140.491 5.08642C142.037 13.3162 131.361 18.6137 125.081 20.7521C124.26 21.0275 123.745 19.9421 124.405 19.4237C129.96 15.1954 137.206 12.2956 139.396 5.02162C139.573 4.40601 140.378 4.45461 140.491 5.07022V5.08642Z" fill="url(#paint29_linear_727_4323)"/>
          <path d="M125.209 20.5908C131.264 20.2182 137.238 21.1416 143.308 21.174C148.686 21.2064 155.691 21.2712 159.716 17.1563C159.41 13.7866 158.106 12.3772 155.771 12.9604C154.113 13.09 152.47 13.7866 150.925 14.386C148.171 15.4715 145.482 16.7351 142.696 17.7557C137.624 19.6187 132.278 21.4656 126.836 21.3684C126.578 21.3684 126.369 20.9958 126.659 20.85C133.937 17.0105 142.519 15.3743 150.168 12.2476C152.825 11.1622 163.114 8.57014 162.985 14.386C162.872 20.202 156.544 22.1622 151.762 22.7616C142.97 23.8632 133.856 22.4214 125.161 21.2388C124.775 21.1902 124.823 20.5908 125.193 20.5584L125.209 20.5908Z" fill="url(#paint30_linear_727_4323)"/>
          <path d="M125.532 21.3029C132.408 19.0673 139.895 20.0069 146.98 18.2087C150.587 17.3015 153.84 16.0702 156.996 14.11C158.397 13.2352 159.765 13.2028 161.263 13.705C161.392 13.7536 161.44 13.9156 161.424 14.029C160.281 22.1453 149.702 22.8096 143.261 22.0157C142.665 21.9347 142.617 21.0275 143.164 20.8493C146.368 19.8125 149.653 19.3589 152.922 18.5975C154.999 18.1115 156.996 17.3339 158.767 16.1512C158.848 16.1026 162.132 13.462 160.023 15.6976C159.363 16.3942 158.509 16.8965 157.72 17.3987C153.743 19.9259 149.009 20.7359 144.452 21.5945C138.108 22.7772 131.989 21.3191 125.629 21.6269C125.436 21.6269 125.355 21.3515 125.548 21.2867L125.532 21.3029Z" fill="url(#paint31_linear_727_4323)"/>
          <path d="M60.4147 33.7778C66.9844 33.0326 73.0549 31.8337 79.1899 34.8794C82.684 36.6128 85.9206 39.5289 85.7112 43.6923C85.518 47.6128 80.9289 49.1842 78.2559 46.7218C76.4525 45.0694 75.7923 41.1813 74.488 39.1563C72.0566 35.414 65.1649 33.6968 60.93 33.875C60.7529 33.875 60.6885 33.6158 60.8656 33.551C68.2243 30.9913 75.4381 35.2034 78.5619 42.0237C78.8195 42.6231 79.0288 43.2387 79.206 43.8706C80.6712 43.6761 82.1365 43.4979 83.6018 43.3035C83.634 42.0399 83.2637 40.9545 82.4908 40.0473C81.2992 38.33 79.206 37.2122 77.4025 36.305C72.443 33.7616 65.809 33.794 60.3986 34.1342C60.1893 34.1342 60.1571 33.8264 60.3664 33.8102L60.4147 33.7778Z" fill="url(#paint32_linear_727_4323)"/>
          <path d="M71.2841 34.296C73.5062 35.5758 75.8249 36.3372 77.9504 37.8763C80.1564 39.4639 81.8632 41.6347 82.9582 44.1134C83.6989 45.8144 80.9293 47.2562 80.0759 45.6038C79.0776 43.6436 77.9343 41.8129 76.5173 40.1281C75.0037 38.3461 72.9748 36.1104 71.0265 34.8306C70.7044 34.62 70.946 34.1016 71.2841 34.296Z" fill="url(#paint33_linear_727_4323)"/>
          <path d="M57.6298 34.1666C63.0562 32.579 71.703 34.6526 71.2844 41.716C71.1395 44.3728 68.5792 47.9207 65.7935 45.6364C64.4088 44.4862 63.7486 43.0768 63.2494 41.3596C62.8629 39.9987 62.5087 38.7027 61.7519 37.4715C60.5281 35.495 58.0001 35.0252 55.939 34.4096C55.778 34.361 55.7941 34.1504 55.939 34.118C58.6281 33.5348 61.5426 33.9884 63.2655 36.4185C64.0545 37.5525 64.4732 38.6379 64.8113 39.9825C65.085 41.0679 65.9385 46.333 68.3216 44.146C71.1878 41.5054 68.3216 37.6497 65.8741 36.3375C63.6037 35.1386 60.2383 34.4744 57.6942 34.5554C57.4687 34.5554 57.3882 34.2314 57.6136 34.1666H57.6298Z" fill="url(#paint34_linear_727_4323)"/>
          <path d="M58.0163 34.1827C61.1402 34.2475 63.5716 35.1223 65.8742 37.358C67.4683 38.9132 69.3684 41.1489 69.4006 43.4979C69.4328 45.7659 65.9064 46.0737 65.8903 43.7895C65.8903 42.2343 64.4089 40.6466 63.5072 39.4802C62.1063 37.6658 60.1418 35.4302 57.9358 34.6039C57.7265 34.5229 57.7909 34.1503 58.0163 34.1665V34.1827Z" fill="url(#paint35_linear_727_4323)"/>
          <path d="M64.7623 39.885C65.9055 41.0352 67.0327 42.801 65.197 43.8379C65.0521 43.9189 64.8428 43.9189 64.7462 43.7569C64.4402 43.206 64.1665 42.8982 64.1504 42.2016C64.1504 41.4888 64.3436 40.8246 64.3114 40.0956C64.3114 39.8688 64.5852 39.6906 64.7623 39.8688V39.885Z" fill="url(#paint36_linear_727_4323)"/>
          <path d="M147.689 27.6212C153.904 30.8288 158.139 38.8966 156.658 45.8627C155.16 52.926 148.574 56.8465 141.844 57.3811C135.322 57.8995 129.171 53.3958 128.028 46.8347C126.901 40.306 129.622 35.8995 136.417 36.4341C136.611 36.4503 136.627 36.7581 136.45 36.8067C130.524 38.4754 129.01 43.1896 131.184 48.7301C133.422 54.4165 139.573 55.6639 144.871 54.3516C157.688 51.1602 156.497 34.8465 147.463 27.9776C147.27 27.8318 147.48 27.524 147.689 27.6374V27.6212Z" fill="url(#paint37_linear_727_4323)"/>
          <path d="M136.417 36.4345C128.914 38.7025 125.693 46.3328 132.215 51.9057C137.706 56.6038 144.163 55.6156 150.587 54.4168C151.103 54.3196 151.28 54.9676 151.038 55.3078C146.191 62.2739 137.158 60.9779 130.814 56.5714C127.271 54.109 125.355 49.7673 124.84 45.6038C124.727 44.6156 124.937 43.6436 125.13 42.6878C125.243 42.1208 127.336 40.6141 127.207 41.7968C126.949 44.2916 125.613 45.296 127.271 48.1959C128.238 49.8807 130.121 50.8527 131.458 52.1973C132.247 52.9912 131.667 54.4006 130.492 54.2062C127.593 53.704 125.404 51.4521 124.099 48.9087C122.376 45.539 125.114 42.9632 126.788 40.3063C126.965 40.0309 127.416 40.1443 127.352 40.5007C126.434 46.6568 129.848 53.0722 135.339 55.6966C139.541 57.7054 146.562 57.9485 150.249 54.6274C150.523 54.8704 150.797 55.0972 151.054 55.3402C144.71 62.7761 132.666 58.3211 127.288 51.9057C121.233 44.6804 127.932 35.1222 136.385 36.0942C136.578 36.1104 136.595 36.4183 136.417 36.4669V36.4345Z" fill="url(#paint38_linear_727_4323)"/>
          <path d="M145.546 56.9115C147.14 55.8422 148.815 54.935 150.441 53.9144C152.921 52.343 154.032 49.0057 154.87 46.3651C155.675 43.8054 155.868 41.1972 155.031 38.6213C154.467 36.8879 153.549 35.6243 153.356 33.7774C153.324 33.4858 153.662 33.2266 153.936 33.3724C158.911 36.1427 158.863 43.3518 157.43 48.0985C155.53 54.368 151.987 56.9439 145.74 57.4947C145.434 57.5271 145.273 57.0897 145.546 56.9277V56.9115Z" fill="url(#paint39_linear_727_4323)"/>
          <path d="M154.806 33.907C159.427 38.362 161.198 50.0101 154.5 53.4931C153.936 53.7847 153.018 53.4445 153.228 52.6669C153.92 50.2045 155.498 48.3576 155.885 45.7494C156.464 41.9099 155.74 37.795 154.355 34.231C154.258 33.9718 154.596 33.7288 154.79 33.9232L154.806 33.907Z" fill="url(#paint40_linear_727_4323)"/>
          <path d="M140.522 47.5315C141.988 46.2679 142.165 43.9675 142.02 42.2016C141.811 39.5934 139.46 38.508 137.238 38.0544C133.244 37.2281 129.235 39.0264 126.497 41.829C126.336 41.991 126.079 41.829 126.175 41.6022C128.526 36.0455 135.096 34.4579 140.394 36.4505C145.772 38.4594 145.079 45.3121 140.828 47.9527C140.539 48.1309 140.297 47.7421 140.539 47.5315H140.522Z" fill="url(#paint41_linear_727_4323)"/>
          <path d="M134.307 42.9797C134.307 42.9797 134.21 42.9149 134.162 42.8663C133.937 42.6881 133.84 42.3803 133.904 42.1049C134.387 39.6424 136.98 40.7926 138.268 41.8294C139.073 42.4937 139.653 43.3037 140.071 44.2433C140.49 45.1667 140.651 45.5069 141.698 44.9561C142.277 44.6483 142.873 44.5349 143.469 44.3243C143.791 44.2109 144.097 44.4377 144.145 44.7617C144.612 48.2448 140.232 49.476 138.123 47.0621C137.704 46.5923 137.543 45.7985 137.35 45.2315C137.157 44.6159 136.706 44.0975 136.32 43.5791C136.03 43.2065 135.595 42.6071 135.144 42.9149C134.919 43.0769 134.548 43.1579 134.307 42.9635V42.9797Z" fill="url(#paint42_linear_727_4323)"/>
          <path d="M143.566 44.6966C143.083 46.5759 141.215 49.2165 138.945 48.7629C137.673 48.5037 136.739 47.9529 136.014 46.8675C135.531 46.1547 135.306 43.5626 134.324 43.4654C134.855 43.0928 135.402 42.7202 135.95 42.3476C135.419 45.4257 141.924 45.7821 140.974 42.4934C140.153 39.6422 137.48 38.2165 134.694 38.3623C133.164 38.4433 131.796 39.1885 130.701 40.2254C128.978 41.8778 129.396 44.2754 128.978 46.3815C128.865 46.8999 128.189 47.0457 127.883 46.6083C124.759 42.0722 129.155 36.7747 133.808 35.9647C138.269 35.1871 143.228 37.5199 144.098 42.3314C145.418 49.7511 132.617 49.2003 133.213 41.8292C133.261 41.2622 133.905 40.6628 134.469 40.6952C135.837 40.7438 136.803 41.2298 137.447 42.4934C137.85 43.3034 138.108 44.1782 138.43 45.0369C139.235 47.1753 142.053 44.8911 143.019 44.3402C143.26 44.2106 143.647 44.3726 143.566 44.6966Z" fill="url(#paint43_linear_727_4323)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_727_4323" x1="58.0221" y1="154.001" x2="27.7963" y2="155.863" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint1_linear_727_4323" x1="45.3162" y1="106.32" x2="23.7061" y2="108.398" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint2_linear_727_4323" x1="25.1304" y1="114.899" x2="6.41593" y2="120.053" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint3_linear_727_4323" x1="23.8569" y1="110.325" x2="10.0958" y2="117.222" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint4_linear_727_4323" x1="13.1063" y1="109.903" x2="6.9971" y2="113.641" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint5_linear_727_4323" x1="24.0424" y1="132.514" x2="5.33347" y2="135.287" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint6_linear_727_4323" x1="23.0145" y1="131.167" x2="6.73733" y2="133.674" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint7_linear_727_4323" x1="19.9907" y1="130.828" x2="6.64135" y2="133.876" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint8_linear_727_4323" x1="20.7082" y1="153.959" x2="9.54931" y2="154.788" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint9_linear_727_4323" x1="20.7738" y1="152.315" x2="11.5628" y2="152.914" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint10_linear_727_4323" x1="51.1076" y1="79.4687" x2="40.1201" y2="80.6144" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint11_linear_727_4323" x1="49.567" y1="76.933" x2="41.0256" y2="78.3394" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint12_linear_727_4323" x1="50.9468" y1="64.8218" x2="41.5137" y2="66.215" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint13_linear_727_4323" x1="49.9713" y1="63.1122" x2="42.0211" y2="64.398" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint14_linear_727_4323" x1="48.7685" y1="59.7747" x2="45.9708" y2="60.8297" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint15_linear_727_4323" x1="54.6785" y1="150.794" x2="31.4525" y2="155.572" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint16_linear_727_4323" x1="57.3736" y1="146.073" x2="39.0818" y2="149.071" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint17_linear_727_4323" x1="54.2069" y1="152.058" x2="37.4652" y2="159.203" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint18_linear_727_4323" x1="49.5338" y1="152.921" x2="38.8098" y2="160.231" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint19_linear_727_4323" x1="45.3102" y1="137.88" x2="35.9968" y2="139.088" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint20_linear_727_4323" x1="45.6407" y1="138.16" x2="40.0494" y2="138.904" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint21_linear_727_4323" x1="46.2446" y1="138.007" x2="36.416" y2="139.411" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint22_linear_727_4323" x1="160.049" y1="60.7189" x2="89.4138" y2="103.732" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint23_linear_727_4323" x1="113.972" y1="43.7529" x2="75.2288" y2="59.8933" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint24_linear_727_4323" x1="124.79" y1="23.9988" x2="109.332" y2="26.5366" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint25_linear_727_4323" x1="119.974" y1="23.2309" x2="112.11" y2="23.9705" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint26_linear_727_4323" x1="119.798" y1="13.0207" x2="116.642" y2="13.2582" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint27_linear_727_4323" x1="142.328" y1="23.3121" x2="117.86" y2="31.0447" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint28_linear_727_4323" x1="141.05" y1="22.6969" x2="120.265" y2="29.1056" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint29_linear_727_4323" x1="140.642" y1="20.7962" x2="127.918" y2="23.3784" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint30_linear_727_4323" x1="162.986" y1="23.1547" x2="140.742" y2="36.7185" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint31_linear_727_4323" x1="161.427" y1="22.2694" x2="143.938" y2="36.3525" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint32_linear_727_4323" x1="85.7209" y1="47.9024" x2="67.3659" y2="54.0648" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint33_linear_727_4323" x1="83.0827" y1="46.333" x2="73.6836" y2="48.2227" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint34_linear_727_4323" x1="71.299" y1="46.3691" x2="59.5902" y2="49.2061" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint35_linear_727_4323" x1="69.4008" y1="45.3582" x2="60.493" y2="47.1937" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint36_linear_727_4323" x1="66.1679" y1="43.8911" x2="64.5671" y2="44.048" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint37_linear_727_4323" x1="156.954" y1="57.4217" x2="134.439" y2="61.8055" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint38_linear_727_4323" x1="151.145" y1="60.2043" x2="130.124" y2="64.977" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint39_linear_727_4323" x1="158.27" y1="57.4964" x2="148.08" y2="58.5755" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint40_linear_727_4323" x1="158.915" y1="53.5966" x2="154.35" y2="53.8596" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint41_linear_727_4323" x1="144.223" y1="47.9982" x2="130.876" y2="51.8762" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint42_linear_727_4323" x1="144.18" y1="48.3171" x2="136.48" y2="50.37" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <linearGradient id="paint43_linear_727_4323" x1="144.193" y1="48.8144" x2="131.203" y2="52.2665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF99E"/>
            <stop offset="1" stopColor="#B37839"/>
          </linearGradient>
          <clipPath id="clip0_727_4323">
            <rect width="163.002" height="154" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    )
}