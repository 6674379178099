import { gql } from "@apollo/client";



export const customerLogMutation= gql`
mutation CreateOneMakemywedding_customer_page_event_logs($data: Makemywedding_customer_page_event_logsCreateInput) {
  createOneMakemywedding_customer_page_event_logs(data: $data) {
    id
  }
}
`