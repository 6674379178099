import { useMutation } from '@apollo/client';
import { customerLogMutation } from '../apollo/eventLogMutation';
import { Device } from '@capacitor/device';
import useMobile from './useMobile';

const useCustomerEventLogs = () => {
    const isMobile = useMobile();

    const sessionCreate = (): string => {
        const uniqueId = Math.floor(100000 + Math.random() * 90000); // Unique customerNumber suffix
        const newDate = new Date();
        const month = newDate.getMonth();
        const year = newDate.getFullYear().toString().substring(2);
        const customizedMonth = (month < 9 ? "0" : "") + (month + 1); // Adding prefix '0' to get 2 digits
        return `${customizedMonth}${year}${uniqueId}`;
    };

    const sessionId = sessionCreate(); // Always generate a sessionId without using storage

    const [logEvent, { data: eventLogData, loading, error }] = useMutation(customerLogMutation);

    const getDeviceDetails = async () => {
        const deviceInfo = await Device.getInfo();
        const deviceId = await Device.getId();
        const devicePlatform = `${isMobile ? "mobile_" : ""}${deviceInfo.platform}`;

        return {
            deviceId: deviceId.identifier || "",
            devicePlatform: devicePlatform || "",
        };
    };

    // Function to create and log customer event
    const createCustomerEventLog = async (data: any) => {
        try {
            const { deviceId, devicePlatform } = await getDeviceDetails();

            const response = await logEvent({
                variables: {
                    data: {
                        event_time: new Date().toISOString(),
                        session_id: sessionId,
                        additional_details: data?.additional_details,
                        device_id: deviceId,
                        device_os: devicePlatform,
                        event_name: data?.event_name,
                        event_type: data?.event_type,
                    },
                },
                fetchPolicy: "no-cache",
            });

            return response;
        } catch (error) {
            console.error("Error logging customer event:", error);
            throw error;
        }
    };

    return { createCustomerEventLog, eventLogData, loading, error };
};

export default useCustomerEventLogs;
