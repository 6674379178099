export const BackgroundMobSvg1: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="90"
      viewBox="0 0 66 90"
      fill="none"
    >
      <g opacity="0.1" clipPath="url(#clip0_645_28312)">
        <path
          d="M68.3224 23.0577C68.3224 23.0577 60.337 29.2619 62.2921 38.3478C64.2472 47.4367 69.2221 53.1533 71.9057 55.9381C74.5893 58.723 75.0998 60.1107 75.0998 60.1107C75.0998 60.1107 78.3469 55.5287 79.6109 49.3433C80.8748 43.1579 79.1034 29.4557 68.3193 23.0577H68.3224Z"
          fill="url(#paint0_linear_645_28312)"
        />
        <path
          d="M68.3224 23.0577C68.3224 23.0577 60.337 29.2619 62.2921 38.3478C64.2472 47.4367 69.2221 53.1533 71.9057 55.9381C74.5893 58.723 75.0998 60.1107 75.0998 60.1107C75.0998 60.1107 78.3469 55.5287 79.6109 49.3433C80.8748 43.1579 79.1034 29.4557 68.3193 23.0577H68.3224Z"
          stroke="url(#paint1_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M73.6554 57.8541C73.6554 57.8541 67.2328 47.965 65.1875 41.5952C63.1421 35.2254 63.5094 28.6086 67.2173 24.1392"
          stroke="url(#paint2_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M52.0029 53.0376C52.0029 53.0376 46.0598 57.6539 47.5168 64.4175C48.9707 71.1812 52.6754 75.435 54.671 77.5072C56.6696 79.5794 57.0495 80.6108 57.0495 80.6108C57.0495 80.6108 59.4653 77.2009 60.4055 72.597C61.3457 67.9931 60.0288 57.7946 52.0029 53.0344V53.0376Z"
          fill="url(#paint3_linear_645_28312)"
        />
        <path
          d="M52.0029 53.0376C52.0029 53.0376 46.0598 57.6539 47.5168 64.4175C48.9707 71.1812 52.6754 75.435 54.671 77.5072C56.6696 79.5794 57.0495 80.6108 57.0495 80.6108C57.0495 80.6108 59.4653 77.2009 60.4055 72.597C61.3457 67.9931 60.0288 57.7946 52.0029 53.0344V53.0376Z"
          stroke="url(#paint4_linear_645_28312)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M52.0032 53.0376C52.0032 53.0376 49.0519 59.795 52.3207 67.4931C55.5896 75.1912 57.0497 80.614 57.0497 80.614"
          stroke="url(#paint5_linear_645_28312)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M57.3079 80.2389C57.3079 80.2389 57.7032 72.022 55.4275 65.1427C53.1517 58.2603 52.3547 53.2189 52.3547 53.2189"
          stroke="url(#paint6_linear_645_28312)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M55.9724 78.9324C55.9724 78.9324 51.1936 71.5719 49.6713 66.8305C48.1489 62.0891 48.4229 57.1632 51.1812 53.8408"
          stroke="url(#paint7_linear_645_28312)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M58.6746 77.726C58.6746 77.726 59.7393 68.2651 57.8932 62.1359C56.0471 56.0037 54.176 54.6378 54.176 54.6378"
          stroke="url(#paint8_linear_645_28312)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M68.1824 -5.4939C68.1824 -5.4939 74.2438 12.0809 62.5911 30.0057C50.9353 47.9305 46.5363 65.0833 46.2437 71.0656"
          stroke="url(#paint9_linear_645_28312)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M38.6287 26.3675C47.5107 11.9589 63.2231 14.7375 66.109 12.6434C68.9981 10.5525 70.2496 6.87372 69.602 4.26392L69.6923 4.31705C69.6923 4.31705 76.4916 18.4694 66.8219 28.2429C57.1523 38.0195 49.9577 33.7938 36.3748 43.1484C36.3748 43.1484 32.835 35.7597 38.6256 26.3675H38.6287Z"
          fill="url(#paint10_linear_645_28312)"
        />
        <path
          d="M69.9165 4.86084C69.9165 4.86084 72.7682 15.572 66.3363 21.0322C59.9013 26.4925 47.2088 30.9995 41.9724 35.1908C36.7328 39.3821 36.3779 43.1484 36.3779 43.1484"
          stroke="url(#paint11_linear_645_28312)"
          strokeMiterlimit="10"
        />
        <path
          d="M69.328 8.15515C69.328 8.15515 69.5802 15.3345 64.0604 18.4662C58.5376 21.598 45.3936 27.9053 40.8141 31.7153C36.2377 35.5221 35.5559 40.3636 35.5559 40.3636"
          stroke="url(#paint12_linear_645_28312)"
          strokeMiterlimit="10"
        />
        <path
          d="M66.0064 12.8309C66.0064 12.8309 62.3079 17.266 50.7485 21.3073C39.1892 25.3486 36.2161 31.4059 36.2161 31.4059"
          stroke="url(#paint13_linear_645_28312)"
          strokeMiterlimit="10"
        />
        <path
          d="M72.0457 15.8689C72.0457 15.8689 69.2033 24.3703 62.9209 28.5179C57.8277 31.8778 48.3448 35.8753 46.7073 37.7506"
          stroke="url(#paint14_linear_645_28312)"
          strokeMiterlimit="10"
        />
        <path
          d="M38.6287 26.3675C47.5107 11.9589 63.2231 14.7375 66.109 12.6434C68.9981 10.5525 70.2496 6.87372 69.602 4.26392L69.6923 4.31705C69.6923 4.31705 76.4916 18.4694 66.8219 28.2429C57.1523 38.0195 49.9577 33.7938 36.3748 43.1484C36.3748 43.1484 32.835 35.7597 38.6256 26.3675H38.6287Z"
          stroke="url(#paint15_linear_645_28312)"
          strokeMiterlimit="10"
        />
        <path
          d="M54.1264 44.8268C54.1264 44.8268 54.1327 54.9628 45.7799 58.976C37.4272 62.9891 29.8777 62.5453 26.0422 62.1202C22.2036 61.6952 20.7996 62.139 20.7996 62.139C20.7996 62.139 22.4184 56.7568 26.5123 51.9654C30.6093 47.1709 42.4831 40.1854 54.1233 44.8236L54.1264 44.8268Z"
          fill="url(#paint16_linear_645_28312)"
        />
        <path
          d="M54.1265 44.8268C54.1265 44.8268 54.1327 54.9628 45.7799 58.976C37.4272 62.9891 29.8777 62.5453 26.0422 62.1202C22.2036 61.6952 20.7996 62.139 20.7996 62.139C20.7996 62.139 22.4184 56.7568 26.5123 51.9654C30.6093 47.1709 42.4831 40.1854 54.1233 44.8236L54.1265 44.8268Z"
          stroke="url(#paint17_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M54.1265 44.8268C54.1265 44.8268 49.4068 53.5345 38.5729 56.3881C27.7389 59.2385 20.7996 62.1421 20.7996 62.1421"
          stroke="url(#paint18_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M20.9802 61.5607C20.9802 61.5607 29.3454 54.3752 38.5014 51.1403C47.6573 47.9054 53.6471 44.6017 53.6471 44.6017"
          stroke="url(#paint19_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M23.4617 61.9109C23.4617 61.9109 35.1829 60.9482 41.4498 58.6697C47.7167 56.3912 52.704 52.0467 53.9493 46.3677"
          stroke="url(#paint20_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M22.5181 58.0351C22.5181 58.0351 31.6491 49.1118 39.6563 46.0331C47.6634 42.9576 50.6459 43.8265 50.6459 43.8265"
          stroke="url(#paint21_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M50.3096 55.863C50.0823 55.5286 57.2956 63.508 49.7772 74.9568C41.8759 85.9336 30.8053 87.9839 26.039 87.9214C21.0392 87.9683 19.1744 88.2121 19.1619 88.2652C19.1464 88.234 22.3685 82.3924 26.3441 76.8571C30.9298 71.0343 39.183 62.9642 50.3064 55.863H50.3096Z"
          fill="url(#paint22_linear_645_28312)"
        />
        <path
          d="M50.3096 55.863C50.0823 55.5286 57.2956 63.508 49.7772 74.9568C41.8759 85.9336 30.8053 87.9839 26.039 87.9214C21.0392 87.9683 19.1744 88.2121 19.1619 88.2652C19.1464 88.234 22.3685 82.3924 26.3441 76.8571C30.9298 71.0343 39.183 62.9642 50.3064 55.863H50.3096Z"
          stroke="url(#paint23_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M50.3095 55.8631C50.2566 55.2755 52.6911 67.6244 40.8329 77.3229C29.1708 86.0899 19.0467 88.0715 19.1619 88.2622"
          stroke="url(#paint24_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M19.4885 87.6183C19.7438 87.6496 31.135 80.0296 39.1578 72.2095C47.7347 64.3176 49.7489 55.96 49.8143 56.135"
          stroke="url(#paint25_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M22.6737 87.959C22.627 88.5122 38.411 85.2023 44.7961 77.8604C51.4677 71.3437 53.6657 62.2422 51.1097 57.1133"
          stroke="url(#paint26_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M21.8081 83.7113C22.2782 83.7926 32.7697 73.6659 38.4326 66.9023C44.6123 60.1418 46.8757 57.979 47.0344 58.0821"
          stroke="url(#paint27_linear_645_28312)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M71.2952 60.6731C71.124 47.9492 59.9382 43.636 58.886 41.1699C57.8306 38.7008 58.4408 35.8472 59.8635 34.4188H59.7826C59.7826 34.4188 49.9542 40.8918 52.4167 50.9372C54.8762 60.9857 61.1368 61.0576 66.283 72.3532C66.283 72.3532 71.4073 68.9683 71.2952 60.6731Z"
          fill="url(#paint28_linear_645_28312)"
        />
        <path
          d="M59.4306 34.6814C59.4306 34.6814 53.4688 40.4699 55.5111 46.4833C57.5502 52.4968 63.9884 60.3262 65.7473 65.0583C67.5063 69.7903 66.2859 72.3532 66.2859 72.3532"
          stroke="url(#paint29_linear_645_28312)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M58.5409 37.0317C58.5409 37.0317 55.6144 41.5575 57.9618 45.7144C60.3123 49.8745 66.3457 59.0385 67.8276 63.2673C69.3094 67.4961 67.8805 70.8779 67.8805 70.8779"
          stroke="url(#paint30_linear_645_28312)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M58.8828 41.3293C58.8828 41.3293 59.5553 45.6207 65.4423 52.7093C71.3294 59.798 70.9122 64.852 70.9122 64.852"
          stroke="url(#paint31_linear_645_28312)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M53.8211 40.9418C53.8211 40.9418 52.3766 47.521 54.8267 52.6281C56.8129 56.7694 61.38 63.0235 61.71 64.8645"
          stroke="url(#paint32_linear_645_28312)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M71.2952 60.6731C71.124 47.9492 59.9382 43.636 58.886 41.1699C57.8306 38.7008 58.4408 35.8472 59.8635 34.4188H59.7826C59.7826 34.4188 49.9542 40.8918 52.4167 50.9372C54.8762 60.9857 61.1368 61.0576 66.283 72.3532C66.283 72.3532 71.4073 68.9682 71.2952 60.6731Z"
          stroke="url(#paint33_linear_645_28312)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M59.3473 14.3438C59.3971 14.4313 51.5985 15.1533 48.8994 21.0074C46.2594 26.9584 47.1902 32.353 47.8378 35.1191C48.4822 37.8789 48.2425 38.9072 48.2425 38.9072C48.2425 38.901 52.4235 37.6383 55.8979 34.5284C59.4158 31.4904 63.7805 22.3045 59.3473 14.3407V14.3438Z"
          fill="url(#paint34_linear_645_28312)"
        />
        <path
          d="M59.3473 14.3438C59.3971 14.4313 51.5985 15.1533 48.8994 21.0074C46.2594 26.9584 47.1902 32.353 47.8378 35.1191C48.4822 37.8789 48.2425 38.9072 48.2425 38.9072C48.2425 38.901 52.4235 37.6383 55.8979 34.5284C59.4158 31.4904 63.7805 22.3045 59.3473 14.3407V14.3438Z"
          stroke="url(#paint35_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M59.3472 14.3439C59.397 14.4283 53.0367 18.4008 51.4863 26.0614C49.9951 33.8158 48.2361 38.9042 48.2393 38.9104"
          stroke="url(#paint36_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M48.6904 38.7665C48.6904 38.7665 53.7805 32.5967 55.6484 25.9331C57.5288 19.2914 59.5586 14.675 59.5555 14.6656"
          stroke="url(#paint37_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M48.2114 36.9788C48.2021 36.96 48.0651 28.5274 49.4536 24.0673C50.8265 19.5822 53.9646 15.9941 58.1924 14.6095"
          stroke="url(#paint38_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M51.387 37.5789C51.387 37.5789 57.8033 30.8122 59.5373 24.8206C61.2839 18.8477 60.3655 16.7349 60.3717 16.7474"
          stroke="url(#paint39_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M35.3507 24.7549C35.3507 24.7549 29.171 24.658 27.3872 29.3932C25.6126 34.1471 26.5435 38.5634 27.107 40.7607C27.6798 42.9704 27.5553 43.8299 27.5553 43.8299C27.5553 43.8299 30.4443 42.5078 32.9411 40.0387C35.3974 37.5039 38.8313 30.9778 35.3539 24.7549H35.3507Z"
          fill="url(#paint40_linear_645_28312)"
        />
        <path
          d="M35.3507 24.7549C35.3507 24.7549 29.171 24.658 27.3872 29.3932C25.6126 34.1471 26.5435 38.5634 27.107 40.7607C27.6798 42.9704 27.5553 43.8299 27.5553 43.8299C27.5553 43.8299 30.4443 42.5078 32.9411 40.0387C35.3974 37.5039 38.8313 30.9778 35.3539 24.7549H35.3507Z"
          stroke="url(#paint41_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M35.3504 24.7549C35.3504 24.7549 30.3195 27.2084 29.4415 33.3719C28.5512 39.5136 27.558 43.833 27.5549 43.8299"
          stroke="url(#paint42_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M27.8668 43.6861C27.845 43.6486 31.2291 38.3727 32.5553 33.3063C33.8473 28.1836 35.5222 25.0205 35.516 25.0143"
          stroke="url(#paint43_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M27.4493 42.2546C27.44 42.239 26.9823 35.3598 27.882 31.8123C28.7568 28.2273 31.0544 25.4894 34.426 24.8424"
          stroke="url(#paint44_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M29.7436 42.5859C29.7094 42.5266 34.1581 36.9413 35.5217 32.6031C36.8511 28.2055 36.1693 26.6396 36.163 26.6271"
          stroke="url(#paint45_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M69.322 -5.90637C69.2224 -6.07202 68.1484 10.5995 52.6975 16.341C44.9581 19.1915 38.1526 22.0388 33.2088 25.055C28.2246 28.0055 25.1083 31.131 23.835 32.9844"
          stroke="url(#paint46_linear_645_28312)"
          strokeWidth="2.29"
          strokeMiterlimit="10"
        />
        <path
          d="M38.0097 4.25776C50.6929 0.441512 59.3538 7.23638 61.9907 6.85507C64.5933 6.42062 66.6231 4.14525 67.0995 2.0199C67.1119 2.04178 67.1244 2.06365 67.1368 2.08553C67.0932 2.01052 66.6761 14.7939 56.2499 16.9599C45.6744 18.882 42.5082 13.2717 28.8195 12.2278C28.7977 12.1903 29.6974 6.66754 38.0128 4.25776H38.0097Z"
          fill="url(#paint47_linear_645_28312)"
        />
        <path
          d="M67.0871 2.54504C67.0871 2.54504 65.2441 11.3121 58.8776 12.356C52.5889 13.5344 41.8982 10.5995 36.3287 10.4682C30.7778 10.3682 28.8134 12.2154 28.8196 12.2279"
          stroke="url(#paint48_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M65.605 4.76721C65.605 4.76721 63.2577 9.97119 58.4322 9.92744C53.7313 10.0868 42.0692 8.19278 37.0974 8.08963C32.1848 8.08963 29.4918 10.3369 29.4981 10.3494"
          stroke="url(#paint49_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M61.8539 6.94565C61.8289 6.90502 57.9748 8.87097 48.5044 6.55809C38.9842 4.16394 34.0062 5.81109 34.0405 5.86735"
          stroke="url(#paint50_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M64.4936 10.9589C64.5247 11.0089 59.5374 15.7346 53.5475 15.6034C48.7688 15.6221 40.4534 13.6906 38.4609 13.9594"
          stroke="url(#paint51_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M38.0097 4.25776C50.6929 0.441512 59.3538 7.23638 61.9907 6.85507C64.5933 6.42062 66.6231 4.14525 67.0995 2.0199C67.1119 2.04178 67.1244 2.06365 67.1368 2.08553C67.0932 2.01052 66.6761 14.7939 56.2499 16.9599C45.6744 18.882 42.5082 13.2717 28.8195 12.2278C28.7977 12.1903 29.6974 6.66754 38.0128 4.25776H38.0097Z"
          stroke="url(#paint52_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M40.4411 21.1138C40.4411 21.1138 35.955 26.7366 28.4584 25.3863C20.9555 24.0267 15.9557 20.5043 13.4652 18.5415C10.9839 16.5943 9.84138 16.2692 9.84449 16.2692C9.83827 16.2567 13.0542 13.5031 18.0042 12.6342C22.9262 11.7216 34.4762 13.4781 40.4442 21.1169L40.4411 21.1138Z"
          fill="url(#paint53_linear_645_28312)"
        />
        <path
          d="M40.4411 21.1138C40.4411 21.1138 35.955 26.7366 28.4584 25.3863C20.9555 24.0267 15.9557 20.5043 13.4652 18.5415C10.9839 16.5943 9.84138 16.2692 9.84449 16.2692C9.83827 16.2567 13.0542 13.5031 18.0042 12.6342C22.9262 11.7216 34.4762 13.4781 40.4442 21.1169L40.4411 21.1138Z"
          stroke="url(#paint54_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M40.4411 21.1138C40.4411 21.1138 33.3056 23.8455 24.5419 20.6481C15.7502 17.407 9.83826 16.2568 9.84449 16.2693"
          stroke="url(#paint55_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M10.1965 15.9723C10.1498 15.891 18.8792 15.1815 26.7619 17.6101C34.5947 19.9542 40.2171 20.7825 40.2109 20.7762"
          stroke="url(#paint56_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M11.7681 17.2788C11.7681 17.2788 20.2422 21.7983 25.5751 23.2735C30.8987 24.73 36.2597 24.4925 39.6344 21.8889"
          stroke="url(#paint57_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M12.6714 14.4408C12.6092 14.3376 22.7862 13.2468 29.8252 15.2971C36.8019 17.2412 38.4706 19.0196 38.4737 19.0259"
          stroke="url(#paint58_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M32.9438 25.6051C32.9874 25.4082 34.3385 32.825 24.7467 37.0164C15.2048 40.9576 7.76731 39.1386 4.69146 37.3352C1.48486 35.6286 0.264482 35.2379 0.239576 35.2754C0.220897 35.2192 3.71703 31.156 8.25609 28.715C13.1999 26.1333 22.085 24.6174 32.9438 25.6051Z"
          fill="url(#paint59_linear_645_28312)"
        />
        <path
          d="M32.9438 25.6051C32.9874 25.4082 34.3385 32.825 24.7467 37.0164C15.2048 40.9576 7.76731 39.1386 4.69146 37.3352C1.48486 35.6286 0.264482 35.2379 0.239576 35.2754C0.220897 35.2192 3.71703 31.156 8.25609 28.715C13.1999 26.1333 22.085 24.6174 32.9438 25.6051Z"
          stroke="url(#paint60_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M32.9442 25.6051C33.2119 25.3332 29.3329 33.0907 17.8015 34.891C6.82748 36.2787 0.277288 35.1786 0.236816 35.2723"
          stroke="url(#paint61_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M0.607422 34.8192C0.682139 34.7973 9.98752 32.2813 18.5519 30.7154C27.4028 29.1307 32.5396 25.4583 32.4804 25.5395"
          stroke="url(#paint62_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M2.53452 36.2006C2.33216 36.6101 13.219 39.1792 20.2798 36.8945C27.1382 35.1286 32.524 30.7653 32.9568 26.6803"
          stroke="url(#paint63_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M3.14771 32.328C3.29091 32.3312 13.3497 28.4774 20.1116 26.9615C27.082 25.4269 29.6722 25.3331 29.7127 25.4206"
          stroke="url(#paint64_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M45.391 37.6008C50.7177 30.1464 44.9863 23.0015 45.3381 21.1481C45.7024 19.3165 47.3773 17.9851 48.9619 17.7631C48.9432 17.7506 48.9245 17.7413 48.909 17.7288C48.9588 17.8131 39.3141 17.113 36.5589 23.7548C33.9064 30.5684 38.184 33.3282 37.0694 42.4078C37.0725 42.4141 41.9323 42.4797 45.391 37.5977V37.6008Z"
          fill="url(#paint65_linear_645_28312)"
        />
        <path
          d="M48.5542 17.735C48.576 17.7725 41.9324 18.4351 40.6653 22.642C39.4138 26.874 40.4817 34.1846 39.7127 37.7664C38.9375 41.3389 37.0696 42.414 37.0696 42.414"
          stroke="url(#paint66_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M46.9196 18.6852C46.9196 18.6852 42.9191 19.9541 42.6981 23.2891C42.4739 26.6177 42.6576 34.4659 41.9011 37.6164C41.129 40.7388 38.7661 42.1922 38.7661 42.1953"
          stroke="url(#paint67_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M45.2636 21.2386C45.2636 21.2386 43.8221 23.9016 44.7904 30.4839C45.7617 37.0694 43.3521 39.8886 43.3521 39.8886"
          stroke="url(#paint68_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M41.9545 18.8322C41.9545 18.8322 38.0225 21.8108 37.4902 25.7802C37.0512 28.9838 37.5462 34.641 37.0107 35.8943"
          stroke="url(#paint69_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M45.391 37.6008C50.7177 30.1464 44.9863 23.0015 45.3381 21.1481C45.7024 19.3165 47.3773 17.9851 48.9619 17.7631C48.9432 17.7506 48.9245 17.7413 48.909 17.7288C48.9588 17.8131 39.3141 17.113 36.5589 23.7548C33.9064 30.5684 38.184 33.3282 37.0694 42.4078C37.0725 42.4141 41.9323 42.4797 45.391 37.5977V37.6008Z"
          stroke="url(#paint70_linear_645_28312)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M48.681 89.8875C49.1228 89.8875 49.481 89.5279 49.481 89.0843C49.481 88.6406 49.1228 88.281 48.681 88.281C48.2391 88.281 47.8809 88.6406 47.8809 89.0843C47.8809 89.5279 48.2391 89.8875 48.681 89.8875Z"
          fill="url(#paint71_radial_645_28312)"
        />
        <path
          d="M24.1302 71.9376C24.5721 71.9376 24.9303 71.5779 24.9303 71.1343C24.9303 70.6907 24.5721 70.3311 24.1302 70.3311C23.6883 70.3311 23.3301 70.6907 23.3301 71.1343C23.3301 71.5779 23.6883 71.9376 24.1302 71.9376Z"
          fill="url(#paint72_radial_645_28312)"
        />
        <path
          d="M15.3265 56.132C16.2103 56.132 16.9267 55.4127 16.9267 54.5255C16.9267 53.6382 16.2103 52.9189 15.3265 52.9189C14.4427 52.9189 13.7263 53.6382 13.7263 54.5255C13.7263 55.4127 14.4427 56.132 15.3265 56.132Z"
          fill="url(#paint73_radial_645_28312)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_645_28312"
          x1="60.7645"
          y1="30.9523"
          x2="82.9707"
          y2="51.687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_645_28312"
          x1="60.6573"
          y1="30.9072"
          x2="83.0436"
          y2="51.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_645_28312"
          x1="60.222"
          y1="31.4785"
          x2="80.5772"
          y2="50.4847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_645_28312"
          x1="46.3787"
          y1="58.9175"
          x2="62.9063"
          y2="74.3498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_645_28312"
          x1="46.2961"
          y1="58.8826"
          x2="62.9582"
          y2="74.4405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_645_28312"
          x1="46.2523"
          y1="59.0897"
          x2="62.7913"
          y2="74.5327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_645_28312"
          x1="46.7561"
          y1="59.1485"
          x2="62.9646"
          y2="74.2829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_645_28312"
          x1="45.9751"
          y1="59.3087"
          x2="61.1235"
          y2="73.4532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_645_28312"
          x1="49.488"
          y1="59.6503"
          x2="63.4647"
          y2="72.7007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_645_28312"
          x1="73.825"
          y1="30.5827"
          x2="41.0513"
          y2="34.8631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_645_28312"
          x1="72.9956"
          y1="21.0585"
          x2="33.7017"
          y2="26.1905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_645_28312"
          x1="72.3648"
          y1="21.4694"
          x2="34.0465"
          y2="26.4739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_645_28312"
          x1="71.266"
          y1="21.7842"
          x2="33.6238"
          y2="26.7004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_645_28312"
          x1="67.0475"
          y1="19.9911"
          x2="35.1382"
          y2="24.1586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_645_28312"
          x1="73.3787"
          y1="24.9799"
          x2="45.4149"
          y2="28.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_645_28312"
          x1="73.1418"
          y1="20.9762"
          x2="33.5392"
          y2="26.1485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_645_28312"
          x1="52.5364"
          y1="55.6631"
          x2="22.5848"
          y2="50.8952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_645_28312"
          x1="52.6407"
          y1="55.7209"
          x2="22.4431"
          y2="50.9139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_645_28312"
          x1="52.4579"
          y1="55.9385"
          x2="22.4817"
          y2="51.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_645_28312"
          x1="51.9813"
          y1="55.4473"
          x2="22.6047"
          y2="50.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_645_28312"
          x1="52.4578"
          y1="56.4164"
          x2="24.9999"
          y2="52.0455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_645_28312"
          x1="49.2013"
          y1="52.9471"
          x2="23.8742"
          y2="48.9153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_645_28312"
          x1="54.3946"
          y1="68.7385"
          x2="16.7403"
          y2="76.5262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_645_28312"
          x1="54.5013"
          y1="68.7163"
          x2="16.6335"
          y2="76.5481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_645_28312"
          x1="52.9814"
          y1="68.1936"
          x2="16.4674"
          y2="75.7454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_645_28312"
          x1="52.4131"
          y1="68.1525"
          x2="16.948"
          y2="75.4873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_645_28312"
          x1="54.0084"
          y1="69.1"
          x2="20.1137"
          y2="76.1101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_645_28312"
          x1="49.1278"
          y1="67.8094"
          x2="19.7398"
          y2="73.8874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_645_28312"
          x1="51.2084"
          y1="43.9177"
          x2="74.5878"
          y2="62.4081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_645_28312"
          x1="51.4591"
          y1="44.4249"
          x2="74.2564"
          y2="62.455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_645_28312"
          x1="52.0459"
          y1="45.0558"
          x2="74.4397"
          y2="62.7668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_645_28312"
          x1="55.4511"
          y1="45.5397"
          x2="74.4358"
          y2="60.5545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_645_28312"
          x1="49.453"
          y1="46.2935"
          x2="66.0901"
          y2="59.4515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_645_28312"
          x1="51.1468"
          y1="43.8046"
          x2="74.7096"
          y2="62.4401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_645_28312"
          x1="51.0142"
          y1="16.679"
          x2="56.9219"
          y2="36.2928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_645_28312"
          x1="50.9462"
          y1="16.4515"
          x2="57.1044"
          y2="36.897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_645_28312"
          x1="50.8775"
          y1="16.8311"
          x2="56.7708"
          y2="36.3971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_645_28312"
          x1="51.2611"
          y1="17.1037"
          x2="57.0453"
          y2="36.3076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_645_28312"
          x1="50.3809"
          y1="16.7132"
          x2="55.8128"
          y2="34.7473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_645_28312"
          x1="53.396"
          y1="18.6016"
          x2="58.5378"
          y2="35.6724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_645_28312"
          x1="29.1817"
          y1="26.1624"
          x2="33.9243"
          y2="41.9079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_645_28312"
          x1="29.1128"
          y1="25.9319"
          x2="34.1302"
          y2="42.5899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_645_28312"
          x1="29.1566"
          y1="26.5582"
          x2="33.8604"
          y2="42.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_645_28312"
          x1="29.4411"
          y1="26.7794"
          x2="33.9953"
          y2="41.8996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_645_28312"
          x1="28.6687"
          y1="26.2843"
          x2="32.9931"
          y2="40.6416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_645_28312"
          x1="31.0255"
          y1="28.1131"
          x2="34.9318"
          y2="41.0822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_645_28312"
          x1="60.4295"
          y1="19.1975"
          x2="33.8079"
          y2="8.11619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_645_28312"
          x1="63.5181"
          y1="13.6505"
          x2="33.3277"
          y2="1.08364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_645_28312"
          x1="62.9696"
          y1="13.688"
          x2="33.1242"
          y2="1.26476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_645_28312"
          x1="62.1513"
          y1="13.6835"
          x2="32.9214"
          y2="1.51639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_645_28312"
          x1="60.1192"
          y1="11.4893"
          x2="35.6775"
          y2="1.31539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_645_28312"
          x1="62.2448"
          y1="16.9848"
          x2="40.7882"
          y2="8.05346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_645_28312"
          x1="63.7941"
          y1="13.6037"
          x2="33.1636"
          y2="0.853657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_645_28312"
          x1="35.656"
          y1="27.0005"
          x2="14.863"
          y2="10.0968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_645_28312"
          x1="35.7152"
          y1="27.0611"
          x2="14.673"
          y2="9.95487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_645_28312"
          x1="35.6999"
          y1="27.327"
          x2="14.5368"
          y2="10.1225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_645_28312"
          x1="35.5754"
          y1="26.8576"
          x2="14.7824"
          y2="9.954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_645_28312"
          x1="35.3273"
          y1="27.5626"
          x2="15.9279"
          y2="11.7919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_645_28312"
          x1="34.5133"
          y1="24.0267"
          x2="16.4857"
          y2="9.37121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_645_28312"
          x1="30.5257"
          y1="35.5044"
          x2="3.17069"
          y2="26.4299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_645_28312"
          x1="30.7502"
          y1="35.5795"
          x2="2.94586"
          y2="26.3559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_645_28312"
          x1="30.143"
          y1="34.8493"
          x2="3.10421"
          y2="25.8796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_645_28312"
          x1="29.7384"
          y1="34.5658"
          x2="3.44456"
          y2="25.8432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_645_28312"
          x1="30.3196"
          y1="35.7911"
          x2="5.18746"
          y2="27.454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_645_28312"
          x1="27.5188"
          y1="32.5717"
          x2="5.42544"
          y2="25.2426"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_645_28312"
          x1="39.104"
          y1="19.962"
          x2="46.6675"
          y2="39.1857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_645_28312"
          x1="39.1876"
          y1="20.6811"
          x2="46.3908"
          y2="38.9891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_645_28312"
          x1="39.3524"
          y1="21.395"
          x2="46.3704"
          y2="39.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_645_28312"
          x1="41.3564"
          y1="22.7248"
          x2="47.5554"
          y2="38.4805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_645_28312"
          x1="36.9739"
          y1="20.7399"
          x2="42.1984"
          y2="34.0189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_645_28312"
          x1="39.1713"
          y1="19.6803"
          x2="46.9087"
          y2="39.3459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <radialGradient
          id="paint71_radial_645_28312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.2887 88.7436) scale(1.17991 1.18457)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint72_radial_645_28312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.7379 70.7936) scale(1.17991 1.18457)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint73_radial_645_28312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.542 53.8441) scale(2.3567 2.36601)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <clipPath id="clip0_645_28312">
          <rect width="66" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
