import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface CustomContainerProps {
  children: React.ReactNode;
}

const CustomContainer: React.FC<CustomContainerProps> = ({ children }) => {

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "80%" },
        margin: "0 auto", // Centering the container
      }}
    >
      {children}
    </Box>
  );
};

export default CustomContainer;
