import React, { useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VerticalarStepper } from "../VerticalarStepper/VerticalarStepper";
import StackedImage from "../StackedImage/StackedImage";
import CustomContainer from "../CustomContainer/CustomContainer";
import EnterYourDetailsModal from "../EnterYourDetailsModal/EnterYourDetailsModal";
import { BackgroundMobSvg1 } from "./BackgroundMobSvg1";
import { BackgroundMobSvg2 } from "./BackgroundMobSvg2";
import StackedImageMob from "../StackedImageMob/StackedImageMob";
import { VerticalarStepperMob } from "../VerticalarStepperMob/VerticalarStepperMob";
import SingleStackImgMob from "../SingleStackImgMob/SingleStackImgMob";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";

interface HowItWorkForMobProps {
  activePanelNo: number; // Input number from another component
}

const HowItWorkForMob: React.FC<HowItWorkForMobProps> = ({ activePanelNo }) => {
  const [isFormModalOpen, setIsFormModalOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();



  const handleStep = (num: number) => {
    setStep(num);
  };

  const handleOpenModal = () => {
    setIsFormModalOpen(true); // Explicitly set to true
  };

  const handleCloseModal = () => {
    setIsFormModalOpen(false); // Explicitly set to false
  };

  useEffect(() => {
    handleStep(activePanelNo);
  }, [activePanelNo]);


  const handleClick = async () => {
    try {
      // Open the modal first
      handleOpenModal();

      // Log the first event
      await createCustomerEventLog({
        event_name: `click_on_request_call_back_HIW_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event",
      });

      // Log the second event after the first one is completed
      await createCustomerEventLog({
        event_name: `enter_your_details_popup_mounted_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event",
      });
    } catch (error) {
      console.error("Error in event logging:", error);
    }
  };


  return (
    <Box sx={{ display: { xs: "block", md: "none" }, }}>
      <CustomContainer>
        <Box
          sx={{
            display: { xs: "block", md: "none", },
            position: "relative",
            background:
              "rgba(255, 249, 158, 0.10) 16.59%",
            height: "100%",
            paddingTop: "30px",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              margin: "0% 2% 0% 2%",
              display: "block",
              padding: "10px 0 0 20px",
              marginBottom: "35px"
            }}
          >
            How it works?
          </Typography>

          {/* Top-right corner flower */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <BackgroundMobSvg1 />
          </Box>

          {/* Bottom-left corner flower */}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <BackgroundMobSvg2 />
          </Box>

          {/* Content */}
          <Grid
            container
            spacing={4}
            direction={{ xs: "column-reverse", md: "row" }}
            alignItems="center"
            justifyContent="center"
            sx={{ height: "auto" }}
          >
            <Grid size={{ xs: 12 }}>
              {/* Steps Instructions */}

              <Box>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ height: "auto", padding: "0% 2%" }}
                >
                  <Grid size={{ xs: 12 }}>
                    <Box sx={{ textAlign: "center" }}>
                      <SingleStackImgMob />
                      <VerticalarStepperMob
                        step={activePanelNo}
                        handleStep={(data: number) => handleStep(data)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* request call back button */}
              <Box
                sx={{
                  width: "80%",
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      display: "flex",
                      width: "312px",
                      height: "44px",
                      padding: "12px 24px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      flexShrink: 0,
                      borderRadius: "12px",
                      border: "1px solid #B37839",
                      background: "#631041",
                      margin: "4% 10%",
                      marginTop: {
                        sm: "30px",
                        xs: "30px"
                      },
                      marginBottom: "45px",
                    }}
                    onClick={() => {
                      // handleOpenModal();
                      handleClick();
                    }}
                  >
                    Request call back
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </CustomContainer>

      {/* Modal */}
      <EnterYourDetailsModal
        open={isFormModalOpen} // Controlled by state
        onClos={handleCloseModal} // Properly close the modal
      />
    </Box>
  );
};

export default HowItWorkForMob;
