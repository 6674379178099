export const PAPData = [
  {
    heading: "Information we collect and how we use it",
    paragraph:
      "We use commercially reasonable efforts to ensure that the collection of personal information is limited to that which is necessary to fulfil the purposes identified below. If we use your information in a manner different than the purpose for which it is collected, then we will ask you for your consent prior to such use. By logging in/accessing the Website to take advantage of the full range of services offered on MMW, we ask for and record personal information such as your name, mobile number and email address. We use your mobile number / email address to send you updates, news, and newsletters (if you willingly subscribe to the newsletter during signup, or anytime after sign up) and contact you for rendering mutually agreed services (such as vendors/service providers for the purpose of discussing/rendering services to you). However, MMW assures that this information will not be made public or sold to any third party expect as stated herein. MMW is not liable for misuse of this information by vendors/service providers or persons related to these businesses.",
    paragraph2: null,
    paragraph2List: [
      {
        Feedback:
          "If you contact us to provide feedback, register a complaint, or ask a question, we will record any personal information and other content that you provide in your communication so that we can effectively respond to your communication.",
        Activity:
          "We record information relating to your use of MMW, such as the searches you undertake, the pages you view, your browser type, IP address, location, requested URL, referring URL, and timestamp information. We use this type of information to administer MMW and provide the highest possible level of service to you. We also use this information in the aggregate to perform statistical analyses of user behaviour and characteristics in order to measure interest in and use of the various areas of MMW. You cannot be identified from this aggregate information.",
        Cookies: `We send cookies to your computer in order to uniquely identify your browser and improve the quality of our service. The term "cookies" refers to small pieces of information that a website sends to your computer's hard drive while you are viewing the site. We may use both session cookies (which expire once you close your browser) and persistent cookies (which stay on your computer until you delete them). Persistent cookies can be removed by following your browser help file directions. If you choose to disable cookies, some areas of MMW may not work properly or at all. MMW uses third party tools, who may collect anonymous information about your visits to MMW using cookies, and interaction with MMW products and services. Such third parties may also use information about your visits to MMW products and services and other web sites to target advertisements for MMW products and services. These third parties may have access to the name, phone number, address, email address, or any personally identifying information about MMW users. Please refer to the help file of your browser to understand the process of deactivating Cookies on your browser. Enforcement. We may use the information we collect in connection with your use of MMW(including your personal information) in order to investigate, enforce, and apply our Terms of Service and Privacy Policy.`,
      },
    ],
  },
  {
    heading: "Transfer of information",
    paragraph:
      "MMW may contain links to third party websites to which we have no affiliation. Except as set forth herein, we do not share your personal information with those third parties, and are not responsible for their privacy practices. We suggest you read the privacy policies on all such third party websites.",
  },
  {
    heading: "Links",
    paragraph:
      "MMW is a personalised wedding planning platform where MMW Users have access to a curated selection of vendors related to wedding planning including but not limited to make-up artists, photographers, caterers, decorators, wedding event planners, venues, and jewellery brands. Upon availing Our services a dedicated Relationship Manager (RM) will be appointed to You.",
  },
  {
    heading: "Controlling your personal data",
    paragraph:
      "To the extent you include personal information in your submissions (such as a photo of yourself), third parties may be able to identify you, associate you with your user account, and contact you. Third parties can also associate you with your user account using the member lookup feature on MMW, but only if the third party already knows your full name and you include information in your submission that allows the third party to distinguish you from other users who might share the same full name. Please do not include information in your submissions that you expect to keep private. In addition, you can reduce the risk of being personally identified by choosing a fictitious account name, though doing so could detract from the credibility of your submissions.",
  },
  {
    heading: "Security",
    paragraph:
      "Your account is validated via OTP which is sent to the mobile number furnished at the time of registration / account creation. We use industry standard measures to protect the personal information that is stored in our database. We limit the access to your personal information to those employees and contractors who need access to perform their job function, such as our customer service personnel / service providers. If you have any questions about the security on MMW please contact us. Although we take appropriate measures to safeguard against unauthorized disclosures of information, we cannot assure you that your personal information will never be disclosed in a manner that is inconsistent with this Privacy Policy. You hereby acknowledge that MMW is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.",
  },
  {
    heading: "E-mails and opt-out",
    paragraph: `You may opt-out of receiving any messages or newsletters from us by writing an email seeking to opt-out <a href="mailto:care@makemywedding.com">care@makemywedding.com</a> . Unregistered users who receive e-mails from us may also opt-out of receiving further e-mails by following aforementioned process. Despite your indicated e-mail preferences, we may send you notices of any updates to our Terms of Use or Privacy Policy.`,
  },
  {
    heading: "Terms and modifications to this Privacy Policy",
    paragraph: `We may modify this Privacy Policy at any time, and we will post any new versions on this page. If we make any material changes in the way we use your personal information, we will notify you by sending an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our website. Any such changes will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our website. These changes will be effective immediately for new users of MMW Please note that at all times you are responsible for updating your personal information to provide us with your most current e-mail address. In the event that the last e-mail you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. In any event, changes to this Privacy Policy may affect our use of personal information that you provided us prior to our notification to you of the changes. If you do not wish to permit changes in our use of your personal information, you must notify us prior to the effective date of the changes that you wish to deactivate your account with us. Continued use of MMW following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.`,
  },
  {
    heading: "Dispute resolution",
    paragraph: `If you believe that MMW not adhered to this Privacy Policy you may write to MMW at the following address:  <a href="mailto:care@makemywedding.com">care@makemywedding.com</a>  In your email, please describe in as much detail as possible ways in which you believe the Privacy Policy has not been complied with. We will investigate your complaint promptly.`,
  },
  {
    heading: "Contacting the website and access to your personal information`",
    paragraph:
      "Upon written request, subject to certain exceptions, MMW will inform you of the existence, use, and disclosure of your personal information and will give you access to that information. Access requests should be sent to our Privacy Officer, using the contact information above. When requesting access to your personal information, we may request specific information from you to enable us to confirm your identity and right to access, as well as to search for and provide the personal information that we hold about you. We may charge you a fee to access your personal information to cover our costs; however, we will advise you of any fee in advance, which will be no more than any maximum fee prescribed by law. If you have any questions about this Privacy Policy, you can contact us using the contact information listed above.",
  },
  {
    heading: "Deleting your data",
    paragraph: `You have the right to share and access your personal information and right to withdraw consent for sharing your personal information at any point of time and right to erase your personal information subject to applicable laws. You can withdraw your consent provided by contacting us. To request to review, update, or delete your personal information, please submit a request on this  <a href="mailto:care@makemywedding.com">care@makemywedding.com</a> .`,
  }

  // Add more content as needed
];
