export const FacebookSvg: React.FC = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    style={{ display: "flex", alignItems: "center" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66699 13.1998C8.66699 10.6961 10.6966 8.6665 13.2003 8.6665H26.8003C29.304 8.6665 31.3337 10.6961 31.3337 13.1998V26.7998C31.3337 29.3035 29.304 31.3332 26.8003 31.3332H13.2003C10.6966 31.3332 8.66699 29.3035 8.66699 26.7998V13.1998ZM13.2003 10.9332C11.9485 10.9332 10.9337 11.948 10.9337 13.1998V26.7998C10.9337 28.0517 11.9485 29.0665 13.2003 29.0665H20.0003V21.1332H18.867C18.2411 21.1332 17.7337 20.6258 17.7337 19.9998C17.7337 19.3739 18.2411 18.8665 18.867 18.8665H20.0003V17.1665C20.0003 14.9758 21.7763 13.1998 23.967 13.1998H24.647C25.2729 13.1998 25.7803 13.7073 25.7803 14.3332C25.7803 14.9591 25.2729 15.4665 24.647 15.4665H23.967C23.0281 15.4665 22.267 16.2276 22.267 17.1665V18.8665H24.647C25.2729 18.8665 25.7803 19.3739 25.7803 19.9998C25.7803 20.6258 25.2729 21.1332 24.647 21.1332H22.267V29.0665H26.8003C28.0522 29.0665 29.067 28.0517 29.067 26.7998V13.1998C29.067 11.948 28.0522 10.9332 26.8003 10.9332H13.2003Z"
      fill="black"
    />
  </svg>
  );