import { Box, Typography } from "@mui/material";
import "./style.css";
import React from "react";
import CustomContainer from "../CustomContainer/CustomContainer";
import CustomCarousel from "../CustomCarousel/CustomCarousel";
import CustomCarouselMobile from "../CustomCarouselMobile/CustomCarouselMobile";
const PartnersSection: React.FC = () => {
  return (
    <CustomContainer>
      <Box sx={{ width: { xs: "85%", md: "100%" }, margin: { xs: "10% auto", md: "4% auto" } }}>
        <Box>
          <Typography
            variant="h2"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "34px" },
              fontStyle: "normal",
              fontWeight: { xs: "500", md: "600" },
              lineHeight: "160%" /* 64px */,
              padding: "0% 0%",
            }}
          >
            Partnered with best brands in the industry
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "16px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: { xs: "normal", md: "50px" } /* 64px */,
            }}
          >
            We work across all categories, from mandap booking to photography,
            catering, decor, and more, ensuring every aspect is covered
          </Typography>
        </Box>
        {/* <Box sx={{ marginTop: "1%", display:{xs:"none", sm:"block"} }}>
          <CustomCarousel />
        </Box>
        <Box sx={{ margin: "10% auto", display:{xs:"grid", sm:"none"}, gap:"1%", height:"240px" }}>
        <CustomCarouselMobile />
        </Box> */}
      </Box>
    </CustomContainer>
  );
};

export default PartnersSection;
