import React, { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import howstepimg1 from "../../../src/assets/images/howstepimg1.png";
import howstepimg2 from "../../../src/assets/images/howstepimg2.png";
import howstepimg3 from "../../../src/assets/images/howstepimg3.png";
import howstepimg4 from "../../../src/assets/images/howstepimg4.png";
import howstepimg5 from "../../../src/assets/images/howstepimg5.png";
import { FrameSvg2 } from "./FrameSvg2";
import { FrameSvg4 } from "./FrameSvg4";
import howItWorkMob from "../../assets/images/howItWorkMob.png";

const SingleStackImgMob: React.FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        margin: "auto",
        width:"250px",
        height:"210px"
      }}
    >
      <Box
        className={`color-display desk-stacked`}
        sx={{ position: "relative", width: "100%", margin: "auto" }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "15% auto",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
          }}
        >
          {" "}
          <Box
            sx={{
              borderRadius: "24px",
              border: "4px solid #FFF99E",
              background: "linear-gradient(180deg, #FFFDF8 0%, #F5E5B8 100%)",
              width: "177.494px",
              height: "172.134px",
              zIndex: 2,
            }}
          >
            <Card sx={{}}>
              <img
                src={howItWorkMob}
                alt={`Stacked img`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  position: "absolute",
                  left: "0%",
                  top: "-4%",
                }} // Ensure image sizing is consistent
              />
            </Card>
          </Box>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", top: "-15%", left: "5%" }}>
        <FrameSvg2 />
      </Box>
      <Box sx={{ position: "absolute", bottom: "0%", right: "0%" }}>
        <FrameSvg4 />
      </Box>
    </Box>
  );
};

export default SingleStackImgMob;
