export const FrameSvg3 : React.FC = ()=>{
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="163" height="154" viewBox="0 0 163 154" fill="none">
      <g opacity="0.3" clipPath="url(#clip0_727_4276)">
        <path d="M123.954 122.037C120.089 108.364 125.902 94.8852 130.717 82.2652C135.225 70.4875 139.058 58.0295 141.457 45.6363C143.663 34.2151 143.679 21.6437 138.414 11.0487C134.388 2.91611 126.305 -1.65238 117.352 0.550858C107.868 2.88371 104.309 14.0781 105.082 22.7291C105.71 29.7924 113.133 39.3668 121.152 37.3417C128.269 35.5435 131.441 26.8763 130.298 20.1532C129.187 13.6569 118.93 10.1414 115.951 17.3344C113.745 22.6967 116.643 26.3741 121.796 27.3948C124.083 27.8484 124.727 24.2843 122.456 23.8307C121.072 23.5553 119.719 23.5553 118.962 21.9839C116.74 17.3992 123.213 16.1193 125.725 17.9176C128.479 19.894 127.915 24.6569 127.303 27.4596C126.45 31.4286 123.825 34.9117 119.574 35.3815C114.341 35.9485 109.72 30.3108 108.029 26.0339C104.567 17.2696 109.188 5.58916 118.06 2.4949C128.575 -1.18256 136.014 8.95881 138.864 17.545C144.999 36.0781 136.932 57.2033 131.57 74.9102C126.933 90.2033 118.189 105.918 123.551 122.183C123.632 122.442 124.018 122.296 123.938 122.037H123.954Z" fill="url(#paint0_linear_727_4276)"/>
        <path d="M123.279 103.876C124.326 95.4841 131.185 88.1939 135.968 81.6004C140.219 75.7359 143.488 69.5798 144.679 62.3545C146.708 50.026 135.098 44.2101 124.986 49.8316C119.866 52.6667 117.016 58.8552 117.821 64.5901C118.497 69.353 123.972 72.3987 128.416 71.054C132.699 69.758 135.436 65.7241 132.458 61.7065C130.316 58.839 125.437 58.191 123.956 61.9009C123.118 63.9907 125.984 65.3353 127.079 63.3913C127.804 62.1115 134.148 65.5135 128.513 68.2514C125.662 69.6446 122.168 67.7492 120.816 65.1571C118.32 60.3456 122.796 54.4487 126.468 51.9701C130.316 49.378 135.597 49.216 139.478 51.7433C143.133 54.1085 142.972 59.2926 142.393 63.0511C141.04 71.7668 135.614 78.1174 131.218 85.3913C127.707 91.2072 123.134 96.7801 122.941 103.876C122.941 104.054 123.247 104.07 123.263 103.876H123.279Z" fill="url(#paint1_linear_727_4276)"/>
        <path d="M138.14 57.1707C140.507 52.3755 142.971 47.8556 147.399 44.6965C149.75 43.0279 152.455 42.2017 155.257 41.7643C161.665 40.7922 161.375 48.779 157.237 47.321C153.727 46.0735 151.456 45.6685 147.721 46.511C144.452 47.2562 141.232 49.103 139.557 52.0515C139.428 52.2621 139.702 52.4241 139.879 52.3269C143.969 49.9616 149.041 47.2238 153.936 48.8762C155.563 49.427 158.139 51.0794 159.975 50.3018C163.275 48.9086 163.903 44.3725 161.746 41.5537C158.686 37.536 152.6 39.2694 148.735 41.0191C141.618 44.2591 140.459 50.5124 137.882 57.0573C137.818 57.2193 138.06 57.3489 138.14 57.1707Z" fill="url(#paint2_linear_727_4276)"/>
        <path d="M139.572 52.1162C141.972 48.7952 147.189 47.4505 151.021 46.8997C154.177 46.4461 158.058 49.508 160.505 46.7539C160.666 46.5757 160.682 46.2679 160.537 46.0735C158.38 43.4328 152.438 43.3032 149.33 44.1133C144.725 45.3283 141.408 47.6287 139.17 51.857C139.041 52.1 139.411 52.343 139.572 52.1162Z" fill="url(#paint3_linear_727_4276)"/>
        <path d="M160.377 46.203C159.346 42.8982 151.778 43.9836 150.088 46.041C149.701 46.5109 149.911 47.4667 150.603 47.4991C152.149 47.5801 153.807 46.7539 155.353 46.5271C156.979 46.2841 158.59 46.6081 160.2 46.4623C160.329 46.4623 160.409 46.3164 160.361 46.1868L160.377 46.203Z" fill="url(#paint4_linear_727_4276)"/>
        <path d="M139.557 53.3636C143.631 44.1133 143.985 32.4167 152.68 25.8069C154.21 24.6405 162.486 22.0485 159.942 27.265C158.316 30.6184 153.34 32.9675 150.426 35.0411C145.579 38.4918 141.908 42.8497 140.104 48.6008C140.024 48.8924 140.41 48.9896 140.571 48.8276C144.243 45.2635 146.4 41.0028 150.587 37.8438C154.21 35.1059 158.445 32.8379 161.263 29.1928C164.628 24.8511 162.277 20.882 156.979 21.5625C144.436 23.1663 139.96 42.9469 138.961 53.153C138.929 53.4932 139.38 53.6714 139.541 53.3474L139.557 53.3636Z" fill="url(#paint5_linear_727_4276)"/>
        <path d="M140.62 49.508C142.44 44.0809 145.435 39.4152 149.219 35.1383C150.878 33.2591 152.681 31.5094 154.388 29.695C156.223 27.7348 158.429 26.6493 160.378 24.9321C160.925 24.4461 160.974 23.5227 160.233 23.1825C155.805 21.1898 151.216 28.2532 148.816 31.0882C144.388 36.3209 141.087 42.477 139.992 49.2974C139.928 49.67 140.508 49.8806 140.637 49.508H140.62Z" fill="url(#paint6_linear_727_4276)"/>
        <path d="M160.104 23.5227C152.117 21.1575 145.821 31.3151 143.084 37.3902C142.729 38.184 143.76 38.8158 144.324 38.2002C149.058 33.0647 152.632 26.0824 160.055 24.6244C160.683 24.4948 160.716 23.7009 160.104 23.5227Z" fill="url(#paint7_linear_727_4276)"/>
        <path d="M143.26 37.2929C144.227 31.2664 143.904 25.1913 144.468 19.1161C144.967 13.7214 145.595 6.70671 150.072 3.07784C153.373 3.70965 154.629 5.16769 153.823 7.43573C153.534 9.07196 152.68 10.6596 151.923 12.15C150.587 14.8069 149.057 17.3665 147.769 20.0558C145.418 24.9482 143.067 30.1162 142.632 35.5919C142.616 35.8349 142.954 36.0941 143.132 35.8187C147.64 28.9011 150.12 20.477 153.968 13.122C155.305 10.5624 158.896 0.518208 153.115 0.0645981C147.35 -0.389011 144.79 5.7509 143.727 10.4652C141.763 19.1647 142.294 28.4313 142.584 37.2605C142.584 37.6493 143.196 37.6655 143.26 37.2929Z" fill="url(#paint8_linear_727_4276)"/>
        <path d="M142.585 36.8881C145.483 30.2136 145.29 22.6319 147.754 15.7305C149.01 12.2151 150.539 9.0722 152.794 6.10755C153.792 4.79533 153.953 3.4183 153.615 1.87927C153.583 1.74967 153.422 1.68488 153.309 1.68488C145.177 2.02508 143.454 12.5553 143.599 19.084C143.599 19.6834 144.517 19.8292 144.743 19.2946C146.079 16.2003 146.852 12.9441 147.931 9.75262C148.623 7.72758 149.573 5.78354 150.926 4.13111C150.99 4.06631 153.921 1.03686 151.505 2.93229C150.749 3.5155 150.169 4.32552 149.589 5.07073C146.691 8.7968 145.419 13.4625 144.131 17.95C142.327 24.1871 143.181 30.4566 142.231 36.7909C142.198 36.9853 142.488 37.0825 142.569 36.9043L142.585 36.8881Z" fill="url(#paint9_linear_727_4276)"/>
        <path d="M123.809 100.847C125.194 94.3504 126.981 88.3887 124.582 81.941C123.213 78.2635 120.653 74.7318 116.515 74.5374C112.618 74.343 110.622 78.7819 112.779 81.698C114.228 83.6582 118.029 84.7112 119.896 86.2179C123.374 89.0205 124.389 96.0838 123.793 100.312C123.761 100.49 124.019 100.588 124.099 100.409C127.368 93.2974 123.906 85.667 117.481 81.86C116.901 81.5522 116.322 81.2768 115.726 81.0338C116.064 79.5757 116.386 78.1339 116.724 76.6759C117.98 76.7731 119.011 77.2429 119.848 78.1015C121.426 79.4623 122.328 81.6656 123.052 83.561C125.081 88.7775 124.389 95.4196 123.519 100.798C123.487 101.009 123.793 101.057 123.841 100.863L123.809 100.847Z" fill="url(#paint10_linear_727_4276)"/>
        <path d="M124.374 89.9275C123.343 87.5784 122.812 85.1808 121.491 82.8965C120.123 80.5313 118.158 78.6034 115.807 77.2588C114.197 76.3354 112.49 78.9922 114.052 79.9967C115.888 81.1955 117.578 82.5077 119.108 84.0953C120.718 85.7802 122.731 88.032 123.794 90.1219C123.971 90.4621 124.518 90.2677 124.357 89.9112L124.374 89.9275Z" fill="url(#paint11_linear_727_4276)"/>
        <path d="M123.149 103.601C125.259 98.3193 124.067 89.474 117.031 89.1824C114.39 89.069 110.622 91.2722 112.619 94.2855C113.617 95.7921 114.953 96.6021 116.596 97.2501C117.916 97.7685 119.156 98.2545 120.283 99.1294C122.119 100.555 122.328 103.131 122.731 105.253C122.763 105.431 122.972 105.431 123.021 105.253C123.874 102.629 123.713 99.6478 121.475 97.6875C120.444 96.7803 119.398 96.2619 118.11 95.7921C117.063 95.4033 111.942 94.0425 114.342 91.8554C117.224 89.2472 120.766 92.4872 121.813 95.0793C122.779 97.4607 123.101 100.895 122.763 103.455C122.731 103.665 123.053 103.795 123.133 103.568L123.149 103.601Z" fill="url(#paint12_linear_727_4276)"/>
        <path d="M123.165 103.212C123.407 100.069 122.795 97.5583 120.798 95.031C119.413 93.2814 117.4 91.1591 115.066 90.8999C112.827 90.6569 112.183 94.14 114.422 94.383C115.967 94.545 117.384 96.1812 118.447 97.2018C120.089 98.7895 122.134 100.977 122.714 103.261C122.762 103.488 123.149 103.455 123.165 103.228V103.212Z" fill="url(#paint13_linear_727_4276)"/>
        <path d="M118.189 95.8897C117.158 94.626 115.532 93.33 114.308 95.0634C114.211 95.193 114.195 95.4199 114.34 95.5333C114.855 95.8897 115.129 96.1813 115.806 96.2785C116.498 96.3595 117.19 96.2299 117.899 96.3271C118.124 96.3595 118.317 96.1003 118.173 95.9059L118.189 95.8897Z" fill="url(#paint14_linear_727_4276)"/>
        <path d="M138.527 14.0781C135.983 7.53314 128.415 2.49486 121.378 3.28867C114.245 4.08249 109.72 10.2872 108.529 16.9779C107.369 23.458 111.218 30.0678 117.594 31.8498C123.939 33.6318 128.576 31.3476 128.721 24.4787C128.721 24.2843 128.431 24.2357 128.351 24.4139C126.112 30.1812 121.298 31.2342 116.032 28.4963C110.622 25.6937 110.01 19.408 111.83 14.2401C116.242 1.71725 132.279 4.5361 138.173 14.2401C138.302 14.4507 138.624 14.2725 138.527 14.0457V14.0781Z" fill="url(#paint15_linear_727_4276)"/>
        <path d="M128.705 24.511C125.726 31.8011 117.852 34.2636 112.989 27.184C108.883 21.2223 110.509 14.8556 112.329 8.53749C112.474 8.03528 111.846 7.79227 111.492 8.00288C104.117 12.1664 104.503 21.3357 108.239 28.1237C110.316 31.9145 114.438 34.2636 118.496 35.187C119.462 35.4138 120.444 35.2842 121.395 35.187C121.974 35.1222 123.665 33.1943 122.489 33.1943C119.994 33.2105 118.866 34.4418 116.177 32.4977C114.599 31.3637 113.843 29.3711 112.635 27.913C111.926 27.0382 110.477 27.4756 110.558 28.6745C110.767 31.6229 112.78 34.0368 115.163 35.6082C118.319 37.6656 121.153 35.187 123.939 33.7776C124.228 33.6318 124.164 33.1619 123.81 33.1943C117.627 33.5021 111.62 29.4521 109.576 23.701C108.014 19.2945 108.448 12.2474 112.104 8.87769C111.894 8.58609 111.685 8.29449 111.492 7.98668C103.505 13.6082 106.709 26.0986 112.522 32.1089C119.06 38.8807 129.188 33.1295 129.075 24.5596C129.075 24.3652 128.785 24.3166 128.705 24.4948V24.511Z" fill="url(#paint16_linear_727_4276)"/>
        <path d="M109.366 13.3327C110.573 11.8423 111.636 10.2547 112.811 8.73185C114.615 6.39901 118.012 5.62139 120.718 5.05438C123.326 4.50357 125.935 4.56838 128.398 5.6538C130.057 6.38281 131.216 7.43583 133.02 7.80844C133.309 7.87324 133.599 7.54923 133.47 7.27382C131.216 2.02493 124.099 1.34452 119.252 2.31654C112.876 3.59636 109.961 6.90121 108.802 13.0897C108.738 13.3975 109.14 13.5919 109.349 13.3489L109.366 13.3327Z" fill="url(#paint17_linear_727_4276)"/>
        <path d="M133.02 6.35061C129.075 1.27991 117.723 -1.65234 113.616 4.71437C113.278 5.24898 113.52 6.20481 114.325 6.0752C116.821 5.6378 118.817 4.22837 121.426 4.09877C125.291 3.90436 129.268 5.02218 132.681 6.77182C132.923 6.90142 133.197 6.57741 133.02 6.36681V6.35061Z" fill="url(#paint18_linear_727_4276)"/>
        <path d="M118.126 19.278C119.527 17.9334 121.813 17.982 123.552 18.306C126.113 18.7758 126.95 21.2383 127.191 23.5063C127.61 27.5888 125.436 31.4121 122.393 33.8907C122.216 34.0365 122.361 34.3119 122.586 34.2471C128.319 32.4489 130.525 26.0174 129.091 20.5255C127.626 14.9364 120.799 14.9526 117.756 18.954C117.546 19.2132 117.917 19.5048 118.142 19.278H118.126Z" fill="url(#paint19_linear_727_4276)"/>
        <path d="M122.022 25.969C122.022 25.969 122.087 26.0662 122.119 26.1148C122.264 26.3578 122.57 26.4712 122.844 26.455C125.323 26.212 124.454 23.5066 123.536 22.1133C122.973 21.2385 122.216 20.5905 121.33 20.0721C120.461 19.5699 120.139 19.3593 120.783 18.3711C121.137 17.8203 121.314 17.2371 121.572 16.6539C121.717 16.346 121.523 16.022 121.217 15.941C117.82 15.131 116.161 19.3917 118.351 21.7407C118.786 22.1944 119.543 22.4536 120.09 22.6966C120.686 22.9558 121.153 23.458 121.62 23.8954C121.958 24.2194 122.522 24.7216 122.151 25.1428C121.974 25.3534 121.861 25.726 122.022 25.969Z" fill="url(#paint20_linear_727_4276)"/>
        <path d="M121.248 16.524C119.332 16.8156 116.53 18.4356 116.772 20.7523C116.901 22.0483 117.352 23.0365 118.35 23.8789C119.01 24.4298 121.538 24.9158 121.554 25.904C121.973 25.4018 122.408 24.8996 122.826 24.3811C119.735 24.608 120.009 18.063 123.181 19.3429C125.934 20.4445 127.061 23.2633 126.643 26.0336C126.417 27.5564 125.548 28.8686 124.404 29.8407C122.601 31.3959 120.266 30.7317 118.141 30.9585C117.625 31.0071 117.4 31.6713 117.803 32.0277C121.989 35.608 127.657 31.7199 128.913 27.1514C130.121 22.7611 128.317 17.5608 123.632 16.2162C116.418 14.1588 115.709 27.0218 123.052 27.1676C123.615 27.1676 124.276 26.6006 124.308 26.0174C124.388 24.6566 124.018 23.6197 122.81 22.8583C122.037 22.3723 121.2 22.0159 120.395 21.6109C118.366 20.5903 120.894 17.9982 121.538 17.091C121.699 16.8642 121.57 16.4592 121.232 16.5078L121.248 16.524Z" fill="url(#paint21_linear_727_4276)"/>
        <path d="M123.52 122.458C110.38 117.242 96.4682 121.713 83.522 125.293C71.4293 128.631 58.7247 131.239 46.2294 132.421C34.7164 133.507 22.2855 132.259 12.3183 125.958C4.66974 121.114 0.966232 112.576 4.02564 103.844C7.26218 94.5776 18.6947 92.1151 27.1806 93.7514C34.1045 95.0798 42.8319 103.472 40.0462 111.28C37.5665 118.23 28.6781 120.547 22.1406 118.732C15.8285 116.983 13.3649 106.355 20.788 104.087C26.2949 102.402 29.6603 105.675 30.1595 110.94C30.3849 113.273 26.7941 113.564 26.5687 111.248C26.4399 109.822 26.5687 108.494 25.0873 107.57C20.7719 104.881 18.8557 111.248 20.4016 113.937C22.0923 116.885 26.8424 116.788 29.6764 116.464C33.7019 116.011 37.3893 113.726 38.2749 109.514C39.3538 104.33 34.2333 99.146 30.1595 97.04C21.8346 92.7145 9.82243 96.1652 5.8774 104.751C1.20777 114.909 10.4987 123.365 18.7108 127.059C36.4232 135.03 58.1289 129.052 76.1633 125.439C91.7502 122.312 108.158 115.12 123.713 122.086C123.955 122.199 123.777 122.555 123.536 122.458H123.52Z" fill="url(#paint22_linear_727_4276)"/>
        <path d="M105.632 119.996C97.2265 120.206 89.3525 126.362 82.3481 130.493C76.1165 134.171 69.724 136.844 62.4458 137.314C50.0471 138.124 45.458 125.925 52.0116 116.351C55.3286 111.507 61.7212 109.271 67.3087 110.648C71.9461 111.782 74.4258 117.582 72.6546 121.907C70.9477 126.055 66.6968 128.404 63.0094 125.018C60.3847 122.604 60.2237 117.647 64.056 116.529C66.2137 115.897 67.2603 118.894 65.2154 119.801C63.8789 120.401 66.6163 127.091 69.885 121.713C71.5435 118.991 70.0299 115.298 67.5985 113.694C63.0899 110.729 56.8101 114.617 53.9922 118.052C51.0455 121.648 50.3692 126.913 52.4786 131.06C54.4591 134.948 59.6118 135.305 63.3636 135.094C72.1071 134.608 78.9344 129.797 86.5668 126.119C92.6696 123.187 98.6274 119.153 105.664 119.655C105.841 119.655 105.825 119.979 105.648 119.979L105.632 119.996Z" fill="url(#paint23_linear_727_4276)"/>
        <path d="M57.9675 130.25C52.992 132.146 48.274 134.154 44.7154 138.286C42.8315 140.473 41.7365 143.097 41.0281 145.867C39.4178 152.185 47.3723 152.704 46.3257 148.411C45.44 144.766 45.2629 142.465 46.4706 138.804C47.5333 135.612 49.6749 132.567 52.7504 131.174C52.9759 131.076 53.1047 131.368 52.992 131.53C50.2546 135.386 47.0342 140.197 48.1774 145.252C48.5639 146.936 49.9326 149.674 48.9825 151.44C47.2757 154.599 42.7349 154.794 40.1424 152.347C36.4711 148.881 38.7899 142.967 40.8992 139.274C44.8121 132.47 51.108 131.919 57.8387 130.007C58.0158 129.959 58.1124 130.218 57.9353 130.283L57.9675 130.25Z" fill="url(#paint24_linear_727_4276)"/>
        <path d="M52.816 131.189C49.2897 133.263 47.454 138.35 46.5201 142.141C45.7633 145.251 48.404 149.431 45.4412 151.618C45.248 151.764 44.9421 151.748 44.7649 151.586C42.3657 149.172 42.8166 143.194 43.9437 140.181C45.6023 135.693 48.2108 132.599 52.6067 130.784C52.8643 130.687 53.0576 131.076 52.8321 131.206L52.816 131.189Z" fill="url(#paint25_linear_727_4276)"/>
        <path d="M44.9099 151.424C41.7378 150.064 43.5573 142.595 45.7794 141.121C46.2786 140.781 47.2125 141.072 47.1642 141.785C47.0837 143.341 46.1176 144.912 45.7311 146.451C45.3286 148.055 45.5057 149.691 45.1837 151.295C45.1514 151.424 45.0226 151.489 44.8938 151.441L44.9099 151.424Z" fill="url(#paint26_linear_727_4276)"/>
        <path d="M54.0552 131.287C44.4906 134.446 32.897 133.653 25.5061 141.688C24.2018 143.097 20.8204 151.133 26.2307 149.108C29.7088 147.812 32.5267 143.065 34.8776 140.343C38.7743 135.823 43.4439 132.583 49.3212 131.368C49.611 131.303 49.6755 131.708 49.4983 131.854C45.6177 135.175 41.1896 136.909 37.631 140.797C34.5716 144.15 31.8987 148.168 28.018 150.63C23.3806 153.579 19.7093 150.825 20.9009 145.592C23.7188 133.183 43.7499 130.688 53.9425 130.688C54.2807 130.688 54.4095 131.174 54.0713 131.287H54.0552Z" fill="url(#paint27_linear_727_4276)"/>
        <path d="M50.1426 131.968C44.5874 133.247 39.6762 135.791 35.0871 139.144C33.0743 140.619 31.1582 142.239 29.1776 143.778C27.0682 145.43 25.7639 147.52 23.88 149.302C23.3486 149.804 22.4147 149.772 22.1571 148.978C20.6274 144.345 28.0666 140.457 31.1099 138.334C36.7295 134.414 43.1382 131.725 49.9977 131.303C50.3681 131.287 50.5291 131.87 50.1426 131.968Z" fill="url(#paint28_linear_727_4276)"/>
        <path d="M22.5112 148.914C20.9654 140.684 31.6411 135.386 37.921 133.248C38.7422 132.972 39.2574 134.058 38.5973 134.576C33.042 138.805 25.796 141.704 23.6061 148.978C23.429 149.594 22.6239 149.545 22.5112 148.93V148.914Z" fill="url(#paint29_linear_727_4276)"/>
        <path d="M37.7926 133.409C31.7382 133.782 25.7643 132.858 19.6937 132.826C14.3156 132.794 7.31118 132.729 3.28564 136.844C3.59158 140.213 4.89586 141.623 7.23067 141.04C8.8892 140.91 10.5316 140.213 12.0774 139.614C14.8309 138.529 17.5199 137.265 20.3056 136.244C25.3778 134.381 30.7237 132.534 36.1663 132.632C36.4239 132.632 36.6332 133.004 36.3434 133.15C29.0652 136.99 20.4827 138.626 12.8342 141.752C10.1774 142.838 -0.111916 145.43 0.0168991 139.614C0.129616 133.798 6.45777 131.838 11.2401 131.238C20.0319 130.137 29.1457 131.579 37.8409 132.761C38.2273 132.81 38.179 133.409 37.8087 133.442L37.7926 133.409Z" fill="url(#paint30_linear_727_4276)"/>
        <path d="M37.4698 132.697C30.5942 134.933 23.1066 133.993 16.0217 135.791C12.4148 136.699 9.16218 137.93 6.00615 139.89C4.60527 140.765 3.23658 140.797 1.73908 140.295C1.61026 140.246 1.56196 140.084 1.57806 139.971C2.72131 131.855 13.3004 131.19 19.7413 131.984C20.3371 132.065 20.3854 132.972 19.8379 133.151C16.6336 134.187 13.3487 134.641 10.08 135.403C8.00282 135.889 6.00616 136.666 4.23492 137.849C4.15441 137.897 0.869568 140.538 2.97895 138.302C3.63914 137.606 4.49255 137.104 5.28156 136.601C9.25879 134.074 13.9928 133.264 18.5497 132.405C24.894 131.223 31.0128 132.681 37.3732 132.373C37.5664 132.373 37.6469 132.648 37.4537 132.713L37.4698 132.697Z" fill="url(#paint31_linear_727_4276)"/>
        <path d="M102.587 120.222C96.0175 120.967 89.947 122.166 83.8121 119.121C80.3179 117.387 77.0814 114.471 77.2907 110.308C77.484 106.387 82.0731 104.816 84.746 107.278C86.5495 108.931 87.2097 112.819 88.5139 114.844C90.9454 118.586 97.8371 120.303 102.072 120.125C102.249 120.125 102.313 120.384 102.136 120.449C94.7777 123.009 87.5639 118.797 84.4401 111.976C84.1824 111.377 83.9731 110.761 83.796 110.129C82.3307 110.324 80.8654 110.502 79.4001 110.696C79.3679 111.96 79.7383 113.046 80.5112 113.953C81.7027 115.67 83.796 116.788 85.5994 117.695C90.5589 120.238 97.193 120.206 102.603 119.866C102.813 119.866 102.845 120.174 102.636 120.19L102.587 120.222Z" fill="url(#paint32_linear_727_4276)"/>
        <path d="M91.7178 119.704C89.4957 118.424 87.177 117.663 85.0515 116.124C82.8455 114.536 81.1387 112.365 80.0438 109.887C79.3031 108.186 82.0726 106.744 82.9261 108.396C83.9244 110.356 85.0676 112.187 86.4846 113.872C87.9982 115.654 90.0271 117.89 91.9755 119.169C92.2975 119.38 92.056 119.898 91.7178 119.704Z" fill="url(#paint33_linear_727_4276)"/>
        <path d="M105.374 119.833C99.9477 121.421 91.3009 119.347 91.7195 112.284C91.8644 109.627 94.4247 106.079 97.2104 108.364C98.5951 109.514 99.2553 110.923 99.7545 112.64C100.141 114.001 100.495 115.297 101.252 116.529C102.476 118.505 105.004 118.975 107.065 119.59C107.226 119.639 107.21 119.85 107.065 119.882C104.376 120.465 101.461 120.012 99.7384 117.582C98.9494 116.448 98.5307 115.362 98.1926 114.017C97.9189 112.932 97.0654 107.667 94.6823 109.854C91.8161 112.495 94.6823 116.35 97.1299 117.663C99.4003 118.861 102.766 119.526 105.31 119.445C105.535 119.445 105.616 119.769 105.39 119.833H105.374Z" fill="url(#paint34_linear_727_4276)"/>
        <path d="M104.986 119.817C101.862 119.752 99.4304 118.878 97.1277 116.642C95.5336 115.087 93.6336 112.851 93.6014 110.502C93.5692 108.234 97.0955 107.926 97.1116 110.21C97.1116 111.766 98.593 113.353 99.4948 114.52C100.896 116.334 102.86 118.57 105.066 119.396C105.275 119.477 105.211 119.85 104.986 119.833V119.817Z" fill="url(#paint35_linear_727_4276)"/>
        <path d="M98.2397 114.115C97.0964 112.965 95.9693 111.199 97.8049 110.162C97.9498 110.081 98.1592 110.081 98.2558 110.243C98.5617 110.794 98.8355 111.102 98.8516 111.798C98.8516 112.511 98.6583 113.175 98.6905 113.904C98.6905 114.131 98.4168 114.309 98.2397 114.131V114.115Z" fill="url(#paint36_linear_727_4276)"/>
        <path d="M15.3131 126.379C9.09764 123.171 4.86278 115.103 6.34418 108.137C7.84168 101.074 14.4275 97.1535 21.1582 96.6189C27.6795 96.1005 33.8306 100.604 34.9738 107.165C36.101 113.694 33.3797 118.1 26.5846 117.566C26.3914 117.55 26.3753 117.242 26.5524 117.193C32.478 115.525 33.9916 110.81 31.8178 105.27C29.5796 99.5835 23.4286 98.3361 18.131 99.6484C5.31364 102.84 6.5052 119.154 15.5385 126.022C15.7317 126.168 15.5224 126.476 15.3131 126.363V126.379Z" fill="url(#paint37_linear_727_4276)"/>
        <path d="M26.5845 117.566C34.0881 115.298 37.3086 107.667 30.7872 102.094C25.2964 97.3962 18.8394 98.3844 12.4146 99.5832C11.8994 99.6804 11.7222 99.0324 11.9638 98.6922C16.8105 91.7261 25.8438 93.0221 32.1881 97.4286C35.7306 99.891 37.6467 104.233 38.162 108.396C38.2747 109.384 38.0654 110.356 37.8721 111.312C37.7594 111.879 35.6662 113.386 35.795 112.203C36.0526 109.708 37.3891 108.704 35.7306 105.804C34.7644 104.119 32.8805 103.147 31.544 101.803C30.755 101.009 31.3347 99.5994 32.5101 99.7938C35.4085 100.296 37.5984 102.548 38.9027 105.091C40.6256 108.461 37.8883 111.037 36.2136 113.694C36.0365 113.969 35.5856 113.856 35.6501 113.499C36.5679 107.343 33.1542 100.928 27.6634 98.3034C23.4607 96.2946 16.4402 96.0515 12.7528 99.3726C12.479 99.1296 12.2053 98.9028 11.9477 98.6598C18.2919 91.2239 30.3363 95.6789 35.7145 102.094C41.7689 109.32 35.0704 118.878 26.6167 117.906C26.4235 117.89 26.4074 117.582 26.5845 117.533V117.566Z" fill="url(#paint38_linear_727_4276)"/>
        <path d="M17.4556 97.0885C15.8615 98.1578 14.1868 99.065 12.5605 100.086C10.0808 101.657 8.96973 104.994 8.13242 107.635C7.32731 110.195 7.13409 112.803 7.9714 115.379C8.53498 117.112 9.4528 118.376 9.64603 120.223C9.67823 120.514 9.34009 120.773 9.06635 120.628C4.09078 117.857 4.13909 110.648 5.57218 105.901C7.47224 99.632 11.0147 97.0561 17.2623 96.5053C17.5683 96.4729 17.7293 96.9103 17.4556 97.0723V97.0885Z" fill="url(#paint39_linear_727_4276)"/>
        <path d="M8.19622 120.093C3.5749 115.638 1.80366 103.99 8.50216 100.507C9.06574 100.215 9.98355 100.555 9.77422 101.333C9.08183 103.796 7.50383 105.642 7.11737 108.251C6.5377 112.09 7.26229 116.205 8.64707 119.769C8.74369 120.028 8.40555 120.271 8.21232 120.077L8.19622 120.093Z" fill="url(#paint40_linear_727_4276)"/>
        <path d="M22.4796 106.468C21.0143 107.732 20.8371 110.033 20.9821 111.798C21.1914 114.407 23.5423 115.492 25.7644 115.946C29.7577 116.772 33.7672 114.974 36.5045 112.171C36.6656 112.009 36.9232 112.171 36.8266 112.398C34.4757 117.954 27.906 119.542 22.6084 117.549C17.2302 115.541 17.9226 108.688 22.1736 106.047C22.4634 105.869 22.705 106.258 22.4634 106.468H22.4796Z" fill="url(#paint41_linear_727_4276)"/>
        <path d="M28.695 111.02C28.695 111.02 28.7917 111.085 28.84 111.134C29.0654 111.312 29.162 111.62 29.0976 111.895C28.6145 114.358 26.0221 113.207 24.7339 112.171C23.9288 111.506 23.3491 110.696 22.9305 109.757C22.5118 108.833 22.3508 108.493 21.3042 109.044C20.7245 109.352 20.1287 109.465 19.5329 109.676C19.2109 109.789 18.9049 109.562 18.8566 109.238C18.3897 105.755 22.7695 104.524 24.8788 106.938C25.2975 107.408 25.4585 108.201 25.6517 108.768C25.845 109.384 26.2958 109.903 26.6823 110.421C26.9721 110.794 27.4069 111.393 27.8577 111.085C28.0832 110.923 28.4535 110.842 28.695 111.037V111.02Z" fill="url(#paint42_linear_727_4276)"/>
        <path d="M19.4357 109.303C19.9188 107.424 21.7866 104.783 24.057 105.237C25.3291 105.496 26.263 106.047 26.9876 107.133C27.4707 107.845 27.6961 110.437 28.6783 110.535C28.147 110.907 27.5995 111.28 27.052 111.652C27.5834 108.574 21.0781 108.218 22.0281 111.507C22.8493 114.358 25.5223 115.783 28.308 115.638C29.8377 115.557 31.2064 114.811 32.3013 113.775C34.0243 112.122 33.6056 109.725 34.0243 107.619C34.137 107.1 34.8133 106.954 35.1192 107.392C38.243 111.928 33.8471 117.225 29.1936 118.035C24.7333 118.813 19.7738 116.48 18.9043 111.669C17.5839 104.249 30.3852 104.8 29.7894 112.171C29.7411 112.738 29.097 113.337 28.5334 113.305C27.1647 113.256 26.1986 112.77 25.5545 111.507C25.152 110.697 24.8943 109.822 24.5723 108.963C23.7672 106.825 20.9493 109.109 19.9832 109.66C19.7416 109.789 19.3552 109.627 19.4357 109.303Z" fill="url(#paint43_linear_727_4276)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_727_4276" x1="104.98" y1="-0.00145322" x2="135.206" y2="-1.86341" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint1_linear_727_4276" x1="117.686" y1="47.6798" x2="139.296" y2="45.6021" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint2_linear_727_4276" x1="137.872" y1="39.101" x2="156.586" y2="33.9468" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint3_linear_727_4276" x1="139.143" y1="43.6746" x2="152.904" y2="36.7779" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint4_linear_727_4276" x1="149.896" y1="44.0965" x2="156.005" y2="40.3587" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint5_linear_727_4276" x1="138.96" y1="21.486" x2="157.668" y2="18.7126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint6_linear_727_4276" x1="139.987" y1="22.8334" x2="156.265" y2="20.3265" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint7_linear_727_4276" x1="143.011" y1="23.1722" x2="156.361" y2="20.1238" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint8_linear_727_4276" x1="142.294" y1="0.0408558" x2="153.453" y2="-0.787871" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint9_linear_727_4276" x1="142.228" y1="1.68487" x2="151.439" y2="1.08567" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint10_linear_727_4276" x1="111.894" y1="74.5313" x2="122.882" y2="73.3856" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint11_linear_727_4276" x1="113.435" y1="77.067" x2="121.976" y2="75.6606" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint12_linear_727_4276" x1="112.057" y1="89.1782" x2="121.49" y2="87.785" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint13_linear_727_4276" x1="113.031" y1="90.8878" x2="120.981" y2="89.602" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint14_linear_727_4276" x1="114.233" y1="94.2253" x2="117.031" y2="93.1703" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint15_linear_727_4276" x1="108.323" y1="3.20621" x2="131.549" y2="-1.57217" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint16_linear_727_4276" x1="105.628" y1="7.9269" x2="123.92" y2="4.92941" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint17_linear_727_4276" x1="108.795" y1="1.9425" x2="125.537" y2="-5.20319" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint18_linear_727_4276" x1="113.468" y1="1.07892" x2="124.192" y2="-6.23128" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint19_linear_727_4276" x1="117.694" y1="16.1195" x2="127.007" y2="14.9118" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint20_linear_727_4276" x1="117.361" y1="15.8401" x2="122.953" y2="15.0958" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint21_linear_727_4276" x1="116.757" y1="15.9934" x2="126.586" y2="14.5892" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint22_linear_727_4276" x1="2.95247" y1="93.2811" x2="73.5882" y2="50.268" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint23_linear_727_4276" x1="49.0298" y1="110.247" x2="87.7731" y2="94.1067" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint24_linear_727_4276" x1="38.2118" y1="130.001" x2="53.6701" y2="127.463" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint25_linear_727_4276" x1="43.0283" y1="130.769" x2="50.8919" y2="130.03" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint26_linear_727_4276" x1="43.2039" y1="140.979" x2="46.3599" y2="140.742" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint27_linear_727_4276" x1="20.6742" y1="130.688" x2="45.1423" y2="122.955" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint28_linear_727_4276" x1="21.9522" y1="131.303" x2="42.7366" y2="124.894" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint29_linear_727_4276" x1="22.3601" y1="133.204" x2="35.0842" y2="130.622" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint30_linear_727_4276" x1="0.0157006" y1="130.845" x2="22.2602" y2="117.281" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint31_linear_727_4276" x1="1.57507" y1="131.731" x2="19.0641" y2="117.648" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint32_linear_727_4276" x1="77.2811" y1="106.098" x2="95.6361" y2="99.9352" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint33_linear_727_4276" x1="79.9192" y1="107.667" x2="89.3184" y2="105.777" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint34_linear_727_4276" x1="91.7049" y1="107.631" x2="103.414" y2="104.794" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint35_linear_727_4276" x1="93.6012" y1="108.642" x2="102.509" y2="106.806" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint36_linear_727_4276" x1="96.834" y1="110.109" x2="98.4348" y2="109.952" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint37_linear_727_4276" x1="6.04755" y1="96.5783" x2="28.5634" y2="92.1945" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint38_linear_727_4276" x1="11.8572" y1="93.7957" x2="32.8779" y2="89.023" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint39_linear_727_4276" x1="4.73228" y1="96.5036" x2="14.9223" y2="95.4245" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint40_linear_727_4276" x1="4.08666" y1="100.403" x2="8.65197" y2="100.14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint41_linear_727_4276" x1="18.7794" y1="106.002" x2="32.1263" y2="102.124" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint42_linear_727_4276" x1="18.8221" y1="105.683" x2="26.5217" y2="103.63" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <linearGradient id="paint43_linear_727_4276" x1="18.8087" y1="105.186" x2="31.7986" y2="101.734" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF99E"/>
          <stop offset="1" stopColor="#B37839"/>
        </linearGradient>
        <clipPath id="clip0_727_4276">
          <rect width="163.002" height="154" fill="white" transform="matrix(-1 0 0 -1 163.002 154)"/>
        </clipPath>
      </defs>
    </svg>
    )
}