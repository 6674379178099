export const FrameSvg4 : React.FC = ()=>{
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="86" height="81" viewBox="0 0 86 81" fill="none">
  <g opacity="0.3" clipPath="url(#clip0_654_13330)">
    <path d="M65.3424 64.298C63.323 57.1533 66.3605 50.1101 68.8763 43.5156C71.2322 37.3613 73.2348 30.8515 74.4884 24.3755C75.6412 18.4075 75.6496 11.8384 72.8982 6.30208C70.7947 2.05249 66.5708 -0.33474 61.8926 0.816547C56.9368 2.03555 55.0773 7.88509 55.4811 12.4056C55.8093 16.0965 59.6881 21.0995 63.8783 20.0413C67.5973 19.1016 69.2549 14.5727 68.6575 11.0596C68.0769 7.66499 62.7172 5.82801 61.1606 9.58662C60.0079 12.3886 61.5224 14.3103 64.2149 14.8436C65.4097 15.0806 65.7463 13.2182 64.5599 12.9812C63.8363 12.8373 63.1295 12.8373 62.734 12.0162C61.5729 9.62048 64.9553 8.95172 66.2679 9.89137C67.7067 10.9241 67.4122 13.4129 67.0925 14.8774C66.6466 16.9515 65.2751 18.7715 63.0538 19.017C60.3192 19.3133 57.9044 16.3673 57.0209 14.1325C55.2119 9.55276 57.6267 3.44926 62.2628 1.83239C67.7572 -0.089241 71.6445 5.21004 73.1338 9.69667C76.3395 19.381 72.1241 30.4198 69.3222 39.6724C66.899 47.6636 62.3302 55.875 65.132 64.3742C65.1741 64.5096 65.376 64.4334 65.334 64.298H65.3424Z" fill="url(#paint0_linear_654_13330)"/>
    <path d="M64.9899 54.8081C65.5368 50.423 69.1212 46.6136 71.6202 43.1682C73.8415 40.1038 75.5495 36.887 76.1722 33.1114C77.2324 26.6693 71.1658 23.6303 65.8818 26.5677C63.2062 28.0492 61.7169 31.2829 62.1376 34.2797C62.491 36.7685 65.3517 38.3599 67.674 37.6573C69.9121 36.9801 71.3425 34.8722 69.7859 32.7728C68.6669 31.2745 66.1174 30.9359 65.3433 32.8744C64.9058 33.9664 66.4035 34.6691 66.9756 33.6532C67.3543 32.9845 70.6694 34.7622 67.7245 36.1928C66.2352 36.9208 64.4094 35.9304 63.7026 34.5759C62.3984 32.0617 64.7375 28.9804 66.6559 27.6852C68.6669 26.3307 71.4267 26.2461 73.4545 27.5667C75.3644 28.8026 75.2803 31.5115 74.9774 33.4755C74.2706 38.0298 71.4351 41.3482 69.1381 45.1491C67.3038 48.1882 64.9142 51.1002 64.8132 54.8081C64.8132 54.9012 64.9731 54.9096 64.9815 54.8081H64.9899Z" fill="url(#paint1_linear_654_13330)"/>
    <path d="M72.7551 30.4027C73.992 27.8969 75.2793 25.5351 77.5932 23.8844C78.8216 23.0124 80.2352 22.5807 81.6992 22.3521C85.048 21.8442 84.8966 26.0176 82.7342 25.2557C80.8999 24.6039 79.7135 24.3923 77.7615 24.8325C76.0534 25.2219 74.3706 26.1869 73.4955 27.7276C73.4282 27.8377 73.5713 27.9223 73.6638 27.8715C75.801 26.6356 78.4514 25.2049 81.0093 26.0684C81.8591 26.3562 83.2053 27.2197 84.1645 26.8134C85.8894 26.0853 86.2176 23.715 85.0901 22.2421C83.4914 20.1427 80.3109 21.0485 78.2915 21.9627C74.5725 23.6558 73.9667 26.9234 72.6205 30.3434C72.5868 30.428 72.713 30.4958 72.7551 30.4027Z" fill="url(#paint2_linear_654_13330)"/>
    <path d="M73.5038 27.7615C74.7574 26.0261 77.4836 25.3235 79.4861 25.0357C81.1353 24.7987 83.1631 26.3986 84.442 24.9595C84.5261 24.8664 84.5346 24.7055 84.4588 24.6039C83.3314 23.2241 80.2266 23.1564 78.6027 23.5796C76.1962 24.2145 74.463 25.4166 73.2934 27.6261C73.2261 27.753 73.4196 27.88 73.5038 27.7615Z" fill="url(#paint3_linear_654_13330)"/>
    <path d="M84.375 24.6717C83.8365 22.9447 79.8819 23.5119 78.9984 24.587C78.7965 24.8325 78.9059 25.332 79.2677 25.3489C80.0754 25.3912 80.9421 24.9595 81.7498 24.841C82.5996 24.714 83.441 24.8833 84.2824 24.8071C84.3498 24.8071 84.3918 24.7309 84.3666 24.6632L84.375 24.6717Z" fill="url(#paint4_linear_654_13330)"/>
    <path d="M73.4956 28.4132C75.6244 23.5795 75.8095 17.4675 80.3531 14.0137C81.1524 13.4042 85.4772 12.0497 84.1478 14.7756C83.298 16.5279 80.698 17.7554 79.1751 18.8389C76.6425 20.642 74.7241 22.9192 73.7817 25.9244C73.7396 26.0768 73.9416 26.1276 74.0257 26.0429C75.9441 24.1805 77.0716 21.9542 79.2592 20.3034C81.1524 18.8728 83.3653 17.6876 84.8377 15.7829C86.5963 13.5142 85.3678 11.4402 82.5996 11.7958C76.0451 12.6338 73.706 22.97 73.1843 28.3032C73.1675 28.4809 73.4031 28.574 73.4872 28.4047L73.4956 28.4132Z" fill="url(#paint5_linear_654_13330)"/>
    <path d="M74.0514 26.3986C75.0022 23.5627 76.5672 21.1247 78.5445 18.8899C79.4111 17.9079 80.3535 16.9937 81.2454 16.0455C82.2046 15.0212 83.3573 14.4541 84.3754 13.5567C84.6615 13.3028 84.6867 12.8203 84.2997 12.6425C81.9858 11.6012 79.5878 15.2921 78.3341 16.7736C76.0203 19.5079 74.2954 22.7247 73.7232 26.2886C73.6896 26.4833 73.9925 26.5933 74.0598 26.3986H74.0514Z" fill="url(#paint6_linear_654_13330)"/>
    <path d="M84.2323 12.8203C80.0589 11.5844 76.769 16.8921 75.3387 20.0666C75.1536 20.4814 75.692 20.8116 75.9865 20.4899C78.4603 17.8064 80.3282 14.1579 84.2071 13.396C84.5352 13.3282 84.552 12.9134 84.2323 12.8203Z" fill="url(#paint7_linear_654_13330)"/>
    <path d="M75.431 20.0157C75.9358 16.8666 75.7675 13.6921 76.062 10.5176C76.3229 7.69869 76.651 4.0332 78.9901 2.13697C80.715 2.46712 81.3713 3.229 80.9506 4.41414C80.7991 5.26914 80.3532 6.09874 79.9577 6.87755C79.2594 8.26587 78.46 9.60338 77.7869 11.0086C76.5585 13.5652 75.33 16.2656 75.1028 19.1269C75.0944 19.2539 75.2711 19.3893 75.3637 19.2454C77.7196 15.6307 79.0154 11.2287 81.0263 7.38547C81.7247 6.04795 83.601 0.799454 80.5804 0.562426C77.5681 0.325396 76.2303 3.53375 75.675 5.99716C74.6485 10.543 74.9261 15.3852 75.0776 19.9988C75.0776 20.202 75.3973 20.2104 75.431 20.0157Z" fill="url(#paint8_linear_654_13330)"/>
    <path d="M75.078 19.8043C76.5926 16.3165 76.4916 12.3548 77.779 8.74854C78.4352 6.91157 79.2346 5.2693 80.4125 3.72014C80.9342 3.03445 81.0184 2.3149 80.8417 1.51069C80.8248 1.44297 80.7407 1.40911 80.6818 1.40911C76.4327 1.58689 75.5324 7.08934 75.6081 10.5009C75.6081 10.8141 76.0877 10.8903 76.2055 10.6109C76.9039 8.99404 77.3078 7.29251 77.8715 5.62484C78.2333 4.56668 78.7297 3.55084 79.4365 2.68737C79.4702 2.65351 81.0015 1.0705 79.7394 2.06094C79.344 2.36569 79.0411 2.78896 78.7382 3.17836C77.2236 5.12539 76.5589 7.5634 75.8858 9.90829C74.9434 13.1674 75.3894 16.4435 74.8929 19.7535C74.8761 19.855 75.0276 19.9058 75.0696 19.8127L75.078 19.8043Z" fill="url(#paint9_linear_654_13330)"/>
    <path d="M65.2666 53.2253C65.9902 49.8307 66.9242 46.7155 65.6705 43.3463C64.9553 41.4246 63.6175 39.5792 61.4551 39.4776C59.4189 39.376 58.3755 41.6955 59.503 43.2193C60.2603 44.2436 62.246 44.7938 63.222 45.5811C65.0395 47.0456 65.5695 50.7365 65.2582 52.9459C65.2414 53.0391 65.376 53.0898 65.4181 52.9967C67.1261 49.2805 65.3171 45.2933 61.9599 43.3039C61.657 43.1431 61.3541 42.9992 61.0428 42.8722C61.2195 42.1103 61.3878 41.3569 61.5645 40.595C62.2208 40.6458 62.7593 40.8913 63.1968 41.34C64.0214 42.0511 64.4926 43.2024 64.8712 44.1928C65.9314 46.9186 65.5695 50.3894 65.1152 53.1999C65.0984 53.3099 65.2582 53.3353 65.2835 53.2338L65.2666 53.2253Z" fill="url(#paint10_linear_654_13330)"/>
    <path d="M65.5617 47.5194C65.0232 46.292 64.7456 45.0391 64.0556 43.8455C63.3404 42.6096 62.3139 41.6022 61.0854 40.8996C60.244 40.417 59.3522 41.8054 60.1683 42.3302C61.1275 42.9566 62.011 43.6423 62.8103 44.4719C63.6517 45.3523 64.7035 46.529 65.2588 47.621C65.3514 47.7988 65.6374 47.6972 65.5533 47.511L65.5617 47.5194Z" fill="url(#paint11_linear_654_13330)"/>
    <path d="M64.9222 54.6642C66.0244 51.9045 65.4018 47.2825 61.7249 47.1301C60.345 47.0708 58.3761 48.2221 59.4194 49.7967C59.9411 50.5839 60.6395 51.0072 61.4977 51.3458C62.1876 51.6167 62.8355 51.8707 63.4245 52.3278C64.3837 53.0728 64.4931 54.4187 64.7034 55.5277C64.7203 55.6208 64.8296 55.6208 64.8549 55.5277C65.3008 54.1563 65.2167 52.5987 64.0471 51.5744C63.5086 51.1003 62.9617 50.8294 62.2886 50.5839C61.7417 50.3808 59.066 49.6697 60.3197 48.5269C61.8258 47.164 63.6769 48.857 64.2238 50.2115C64.7287 51.4559 64.897 53.2505 64.7203 54.588C64.7034 54.6981 64.8717 54.7658 64.9138 54.6473L64.9222 54.6642Z" fill="url(#paint12_linear_654_13330)"/>
    <path d="M64.9302 54.4612C65.0565 52.819 64.7367 51.5068 63.6934 50.1862C62.9698 49.272 61.918 48.163 60.698 48.0276C59.5284 47.9006 59.1919 49.7207 60.3614 49.8476C61.1692 49.9323 61.9096 50.7873 62.4649 51.3206C63.3232 52.1502 64.3917 53.293 64.6947 54.4866C64.7199 54.6051 64.9218 54.5882 64.9302 54.4697V54.4612Z" fill="url(#paint13_linear_654_13330)"/>
    <path d="M62.3299 50.635C61.7914 49.9747 60.9416 49.2975 60.3021 50.2032C60.2516 50.271 60.2432 50.3895 60.3189 50.4487C60.5882 50.635 60.7312 50.7874 61.0846 50.8381C61.4464 50.8805 61.8082 50.8128 62.1784 50.8635C62.2962 50.8805 62.3972 50.745 62.3215 50.6434L62.3299 50.635Z" fill="url(#paint14_linear_654_13330)"/>
    <path d="M72.9575 7.885C71.6281 4.46501 67.6735 1.8323 63.9965 2.2471C60.2691 2.6619 57.9048 5.90412 57.2821 9.40029C56.6763 12.7864 58.6873 16.2403 62.0192 17.1715C65.3344 18.1026 67.7576 16.909 67.8333 13.3197C67.8333 13.2182 67.6819 13.1928 67.6398 13.2859C66.4703 16.2995 63.9545 16.8498 61.2031 15.4191C58.376 13.9546 58.0562 10.6701 59.007 7.96966C61.3125 1.42597 69.6928 2.89893 72.7724 7.96966C72.8397 8.07971 73.008 7.98659 72.9575 7.86807V7.885Z" fill="url(#paint15_linear_654_13330)"/>
    <path d="M67.825 13.3367C66.2684 17.1461 62.154 18.4328 59.6129 14.7334C57.4674 11.6182 58.3172 8.29133 59.268 4.98985C59.3437 4.72743 59.0155 4.60045 58.8304 4.71049C54.9768 6.88608 55.1787 11.6775 57.1308 15.2244C58.2162 17.2053 60.3702 18.4328 62.4905 18.9153C62.9954 19.0338 63.5086 18.9661 64.0051 18.9153C64.308 18.8814 65.1914 17.8741 64.5772 17.8741C63.273 17.8825 62.6841 18.5259 61.2789 17.5101C60.4543 16.9175 60.0589 15.8763 59.4278 15.1144C59.0576 14.6572 58.3003 14.8858 58.3424 15.5122C58.4518 17.0529 59.5036 18.3143 60.7488 19.1354C62.398 20.2105 63.8788 18.9153 65.3345 18.1788C65.4859 18.1026 65.4523 17.8571 65.2672 17.8741C62.0362 18.0349 58.8977 15.9186 57.8292 12.9134C57.013 10.6108 57.2402 6.92841 59.1502 5.16762C59.0408 5.01525 58.9314 4.86287 58.8304 4.70203C54.6571 7.6395 56.3315 14.1663 59.3689 17.3069C62.785 20.8454 68.0775 17.8402 68.0186 13.3621C68.0186 13.2605 67.8671 13.2351 67.825 13.3282V13.3367Z" fill="url(#paint16_linear_654_13330)"/>
    <path d="M57.7195 7.49556C58.3506 6.71675 58.9059 5.88716 59.5201 5.09141C60.4625 3.87241 62.2379 3.46607 63.6514 3.16978C65.0145 2.88196 66.3776 2.91583 67.6649 3.483C68.5316 3.86394 69.1374 4.41419 70.0798 4.60889C70.2312 4.64275 70.3827 4.47345 70.3153 4.32954C69.1374 1.58677 65.4184 1.23123 62.8858 1.73915C59.5538 2.40791 58.0309 4.13483 57.425 7.36858C57.3914 7.52942 57.6017 7.63101 57.7111 7.50403L57.7195 7.49556Z" fill="url(#paint17_linear_654_13330)"/>
    <path d="M70.0796 3.84715C68.0181 1.1975 62.0862 -0.334723 59.9406 2.99215C59.7639 3.2715 59.8902 3.77096 60.3109 3.70324C61.615 3.47467 62.6584 2.73819 64.0215 2.67047C66.0408 2.56888 68.1191 3.15299 69.9029 4.06725C70.0291 4.13497 70.1721 3.96566 70.0796 3.85561V3.84715Z" fill="url(#paint18_linear_654_13330)"/>
    <path d="M62.2972 10.6023C63.0292 9.89967 64.224 9.92506 65.1327 10.0944C66.4706 10.3399 66.9081 11.6266 67.0343 12.8117C67.2531 14.945 66.1172 16.9428 64.5269 18.238C64.4344 18.3142 64.5101 18.4581 64.6279 18.4242C67.6233 17.4846 68.776 14.1239 68.0272 11.2541C67.2615 8.33359 63.6939 8.34205 62.1037 10.433C61.9943 10.5684 62.1878 10.7208 62.3056 10.6023H62.2972Z" fill="url(#paint19_linear_654_13330)"/>
    <path d="M64.3331 14.0985C64.3331 14.0985 64.3668 14.1493 64.3836 14.1747C64.4594 14.3017 64.6192 14.3609 64.7623 14.3525C66.058 14.2255 65.6037 12.8118 65.1241 12.0838C64.8296 11.6266 64.4341 11.288 63.9713 11.0171C63.517 10.7547 63.3487 10.6447 63.6853 10.1283C63.8704 9.84046 63.9629 9.53572 64.0976 9.23096C64.1733 9.07012 64.0723 8.90081 63.9124 8.85849C62.1371 8.43522 61.2704 10.6616 62.4147 11.8891C62.6419 12.1261 63.0374 12.2615 63.3235 12.3885C63.6348 12.524 63.8788 12.7864 64.1228 13.015C64.2995 13.1843 64.594 13.4467 64.4005 13.6668C64.3079 13.7768 64.249 13.9715 64.3331 14.0985Z" fill="url(#paint20_linear_654_13330)"/>
    <path d="M63.9288 9.16319C62.9275 9.31557 61.4635 10.1621 61.5897 11.3726C61.657 12.0499 61.8926 12.5663 62.4143 13.0064C62.7593 13.2943 64.0803 13.5482 64.0887 14.0646C64.3074 13.8022 64.5346 13.5398 64.7534 13.2689C63.1379 13.3874 63.2809 9.9674 64.9385 10.6362C66.3773 11.2118 66.9663 12.6848 66.7475 14.1323C66.6297 14.9281 66.1754 15.6138 65.578 16.1217C64.6356 16.9344 63.4156 16.5873 62.3049 16.7058C62.0357 16.7312 61.9179 17.0783 62.1282 17.2645C64.3159 19.1353 67.2776 17.1037 67.9339 14.7164C68.565 12.4223 67.6226 9.70497 65.1741 9.00235C61.4046 7.92725 61.0344 14.6487 64.8712 14.7249C65.1657 14.7249 65.5107 14.4286 65.5275 14.1239C65.5696 13.4128 65.376 12.871 64.745 12.4731C64.3411 12.2192 63.9036 12.0329 63.4829 11.8213C62.4227 11.288 63.7437 9.93354 64.0803 9.45948C64.1644 9.34096 64.0971 9.12933 63.9204 9.15473L63.9288 9.16319Z" fill="url(#paint21_linear_654_13330)"/>
    <path d="M65.116 64.5182C58.2501 61.7923 50.9804 64.1287 44.2155 65.9996C37.8966 67.7434 31.2579 69.1064 24.7286 69.7243C18.7126 70.2915 12.2169 69.6397 7.00863 66.3467C3.01196 63.8155 1.07673 59.3543 2.6754 54.7915C4.36662 49.9493 10.3406 48.6626 14.7748 49.5176C18.3928 50.2118 22.9532 54.5968 21.4976 58.6771C20.2018 62.3087 15.5573 63.5192 12.1412 62.5711C8.84289 61.6569 7.55554 56.1036 11.4344 54.9185C14.312 54.0381 16.0705 55.7481 16.3314 58.4993C16.4492 59.7183 14.5728 59.8707 14.4551 58.6602C14.3877 57.9152 14.4551 57.221 13.681 56.7385C11.426 55.3333 10.4247 58.6602 11.2325 60.0654C12.116 61.6061 14.5981 61.5553 16.079 61.386C18.1825 61.149 20.1093 59.9553 20.5721 57.7544C21.1358 55.0455 18.4601 52.3366 16.3314 51.2361C11.9813 48.9758 5.70445 50.7789 3.64301 55.2656C1.20294 60.5733 6.05784 64.9922 10.349 66.9223C19.6044 71.0872 30.9466 67.9635 40.3703 66.0758C48.5151 64.442 57.089 60.6834 65.217 64.3234C65.3432 64.3827 65.2506 64.5689 65.1244 64.5182H65.116Z" fill="url(#paint22_linear_654_13330)"/>
    <path d="M55.7686 63.2313C51.3765 63.3414 47.2621 66.5582 43.6019 68.7169C40.3457 70.6385 37.0053 72.0353 33.2022 72.2808C26.7234 72.704 24.3254 66.3296 27.7499 61.3266C29.4832 58.7955 32.8236 57.6273 35.7432 58.3468C38.1665 58.9394 39.4622 61.97 38.5367 64.2302C37.6448 66.3974 35.4235 67.6248 33.4967 65.8556C32.1252 64.5943 32.0411 62.0039 34.0436 61.4198C35.1711 61.0896 35.718 62.6557 34.6494 63.1298C33.951 63.443 35.3814 66.9391 37.0895 64.1287C37.9561 62.7065 37.1652 60.7764 35.8947 59.9383C33.5388 58.3892 30.2573 60.4208 28.7848 62.2155C27.2451 64.0948 26.8917 66.846 27.9939 69.0131C29.0288 71.0448 31.7213 71.2311 33.6818 71.121C38.2506 70.8671 41.8182 68.3529 45.8064 66.4312C48.9953 64.899 52.1085 62.7911 55.7855 63.0536C55.878 63.0536 55.8696 63.2229 55.7771 63.2229L55.7686 63.2313Z" fill="url(#paint23_linear_654_13330)"/>
    <path d="M30.8623 68.5898C28.2623 69.5802 25.797 70.6299 23.9375 72.7886C22.9531 73.9314 22.3809 75.3028 22.0107 76.7503C21.1693 80.0518 25.3258 80.3227 24.7789 78.0794C24.3161 76.1747 24.2236 74.9726 24.8546 73.0594C25.41 71.3918 26.529 69.8003 28.1361 69.0723C28.2539 69.0215 28.3212 69.1739 28.2623 69.2585C26.8319 71.2733 25.1491 73.7875 25.7465 76.4286C25.9485 77.309 26.6636 78.7397 26.1672 79.6624C25.2753 81.3131 22.9026 81.4147 21.5479 80.1365C19.6295 78.3249 20.8411 75.235 21.9434 73.3049C23.988 69.7495 27.2779 69.4617 30.7949 68.4628C30.8875 68.4374 30.938 68.5728 30.8454 68.6067L30.8623 68.5898Z" fill="url(#paint24_linear_654_13330)"/>
    <path d="M28.1701 69.0806C26.3274 70.1642 25.3682 72.8223 24.8802 74.8032C24.4847 76.4285 25.8646 78.6126 24.3164 79.7554C24.2155 79.8316 24.0556 79.8231 23.963 79.7385C22.7093 78.4771 22.9449 75.3534 23.5339 73.7789C24.4006 71.434 25.7636 69.8171 28.0607 68.869C28.1953 68.8182 28.2963 69.0214 28.1785 69.0891L28.1701 69.0806Z" fill="url(#paint25_linear_654_13330)"/>
    <path d="M24.0389 79.6541C22.3813 78.943 23.3321 75.0405 24.4932 74.2702C24.7541 74.0924 25.2421 74.2448 25.2168 74.6173C25.1748 75.4299 24.6699 76.2511 24.468 77.0553C24.2576 77.8933 24.3502 78.7483 24.1819 79.5864C24.1651 79.6541 24.0978 79.688 24.0305 79.6626L24.0389 79.6541Z" fill="url(#paint26_linear_654_13330)"/>
    <path d="M28.8177 69.1317C23.8198 70.7825 17.7617 70.3677 13.8996 74.5665C13.2181 75.3029 11.4511 79.5017 14.2783 78.4436C16.0957 77.7663 17.5681 75.286 18.7966 73.8638C20.8328 71.502 23.2729 69.8089 26.344 69.174C26.4954 69.1402 26.5291 69.3518 26.4365 69.428C24.4088 71.1634 22.0949 72.0692 20.2354 74.1009C18.6367 75.8532 17.24 77.9526 15.2122 79.2393C12.789 80.78 10.8706 79.3409 11.4932 76.6066C12.9657 70.1222 23.4327 68.8185 28.7588 68.8185C28.9355 68.8185 29.0028 69.0725 28.8261 69.1317H28.8177Z" fill="url(#paint27_linear_654_13330)"/>
    <path d="M26.7731 69.4872C23.8702 70.156 21.304 71.485 18.906 73.2373C17.8542 74.0077 16.8529 74.8542 15.818 75.6584C14.7158 76.5219 14.0342 77.6139 13.0498 78.5451C12.7721 78.8075 12.2841 78.7906 12.1495 78.3758C11.3501 75.9547 15.2374 73.923 16.8277 72.8141C19.7642 70.7655 23.113 69.3602 26.6974 69.1401C26.8909 69.1317 26.975 69.4364 26.7731 69.4872Z" fill="url(#paint28_linear_654_13330)"/>
    <path d="M12.3347 78.3422C11.527 74.0418 17.1055 71.2736 20.3869 70.1562C20.8161 70.0123 21.0853 70.5795 20.7403 70.8504C17.8375 73.0598 14.0512 74.5751 12.9069 78.376C12.8143 78.6977 12.3936 78.6723 12.3347 78.3506V78.3422Z" fill="url(#paint29_linear_654_13330)"/>
    <path d="M20.3198 70.2404C17.1561 70.4351 14.0345 69.9526 10.8624 69.9357C8.05214 69.9188 4.39203 69.8849 2.28852 72.0351C2.44839 73.7959 3.12993 74.5324 4.34997 74.2276C5.21661 74.1599 6.07484 73.7959 6.88259 73.4827C8.32139 72.9155 9.72653 72.2552 11.1822 71.7219C13.8326 70.7484 16.626 69.7833 19.47 69.8341C19.6046 69.8341 19.714 70.0288 19.5625 70.105C15.7594 72.1113 11.2747 72.9663 7.27805 74.6001C5.88973 75.1673 0.513161 76.5217 0.580473 73.4827C0.639372 70.4436 3.94609 69.4193 6.44506 69.1061C11.0391 68.5304 15.8015 69.2839 20.345 69.9018C20.547 69.9272 20.5217 70.2404 20.3282 70.2574L20.3198 70.2404Z" fill="url(#paint30_linear_654_13330)"/>
    <path d="M20.1512 69.8684C16.5584 71.0366 12.6459 70.5456 8.94371 71.4853C7.05897 71.9593 5.35933 72.6027 3.71018 73.627C2.97816 74.0841 2.26296 74.101 1.48046 73.8386C1.41315 73.8132 1.3879 73.7286 1.39632 73.6693C1.99372 69.4282 7.52174 69.0811 10.8874 69.4959C11.1987 69.5382 11.2239 70.0123 10.9378 70.1054C9.26344 70.6472 7.54698 70.8842 5.83893 71.2821C4.75352 71.5361 3.71018 71.9424 2.78464 72.5604C2.74257 72.5857 1.0261 73.9656 2.12834 72.7974C2.47332 72.4334 2.91926 72.1709 3.33155 71.9085C5.40982 70.5879 7.88354 70.1647 10.2647 69.716C13.5798 69.098 16.7772 69.8599 20.1007 69.6991C20.2017 69.6991 20.2438 69.843 20.1428 69.8768L20.1512 69.8684Z" fill="url(#paint31_linear_654_13330)"/>
    <path d="M54.1778 63.3497C50.7448 63.7391 47.5727 64.3655 44.367 62.7741C42.5411 61.8683 40.8499 60.3445 40.9593 58.1689C41.0603 56.1203 43.4583 55.2992 44.855 56.5859C45.7974 57.4494 46.1423 59.481 46.8239 60.5392C48.0944 62.4947 51.6956 63.392 53.9085 63.2989C54.0011 63.2989 54.0347 63.4343 53.9422 63.4682C50.0969 64.8057 46.3275 62.6047 44.6951 59.0408C44.5605 58.7276 44.4511 58.4059 44.3586 58.0758C43.5929 58.1774 42.8272 58.2705 42.0615 58.3721C42.0447 59.0324 42.2382 59.5996 42.6421 60.0736C43.2647 60.9709 44.3586 61.555 45.3009 62.0291C47.8925 63.3582 51.3591 63.3412 54.1862 63.1635C54.2955 63.1635 54.3124 63.3243 54.203 63.3328L54.1778 63.3497Z" fill="url(#paint32_linear_654_13330)"/>
    <path d="M48.4979 63.0791C47.3368 62.4103 46.1251 62.0124 45.0145 61.2082C43.8618 60.3786 42.9699 59.2443 42.3977 57.9491C42.0107 57.0602 43.4579 56.3068 43.9038 57.1703C44.4255 58.1946 45.0229 59.1512 45.7633 60.0315C46.5542 60.9627 47.6144 62.1309 48.6325 62.7997C48.8008 62.9098 48.6746 63.1806 48.4979 63.0791Z" fill="url(#paint33_linear_654_13330)"/>
    <path d="M55.6339 63.1466C52.7984 63.9762 48.2801 62.8926 48.4988 59.2017C48.5745 57.8134 49.9124 55.9595 51.368 57.1531C52.0916 57.7541 52.4366 58.4906 52.6974 59.3879C52.8994 60.099 53.0845 60.7763 53.4799 61.4196C54.1194 62.4524 55.4404 62.6979 56.5174 63.0196C56.6015 63.045 56.5931 63.155 56.5174 63.1719C55.1123 63.4767 53.5893 63.2397 52.689 61.9699C52.2767 61.3773 52.058 60.8101 51.8813 60.1075C51.7382 59.5403 51.2923 56.7891 50.047 57.9319C48.5493 59.3118 50.047 61.3265 51.3259 62.0122C52.5123 62.6386 54.2709 62.9857 55.6003 62.9434C55.7181 62.9434 55.7601 63.1127 55.6423 63.1466H55.6339Z" fill="url(#paint34_linear_654_13330)"/>
    <path d="M55.431 63.1381C53.7987 63.1043 52.5281 62.6471 51.3249 61.4789C50.4919 60.6662 49.4991 59.498 49.4823 58.2706C49.4654 57.0854 51.3081 56.9246 51.3165 58.1182C51.3165 58.9309 52.0906 59.7605 52.5618 60.37C53.2938 61.3181 54.3203 62.4863 55.4731 62.918C55.5824 62.9603 55.5488 63.155 55.431 63.1466V63.1381Z" fill="url(#paint35_linear_654_13330)"/>
    <path d="M51.9059 60.1584C51.3085 59.5574 50.7195 58.6347 51.6787 58.0929C51.7544 58.0505 51.8638 58.0505 51.9143 58.1352C52.0741 58.423 52.2172 58.5839 52.2256 58.9479C52.2256 59.3203 52.1246 59.6674 52.1414 60.0484C52.1414 60.1669 51.9984 60.26 51.9059 60.1669V60.1584Z" fill="url(#paint36_linear_654_13330)"/>
    <path d="M8.57328 66.5668C5.32546 64.8907 3.11257 60.6749 3.88666 57.0348C4.66917 53.344 8.11051 51.2954 11.6276 51.016C15.0353 50.7451 18.2494 53.0985 18.8468 56.5269C19.4358 59.9384 18.0138 62.241 14.4631 61.9617C14.3621 61.9532 14.3537 61.7924 14.4463 61.767C17.5426 60.895 18.3336 58.4316 17.1977 55.5365C16.0281 52.5652 12.814 51.9133 10.0457 52.599C3.34816 54.2667 3.9708 62.7913 8.69108 66.3806C8.79204 66.4567 8.68266 66.6176 8.57328 66.5583V66.5668Z" fill="url(#paint37_linear_654_13330)"/>
    <path d="M14.4632 61.9615C18.3841 60.7764 20.0669 56.7892 16.6592 53.8772C13.7901 51.4222 10.416 51.9386 7.05883 52.565C6.78958 52.6158 6.69702 52.2772 6.82323 52.0995C9.35585 48.4594 14.0761 49.1366 17.3913 51.4392C19.2424 52.7259 20.2436 54.9946 20.5129 57.1702C20.5718 57.6866 20.4624 58.1945 20.3614 58.6939C20.3025 58.9902 19.2087 59.7775 19.276 59.1595C19.4106 57.8559 20.109 57.331 19.2424 55.8157C18.7375 54.9353 17.7531 54.4274 17.0547 53.7248C16.6424 53.31 16.9453 52.5735 17.5595 52.6751C19.0741 52.9375 20.2184 54.1142 20.8999 55.4432C21.8002 57.204 20.3698 58.55 19.4948 59.9383C19.4022 60.0822 19.1666 60.023 19.2003 59.8368C19.6799 56.6199 17.8961 53.2677 15.0269 51.8963C12.8309 50.8466 9.16234 50.7196 7.23552 52.455C7.09248 52.328 6.94944 52.2095 6.81482 52.0825C10.1299 48.1969 16.4236 50.5249 19.2339 53.8772C22.3976 57.6527 18.8974 62.6472 14.48 62.1393C14.379 62.1309 14.3706 61.97 14.4632 61.9446V61.9615Z" fill="url(#paint38_linear_654_13330)"/>
    <path d="M9.69289 51.2614C8.8599 51.8201 7.98484 52.2942 7.13502 52.8275C5.83926 53.6486 5.25869 55.3925 4.82116 56.7723C4.40046 58.1098 4.29949 59.4727 4.73702 60.8187C5.03151 61.7245 5.51111 62.3848 5.61208 63.3499C5.62891 63.5022 5.45222 63.6377 5.30918 63.5615C2.70924 62.1139 2.73448 58.3469 3.48333 55.8665C4.47619 52.5904 6.32727 51.2445 9.59192 50.9566C9.75179 50.9397 9.83593 51.1683 9.69289 51.2529V51.2614Z" fill="url(#paint39_linear_654_13330)"/>
    <path d="M4.85458 63.2822C2.43975 60.9543 1.5142 54.8677 5.01444 53.0477C5.30894 52.8953 5.78853 53.0731 5.67915 53.4794C5.31734 54.7661 4.49277 55.7312 4.29084 57.0941C3.98793 59.1004 4.36656 61.2506 5.09017 63.1129C5.14065 63.2484 4.96396 63.3754 4.86299 63.2738L4.85458 63.2822Z" fill="url(#paint40_linear_654_13330)"/>
    <path d="M12.318 56.1628C11.5523 56.8231 11.4598 58.0252 11.5355 58.9479C11.6449 60.3108 12.8733 60.878 14.0345 61.115C16.1211 61.5467 18.2162 60.6071 19.6466 59.1426C19.7308 59.0579 19.8654 59.1426 19.8149 59.2611C18.5865 62.1647 15.1535 62.9943 12.3853 61.9531C9.57502 60.9034 9.93683 57.3225 12.1581 55.9427C12.3096 55.8496 12.4358 56.0527 12.3096 56.1628H12.318Z" fill="url(#paint41_linear_654_13330)"/>
    <path d="M15.566 58.5413C15.566 58.5413 15.6165 58.5752 15.6418 58.6006C15.7596 58.6937 15.81 58.8545 15.7764 58.9984C15.524 60.2852 14.1693 59.6841 13.4962 59.1423C13.0755 58.7953 12.7726 58.372 12.5538 57.881C12.335 57.3985 12.2509 57.2207 11.704 57.5085C11.4011 57.6694 11.0898 57.7286 10.7785 57.8387C10.6102 57.8979 10.4503 57.7794 10.4251 57.6101C10.1811 55.7901 12.4697 55.1467 13.5719 56.408C13.7907 56.6535 13.8748 57.0683 13.9758 57.3646C14.0767 57.6863 14.3123 57.9572 14.5143 58.2281C14.6657 58.4228 14.8929 58.736 15.1285 58.5752C15.2463 58.4905 15.4398 58.4482 15.566 58.5498V58.5413Z" fill="url(#paint42_linear_654_13330)"/>
    <path d="M10.7276 57.6442C10.98 56.6622 11.9561 55.2823 13.1424 55.5194C13.8071 55.6548 14.2952 55.9426 14.6738 56.5098C14.9262 56.8823 15.044 58.2367 15.5573 58.2875C15.2796 58.4822 14.9935 58.6769 14.7074 58.8716C14.9851 57.2632 11.5858 57.077 12.0823 58.7954C12.5114 60.2853 13.9081 61.0303 15.3637 60.9541C16.1631 60.9118 16.8783 60.5224 17.4504 59.9806C18.3507 59.1171 18.132 57.8643 18.3507 56.7638C18.4096 56.4929 18.763 56.4167 18.9229 56.6453C20.5552 59.0155 18.2582 61.7837 15.8265 62.207C13.4958 62.6133 10.9043 61.3943 10.4499 58.8801C9.75999 55.003 16.4491 55.2908 16.1378 59.1425C16.1126 59.4388 15.776 59.752 15.4815 59.7351C14.7663 59.7097 14.2615 59.4557 13.9249 58.7954C13.7146 58.3722 13.58 57.9151 13.4117 57.4664C12.991 56.349 11.5185 57.5426 11.0137 57.8304C10.8875 57.8981 10.6855 57.8135 10.7276 57.6442Z" fill="url(#paint43_linear_654_13330)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_654_13330" x1="55.4277" y1="0.527941" x2="71.2219" y2="-0.445008" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint1_linear_654_13330" x1="62.0671" y1="25.4433" x2="73.3592" y2="24.3576" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint2_linear_654_13330" x1="72.6148" y1="20.9605" x2="82.3939" y2="18.2672" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint3_linear_654_13330" x1="73.2794" y1="23.3504" x2="80.4702" y2="19.7466" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint4_linear_654_13330" x1="78.8981" y1="23.5709" x2="82.0904" y2="21.6178" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint5_linear_654_13330" x1="73.1834" y1="11.7558" x2="82.9596" y2="10.3066" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint6_linear_654_13330" x1="73.7206" y1="12.4601" x2="82.2261" y2="11.1501" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint7_linear_654_13330" x1="75.3008" y1="12.6372" x2="82.2764" y2="11.0443" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint8_linear_654_13330" x1="74.9259" y1="0.550018" x2="80.7568" y2="0.116975" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint9_linear_654_13330" x1="74.8916" y1="1.40911" x2="79.7048" y2="1.096" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint10_linear_654_13330" x1="59.0407" y1="39.4744" x2="64.7821" y2="38.8757" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint11_linear_654_13330" x1="59.8459" y1="40.7993" x2="64.3091" y2="40.0644" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint12_linear_654_13330" x1="59.126" y1="47.1279" x2="64.0552" y2="46.3999" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint13_linear_654_13330" x1="59.6346" y1="48.0212" x2="63.7889" y2="47.3494" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint14_linear_654_13330" x1="60.2631" y1="49.7653" x2="61.725" y2="49.214" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint15_linear_654_13330" x1="57.1748" y1="2.20401" x2="69.3114" y2="-0.292886" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint16_linear_654_13330" x1="55.7666" y1="4.6708" x2="65.3248" y2="3.10448" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint17_linear_654_13330" x1="57.4214" y1="1.5437" x2="66.1696" y2="-2.19022" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint18_linear_654_13330" x1="59.8631" y1="1.09247" x2="65.4669" y2="-2.7274" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint19_linear_654_13330" x1="62.0713" y1="8.95184" x2="66.938" y2="8.32077" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint20_linear_654_13330" x1="61.8975" y1="8.80574" x2="64.8191" y2="8.41682" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint21_linear_654_13330" x1="61.5821" y1="8.88591" x2="66.7179" y2="8.15219" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint22_linear_654_13330" x1="2.11462" y1="49.2719" x2="39.0247" y2="26.7958" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint23_linear_654_13330" x1="26.1918" y1="58.1374" x2="46.4368" y2="49.7033" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint24_linear_654_13330" x1="20.5391" y1="68.4596" x2="28.6167" y2="67.1335" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint25_linear_654_13330" x1="23.0555" y1="68.8609" x2="27.1646" y2="68.4745" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint26_linear_654_13330" x1="23.1474" y1="74.1961" x2="24.7966" y2="74.072" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint27_linear_654_13330" x1="11.3747" y1="68.8185" x2="24.1603" y2="64.7779" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint28_linear_654_13330" x1="12.0424" y1="69.1399" x2="22.9031" y2="65.7911" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint29_linear_654_13330" x1="12.2557" y1="70.1332" x2="18.9046" y2="68.7839" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint30_linear_654_13330" x1="0.579845" y1="68.9007" x2="12.2035" y2="61.813" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint31_linear_654_13330" x1="1.39476" y1="69.3634" x2="10.5335" y2="62.0044" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint32_linear_654_13330" x1="40.9543" y1="55.969" x2="50.5455" y2="52.7489" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint33_linear_654_13330" x1="42.3326" y1="56.7893" x2="47.2441" y2="55.8018" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint34_linear_654_13330" x1="48.4912" y1="56.7702" x2="54.6095" y2="55.2878" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint35_linear_654_13330" x1="49.4822" y1="57.2985" x2="54.1368" y2="56.3394" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint36_linear_654_13330" x1="51.1713" y1="58.0651" x2="52.0078" y2="57.9831" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint37_linear_654_13330" x1="3.73166" y1="50.9948" x2="15.4971" y2="48.7041" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint38_linear_654_13330" x1="6.76757" y1="49.5408" x2="17.7517" y2="47.0469" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint39_linear_654_13330" x1="3.04445" y1="50.9557" x2="8.36917" y2="50.3919" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint40_linear_654_13330" x1="2.70716" y1="52.9936" x2="5.09273" y2="52.8562" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint41_linear_654_13330" x1="10.3845" y1="55.9189" x2="17.3588" y2="53.8925" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint42_linear_654_13330" x1="10.407" y1="55.7523" x2="14.4304" y2="54.6796" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <linearGradient id="paint43_linear_654_13330" x1="10.4" y1="55.4925" x2="17.1877" y2="53.6886" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF99E"/>
      <stop offset="1" stopColor="#B37839"/>
    </linearGradient>
    <clipPath id="clip0_654_13330">
      <rect width="85.1753" height="80.4713" fill="white" transform="matrix(-1 0 0 -1 85.7468 81)"/>
    </clipPath>
  </defs>
</svg>
    )
}