export const BannerBackgroundSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="611"
      viewBox="0 0 1128 611"
      fill="none"
    >
      <path
        d="M1127.18 15.2199L1101.63 14.4801L1101.37 0.716553L229.781 20.7166L42.7414 7.00001L41.3857 20.7098L15.7733 20.7098L19.8663 238.428L0.494365 594.608L0.427643 595.28L23.9758 597.02L24.2347 610.79L211.828 604.79L1081.99 594.439L1082.88 594.5L1084.23 580.79H1109.84L1109.75 253.072L1127.12 15.8916L1127.19 15.2199H1127.18ZM1099.64 2.05328L1099.87 14.3592L249.476 22.0533L1099.64 2.05328ZM1099.89 15.7026L1107.98 253.004L1082.58 579.433L211.873 603.433L25.7071 595.784L21.6219 238.482L43.0244 22.0533L229.736 22.0533L1099.9 15.7026H1099.89ZM44.3646 8.45764L210.032 20.7098L43.1579 20.7098L44.3735 8.45764H44.3646ZM17.5619 22.0533L41.2523 22.0533L21.3478 223.435L17.5619 22.0533ZM2.31788 594.057L20.1492 253.475L23.9504 595.669L2.31788 594.057ZM25.9726 609.44L25.7413 597.134L192.133 603.44L25.9726 609.44ZM1081.24 593.035L231.577 604.783L1082.46 580.783L1081.24 593.035ZM1108.05 579.44H1084.36L1108.26 268.051L1108.05 579.44ZM1109.46 238.025L1101.66 15.8303L1125.29 16.4424L1109.46 238.025Z"
        fill="#D3BF8C"
      />
    </svg>
  );
};
