export const BackgroundSvg2: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="190"
      viewBox="0 0 140 190"
      fill="none"
    >
      <g opacity="0.1" clipPath="url(#clip0_818_4477)">
        <path
          d="M-4.92677 141.323C-4.92677 141.323 12.0119 128.225 7.86475 109.044C3.71758 89.8557 -6.83525 77.7874 -12.5277 71.9083C-18.2202 66.0292 -19.3032 63.0996 -19.3032 63.0996C-19.3032 63.0996 -26.1909 72.7727 -28.872 85.8308C-31.5532 98.8888 -27.7956 127.816 -4.92016 141.323H-4.92677Z"
          fill="url(#paint0_linear_818_4477)"
        />
        <path
          d="M-4.92677 141.323C-4.92677 141.323 12.0119 128.225 7.86475 109.044C3.71758 89.8557 -6.83525 77.7874 -12.5277 71.9083C-18.2202 66.0292 -19.3032 63.0996 -19.3032 63.0996C-19.3032 63.0996 -26.1909 72.7727 -28.872 85.8308C-31.5532 98.8888 -27.7956 127.816 -4.92016 141.323H-4.92677Z"
          stroke="url(#paint1_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M-16.2391 67.8636C-16.2391 67.8636 -2.61552 88.7407 1.72316 102.188C6.06184 115.635 5.28259 129.604 -2.5825 139.04"
          stroke="url(#paint2_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M29.6905 78.0315C29.6905 78.0315 42.2971 68.2858 39.2066 54.0071C36.1226 39.7283 28.2641 30.748 24.0311 26.3733C19.7915 21.9987 18.9858 19.8212 18.9858 19.8212C18.9858 19.8212 13.8613 27.02 11.8669 36.7393C9.8726 46.4586 12.666 67.9889 29.6905 78.0381V78.0315Z"
          fill="url(#paint3_linear_818_4477)"
        />
        <path
          d="M29.6905 78.0315C29.6905 78.0315 42.2971 68.2858 39.2066 54.0071C36.1226 39.7283 28.2641 30.748 24.0311 26.3733C19.7915 21.9987 18.9858 19.8212 18.9858 19.8212C18.9858 19.8212 13.8613 27.02 11.8669 36.7393C9.8726 46.4586 12.666 67.9889 29.6905 78.0381V78.0315Z"
          stroke="url(#paint4_linear_818_4477)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M29.6902 78.0312C29.6902 78.0312 35.9505 63.7657 29.0166 47.5141C22.0826 31.2624 18.9854 19.8144 18.9854 19.8144"
          stroke="url(#paint5_linear_818_4477)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M18.4377 20.6063C18.4377 20.6063 17.5991 37.9533 22.4264 52.4761C27.2538 67.0056 28.9443 77.6487 28.9443 77.6487"
          stroke="url(#paint6_linear_818_4477)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M21.2706 23.3645C21.2706 23.3645 31.4074 38.9035 34.6367 48.9131C37.8659 58.9228 37.2848 69.3217 31.4338 76.3357"
          stroke="url(#paint7_linear_818_4477)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M15.5386 25.9116C15.5386 25.9116 13.2801 45.8847 17.1961 58.824C21.1122 71.7699 25.0811 74.6533 25.0811 74.6533"
          stroke="url(#paint8_linear_818_4477)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.62957 201.598C-4.62957 201.598 -17.4871 164.496 7.2308 126.655C31.9553 88.8133 41.2865 52.6018 41.9072 39.9726"
          stroke="url(#paint9_linear_818_4477)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M58.0602 134.335C39.2197 164.753 5.89041 158.887 -0.231285 163.308C-6.35959 167.722 -9.01431 175.489 -7.64072 180.998L-7.83222 180.886C-7.83222 180.886 -22.2549 151.009 -1.74355 130.376C18.7678 109.737 34.0291 118.657 62.8414 98.9088C62.8414 98.9088 70.3499 114.507 58.0668 134.335H58.0602Z"
          fill="url(#paint10_linear_818_4477)"
        />
        <path
          d="M-8.30749 179.738C-8.30749 179.738 -14.3565 157.126 -0.713151 145.598C12.9368 134.071 39.8604 124.556 50.968 115.708C62.0821 106.86 62.835 98.9088 62.835 98.9088"
          stroke="url(#paint11_linear_818_4477)"
          strokeMiterlimit="10"
        />
        <path
          d="M-7.0596 172.783C-7.0596 172.783 -7.59451 157.627 4.11398 151.016C15.8291 144.404 43.7102 131.089 53.4243 123.045C63.1319 115.009 64.5781 104.788 64.5781 104.788"
          stroke="url(#paint12_linear_818_4477)"
          strokeMiterlimit="10"
        />
        <path
          d="M-0.013779 162.912C-0.013779 162.912 7.83151 153.549 32.3513 145.018C56.8711 136.486 63.1777 123.699 63.1777 123.699"
          stroke="url(#paint13_linear_818_4477)"
          strokeMiterlimit="10"
        />
        <path
          d="M-12.8243 156.499C-12.8243 156.499 -6.79504 138.551 6.53138 129.795C17.3351 122.702 37.4502 114.263 40.9238 110.304"
          stroke="url(#paint14_linear_818_4477)"
          strokeMiterlimit="10"
        />
        <path
          d="M58.0602 134.335C39.2197 164.753 5.89041 158.887 -0.231286 163.308C-6.35959 167.722 -9.01431 175.489 -7.64072 180.998L-7.83223 180.886C-7.83223 180.886 -22.2549 151.009 -1.74355 130.376C18.7678 109.737 34.0291 118.657 62.8414 98.9088C62.8414 98.9088 70.3499 114.507 58.0668 134.335H58.0602Z"
          stroke="url(#paint15_linear_818_4477)"
          strokeMiterlimit="10"
        />
        <path
          d="M25.1865 95.3655C25.1865 95.3655 25.1733 73.9672 42.8912 65.495C60.6091 57.0228 76.6233 57.9597 84.7591 58.8571C92.9016 59.7545 95.8799 58.8175 95.8799 58.8175C95.8799 58.8175 92.4459 70.1798 83.762 80.295C75.0714 90.4168 49.8846 105.164 25.1931 95.3721L25.1865 95.3655Z"
          fill="url(#paint16_linear_818_4477)"
        />
        <path
          d="M25.1865 95.3655C25.1865 95.3655 25.1733 73.9672 42.8912 65.495C60.6091 57.0228 76.6233 57.9597 84.7591 58.8571C92.9016 59.7545 95.8799 58.8175 95.8799 58.8175C95.8799 58.8175 92.4459 70.1798 83.762 80.295C75.0714 90.4168 49.8846 105.164 25.1931 95.3721L25.1865 95.3655Z"
          stroke="url(#paint17_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M25.1865 95.3655C25.1865 95.3655 35.1978 76.9826 58.1789 70.9583C81.1601 64.9407 95.8799 58.8109 95.8799 58.8109"
          stroke="url(#paint18_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M95.4971 60.0382C95.4971 60.0382 77.7527 75.2077 58.331 82.0369C38.9093 88.8662 26.2037 95.8406 26.2037 95.8406"
          stroke="url(#paint19_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M90.2334 59.2992C90.2334 59.2992 65.3702 61.3314 52.0768 66.1416C38.7834 70.9518 28.2042 80.1234 25.5627 92.1125"
          stroke="url(#paint20_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M92.2344 67.4813C92.2344 67.4813 72.8655 86.3195 55.8806 92.8188C38.8957 99.3115 32.5693 97.4772 32.5693 97.4772"
          stroke="url(#paint21_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M33.2826 72.0665C33.7647 72.7725 18.4638 55.927 34.4119 31.7575C51.1723 8.5842 74.6553 4.2557 84.7657 4.38766C95.3713 4.2887 99.327 3.77402 99.3534 3.66185C99.3864 3.72783 92.5515 16.0601 84.1185 27.7457C74.3911 40.0383 56.8845 57.0752 33.2892 72.0665H33.2826Z"
          fill="url(#paint22_linear_818_4477)"
        />
        <path
          d="M33.2826 72.0665C33.7647 72.7725 18.4638 55.9271 34.4119 31.7575C51.1723 8.5842 74.6553 4.2557 84.7657 4.38767C95.3713 4.28869 99.327 3.77402 99.3534 3.66185C99.3864 3.72783 92.5515 16.0601 84.1185 27.7457C74.3911 40.0383 56.8845 57.0752 33.2892 72.0665H33.2826Z"
          stroke="url(#paint23_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M33.2826 72.0667C33.3949 73.3072 28.2308 47.2373 53.3845 26.7627C78.1223 8.25449 99.5977 4.07117 99.3534 3.66867"
          stroke="url(#paint24_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M98.6602 5.02784C98.1186 4.96186 73.9554 21.0485 56.9375 37.5575C38.7441 54.2182 34.4715 71.8621 34.3328 71.4926"
          stroke="url(#paint25_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M91.9041 4.3088C92.0031 3.1409 58.522 10.1285 44.9777 25.6279C30.8258 39.3854 26.1635 58.5997 31.5852 69.4275"
          stroke="url(#paint26_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7402 13.2762C92.7431 13.1046 70.4884 34.4831 58.4761 48.7618C45.3676 63.034 40.5667 67.6 40.2299 67.3823"
          stroke="url(#paint27_linear_818_4477)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M-11.2329 61.9121C-10.8697 88.7738 12.8577 97.8795 15.0898 103.086C17.3284 108.298 16.0341 114.322 13.0162 117.338H13.1879C13.1879 117.338 34.036 103.673 28.8124 82.4658C23.5954 61.2523 10.3152 61.1005 -0.600815 37.2542C-0.600815 37.2542 -11.4706 44.4002 -11.2329 61.9121Z"
          fill="url(#paint28_linear_818_4477)"
        />
        <path
          d="M13.9343 116.784C13.9343 116.784 26.5805 104.564 22.2484 91.8685C17.923 79.1733 4.26635 62.6446 0.535216 52.6548C-3.19592 42.6649 -0.607233 37.2543 -0.607233 37.2543"
          stroke="url(#paint29_linear_818_4477)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M15.8221 111.822C15.8221 111.822 22.0296 102.267 17.0504 93.4917C12.0645 84.7093 -0.733594 65.3631 -3.87699 56.4356C-7.02039 47.5081 -3.98925 40.3687 -3.98925 40.3687"
          stroke="url(#paint30_linear_818_4477)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M15.0967 102.749C15.0967 102.749 13.6703 93.6895 1.18252 78.7246C-11.3052 63.7596 -10.4203 53.0902 -10.4203 53.0902"
          stroke="url(#paint31_linear_818_4477)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M25.8333 103.567C25.8333 103.567 28.8974 89.6777 23.7003 78.8961C19.4871 70.1533 9.79933 56.9501 9.09933 53.0637"
          stroke="url(#paint32_linear_818_4477)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M-11.2329 61.9121C-10.8697 88.7738 12.8577 97.8795 15.0898 103.086C17.3284 108.298 16.0341 114.322 13.0162 117.338H13.1879C13.1879 117.338 34.036 103.673 28.8124 82.4658C23.5954 61.2523 10.3152 61.1005 -0.600815 37.2543C-0.600815 37.2543 -11.4706 44.4002 -11.2329 61.9121Z"
          stroke="url(#paint33_linear_818_4477)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M14.1123 159.719C14.0066 159.534 30.549 158.01 36.2745 145.651C41.8745 133.088 39.9 121.699 38.5264 115.86C37.1594 110.033 37.6679 107.863 37.6679 107.863C37.6679 107.876 28.7991 110.542 21.4292 117.107C13.967 123.52 4.70848 142.913 14.1123 159.725V159.719Z"
          fill="url(#paint34_linear_818_4477)"
        />
        <path
          d="M14.1123 159.719C14.0066 159.534 30.549 158.01 36.2745 145.651C41.8745 133.088 39.9 121.699 38.5264 115.86C37.1594 110.033 37.6679 107.863 37.6679 107.863C37.6679 107.876 28.7991 110.542 21.4292 117.107C13.967 123.52 4.70848 142.913 14.1123 159.725V159.719Z"
          stroke="url(#paint35_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M14.1125 159.719C14.0069 159.54 27.4984 151.154 30.7871 134.981C33.9503 118.611 37.6814 107.869 37.6748 107.856"
          stroke="url(#paint36_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M36.7178 108.16C36.7178 108.16 25.9206 121.185 21.9583 135.252C17.9697 149.274 13.664 159.02 13.6706 159.039"
          stroke="url(#paint37_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M37.7334 111.934C37.7532 111.973 38.0438 129.776 35.0985 139.191C32.1862 148.66 25.5296 156.235 16.5617 159.158"
          stroke="url(#paint38_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M30.998 110.667C30.998 110.667 17.3877 124.952 13.7094 137.601C10.0046 150.211 11.9528 154.671 11.9396 154.645"
          stroke="url(#paint39_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M65.0142 137.74C65.0142 137.74 78.1227 137.944 81.9066 127.948C85.6708 117.912 83.6963 108.589 82.501 103.95C81.2859 99.285 81.55 97.4705 81.55 97.4705C81.55 97.4705 75.4217 100.262 70.1255 105.474C64.9151 110.825 57.6312 124.603 65.0076 137.74H65.0142Z"
          fill="url(#paint40_linear_818_4477)"
        />
        <path
          d="M65.0142 137.74C65.0142 137.74 78.1227 137.944 81.9066 127.948C85.6708 117.912 83.6963 108.589 82.501 103.95C81.2859 99.285 81.55 97.4705 81.55 97.4705C81.55 97.4705 75.4217 100.262 70.1255 105.474C64.9151 110.825 57.6312 124.603 65.0076 137.74H65.0142Z"
          stroke="url(#paint41_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M65.0149 137.74C65.0149 137.74 75.6866 132.56 77.5489 119.548C79.4376 106.583 81.5442 97.4639 81.5508 97.4705"
          stroke="url(#paint42_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M80.8894 97.7741C80.9357 97.8533 73.7574 108.991 70.9441 119.687C68.2036 130.502 64.6507 137.179 64.664 137.192"
          stroke="url(#paint43_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M81.7748 100.796C81.7946 100.829 82.7653 115.352 80.8569 122.841C79.0012 130.409 74.1276 136.189 66.9757 137.555"
          stroke="url(#paint44_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M76.9078 100.097C76.9804 100.222 67.5436 112.013 64.6512 121.172C61.8314 130.455 63.2776 133.761 63.2908 133.788"
          stroke="url(#paint45_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M-7.04633 202.469C-6.83501 202.819 -4.55672 167.623 28.2178 155.502C44.6348 149.485 59.0706 143.474 69.5574 137.106C80.1301 130.877 86.7405 124.279 89.4414 120.366"
          stroke="url(#paint46_linear_818_4477)"
          strokeWidth="2.29"
          strokeMiterlimit="10"
        />
        <path
          d="M59.374 181.011C32.4702 189.068 14.0985 174.723 8.50513 175.528C2.98438 176.445 -1.32128 181.249 -2.33166 185.736C-2.35807 185.69 -2.38448 185.643 -2.4109 185.597C-2.31844 185.756 -1.43354 158.769 20.6825 154.196C43.1155 150.138 49.8315 161.982 78.8683 164.186C78.9146 164.265 77.0061 175.924 59.3674 181.011H59.374Z"
          fill="url(#paint47_linear_818_4477)"
        />
        <path
          d="M-2.30545 184.627C-2.30545 184.627 1.60399 166.119 15.1087 163.915C28.4483 161.428 51.1257 167.623 62.9398 167.9C74.7144 168.112 78.8813 164.212 78.8681 164.186"
          stroke="url(#paint48_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M0.838127 179.936C0.838127 179.936 5.81737 168.95 16.0532 169.042C26.0249 168.706 50.7626 172.704 61.3089 172.922C71.7296 172.922 77.4419 168.178 77.4287 168.151"
          stroke="url(#paint49_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M8.79546 175.337C8.84829 175.423 17.0238 171.272 37.1124 176.155C57.3068 181.209 67.8662 177.732 67.7936 177.613"
          stroke="url(#paint50_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M3.19623 166.865C3.1302 166.759 13.7094 156.783 26.4151 157.06C36.5519 157.02 54.1906 161.098 58.417 160.53"
          stroke="url(#paint51_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M59.374 181.011C32.4702 189.068 14.0985 174.723 8.50514 175.528C2.98438 176.445 -1.32128 181.249 -2.33166 185.736C-2.35807 185.69 -2.38448 185.643 -2.4109 185.597C-2.31845 185.756 -1.43354 158.769 20.6825 154.196C43.1155 150.138 49.8315 161.982 78.8683 164.186C78.9146 164.265 77.0061 175.924 59.3674 181.011H59.374Z"
          stroke="url(#paint52_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M54.2163 145.427C54.2163 145.427 63.7323 133.556 79.6342 136.407C95.5493 139.277 106.155 146.713 111.438 150.857C116.701 154.968 119.125 155.654 119.118 155.654C119.131 155.68 112.31 161.494 101.81 163.328C91.3691 165.255 66.8691 161.546 54.2097 145.42L54.2163 145.427Z"
          fill="url(#paint53_linear_818_4477)"
        />
        <path
          d="M54.2163 145.427C54.2163 145.427 63.7323 133.556 79.6342 136.407C95.5493 139.277 106.155 146.713 111.438 150.857C116.701 154.968 119.125 155.654 119.118 155.654C119.131 155.68 112.31 161.494 101.81 163.328C91.3691 165.255 66.8691 161.546 54.2097 145.42L54.2163 145.427Z"
          stroke="url(#paint54_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M54.2163 145.427C54.2163 145.427 69.3521 139.66 87.9417 146.41C106.591 153.252 119.131 155.68 119.118 155.654"
          stroke="url(#paint55_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M118.372 156.281C118.471 156.453 99.9537 157.95 83.233 152.823C66.6179 147.875 54.6915 146.126 54.7047 146.139"
          stroke="url(#paint56_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M115.038 153.522C115.038 153.522 97.0626 143.981 85.7503 140.867C74.4579 137.792 63.0862 138.294 55.9277 143.79"
          stroke="url(#paint57_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M113.121 159.514C113.254 159.732 91.6658 162.035 76.7347 157.706C61.9356 153.602 58.396 149.848 58.3894 149.834"
          stroke="url(#paint58_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M70.1194 135.945C70.0269 136.361 67.1609 120.703 87.5071 111.855C107.748 103.534 123.524 107.374 130.049 111.181C136.851 114.784 139.439 115.609 139.492 115.53C139.532 115.649 132.116 124.226 122.487 129.38C112.001 134.83 93.1534 138.03 70.1194 135.945Z"
          fill="url(#paint59_linear_818_4477)"
        />
        <path
          d="M70.1194 135.945C70.0269 136.361 67.1609 120.703 87.5071 111.855C107.748 103.534 123.524 107.374 130.049 111.181C136.851 114.784 139.439 115.609 139.492 115.53C139.532 115.649 132.116 124.226 122.487 129.38C112.001 134.83 93.1534 138.03 70.1194 135.945Z"
          stroke="url(#paint60_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M70.1188 135.945C69.5509 136.519 77.7792 120.142 102.24 116.341C125.518 113.412 139.412 115.734 139.498 115.536"
          stroke="url(#paint61_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M138.712 116.493C138.553 116.539 118.815 121.851 100.648 125.157C81.8732 128.502 70.977 136.255 71.1025 136.083"
          stroke="url(#paint62_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M134.624 113.576C135.053 112.712 111.96 107.288 96.9825 112.112C82.4344 115.84 71.0098 125.051 70.0919 133.675"
          stroke="url(#paint63_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M133.323 121.752C133.019 121.746 111.683 129.881 97.3393 133.081C82.5534 136.321 77.0591 136.519 76.9732 136.334"
          stroke="url(#paint64_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M43.7166 110.621C32.4176 126.358 44.5751 141.441 43.8289 145.354C43.0562 149.221 39.5034 152.032 36.1421 152.5C36.1817 152.527 36.2213 152.546 36.2543 152.573C36.1487 152.395 56.6072 153.873 62.4515 139.851C68.0779 125.467 59.0043 119.641 61.3685 100.473C61.3619 100.459 51.0534 100.321 43.7166 110.627V110.621Z"
          fill="url(#paint65_linear_818_4477)"
        />
        <path
          d="M37.0068 152.56C36.9606 152.48 51.0531 151.082 53.7408 142.2C56.3955 133.266 54.1304 117.833 55.7616 110.271C57.4059 102.729 61.3682 100.459 61.3682 100.459"
          stroke="url(#paint66_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M40.4742 150.554C40.4742 150.554 48.9601 147.875 49.429 140.834C49.9044 133.807 49.5148 117.239 51.1195 110.588C52.7573 103.996 57.7695 100.928 57.7695 100.921"
          stroke="url(#paint67_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M43.9873 145.163C43.9873 145.163 47.0448 139.541 44.9911 125.645C42.9307 111.742 48.042 105.791 48.042 105.791"
          stroke="url(#paint68_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M51.0064 150.243C51.0064 150.243 59.3469 143.955 60.4762 135.575C61.4073 128.812 60.3573 116.869 61.4932 114.223"
          stroke="url(#paint69_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M43.7166 110.621C32.4176 126.358 44.5751 141.441 43.8289 145.354C43.0562 149.221 39.5034 152.032 36.1421 152.5C36.1817 152.527 36.2213 152.546 36.2543 152.573C36.1487 152.395 56.6072 153.873 62.4515 139.851C68.0779 125.467 59.0043 119.641 61.3685 100.473C61.3619 100.459 51.0534 100.321 43.7166 110.627V110.621Z"
          stroke="url(#paint70_linear_818_4477)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M36.7374 0.237367C35.8001 0.237367 35.0402 0.996591 35.0402 1.93314C35.0402 2.86968 35.8001 3.62891 36.7374 3.62891C37.6747 3.62891 38.4346 2.86968 38.4346 1.93314C38.4346 0.996591 37.6747 0.237367 36.7374 0.237367Z"
          fill="url(#paint71_radial_818_4477)"
        />
        <path
          d="M88.8145 38.1319C87.8772 38.1319 87.1174 38.8911 87.1174 39.8277C87.1174 40.7642 87.8772 41.5234 88.8145 41.5234C89.7519 41.5234 90.5117 40.7642 90.5117 39.8277C90.5117 38.8911 89.7519 38.1319 88.8145 38.1319Z"
          fill="url(#paint72_radial_818_4477)"
        />
        <path
          d="M107.489 71.4992C105.615 71.4992 104.095 73.0176 104.095 74.8907C104.095 76.7638 105.615 78.2822 107.489 78.2822C109.364 78.2822 110.884 76.7638 110.884 74.8907C110.884 73.0176 109.364 71.4992 107.489 71.4992Z"
          fill="url(#paint73_radial_818_4477)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_818_4477"
          x1="11.1052"
          y1="124.656"
          x2="-35.7894"
          y2="80.6597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_818_4477"
          x1="11.3326"
          y1="124.752"
          x2="-35.9424"
          y2="80.3982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_818_4477"
          x1="12.2559"
          y1="123.545"
          x2="-30.7297"
          y2="83.2164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_818_4477"
          x1="41.6208"
          y1="65.6183"
          x2="6.71816"
          y2="32.8727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_818_4477"
          x1="41.796"
          y1="65.692"
          x2="6.60937"
          y2="32.6799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_818_4477"
          x1="41.889"
          y1="65.2546"
          x2="6.96229"
          y2="32.4864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_818_4477"
          x1="40.8202"
          y1="65.1305"
          x2="6.59153"
          y2="33.0173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_818_4477"
          x1="42.477"
          y1="64.7924"
          x2="10.4869"
          y2="34.7794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_818_4477"
          x1="35.0253"
          y1="64.0714"
          x2="5.50967"
          y2="36.3799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_818_4477"
          x1="-16.5987"
          y1="125.436"
          x2="52.9101"
          y2="116.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_818_4477"
          x1="-14.8391"
          y1="145.543"
          x2="68.498"
          y2="134.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_818_4477"
          x1="-13.5008"
          y1="144.676"
          x2="67.7673"
          y2="134.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_818_4477"
          x1="-11.1706"
          y1="144.011"
          x2="68.6636"
          y2="133.534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_818_4477"
          x1="-2.22228"
          y1="147.796"
          x2="65.4531"
          y2="138.915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_818_4477"
          x1="-15.652"
          y1="137.265"
          x2="43.6556"
          y2="129.482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_818_4477"
          x1="-15.1493"
          y1="145.717"
          x2="68.8426"
          y2="134.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_818_4477"
          x1="28.5592"
          y1="72.4889"
          x2="92.0779"
          y2="82.6486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_818_4477"
          x1="28.338"
          y1="72.3667"
          x2="92.3783"
          y2="82.6098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_818_4477"
          x1="28.7258"
          y1="71.9074"
          x2="92.2966"
          y2="82.0754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_818_4477"
          x1="29.7372"
          y1="72.9442"
          x2="92.0364"
          y2="82.9089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_818_4477"
          x1="28.7264"
          y1="70.8986"
          x2="86.9567"
          y2="80.2124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_818_4477"
          x1="35.6336"
          y1="78.2227"
          x2="89.345"
          y2="86.8138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_818_4477"
          x1="24.6173"
          y1="44.8849"
          x2="104.459"
          y2="28.2932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_818_4477"
          x1="24.391"
          y1="44.9318"
          x2="104.685"
          y2="28.2461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_818_4477"
          x1="27.615"
          y1="46.0356"
          x2="105.038"
          y2="29.9464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_818_4477"
          x1="28.8202"
          y1="46.1224"
          x2="104.02"
          y2="30.4954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_818_4477"
          x1="25.4365"
          y1="44.1222"
          x2="97.3061"
          y2="29.1871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_818_4477"
          x1="35.7894"
          y1="46.847"
          x2="98.1031"
          y2="33.8977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_818_4477"
          x1="31.3754"
          y1="97.2848"
          x2="-18.0348"
          y2="58.0199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_818_4477"
          x1="30.8436"
          y1="96.2141"
          x2="-17.3365"
          y2="57.9267"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_818_4477"
          x1="29.5992"
          y1="94.8821"
          x2="-17.7282"
          y2="57.2724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_818_4477"
          x1="22.3761"
          y1="93.8604"
          x2="-17.7465"
          y2="61.9762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_818_4477"
          x1="35.099"
          y1="92.2691"
          x2="-0.0619029"
          y2="64.3278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_818_4477"
          x1="31.5063"
          y1="97.5234"
          x2="-18.2916"
          y2="57.9505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_818_4477"
          x1="31.7885"
          y1="154.789"
          x2="19.3663"
          y2="113.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_818_4477"
          x1="31.9327"
          y1="155.269"
          x2="18.9838"
          y2="112.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_818_4477"
          x1="32.0784"
          y1="154.468"
          x2="19.6866"
          y2="113.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_818_4477"
          x1="31.2649"
          y1="153.892"
          x2="19.1023"
          y2="113.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_818_4477"
          x1="33.1314"
          y1="154.717"
          x2="21.7097"
          y2="116.615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_818_4477"
          x1="26.7364"
          y1="150.73"
          x2="15.9247"
          y2="114.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_818_4477"
          x1="78.1"
          y1="134.768"
          x2="68.1277"
          y2="101.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_818_4477"
          x1="78.2461"
          y1="135.255"
          x2="67.6959"
          y2="100.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_818_4477"
          x1="78.1532"
          y1="133.933"
          x2="68.2624"
          y2="100.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_818_4477"
          x1="77.5501"
          y1="133.466"
          x2="67.9738"
          y2="101.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_818_4477"
          x1="79.1881"
          y1="134.511"
          x2="70.0951"
          y2="104.177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_818_4477"
          x1="74.1886"
          y1="130.65"
          x2="65.9747"
          y2="103.249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_818_4477"
          x1="11.8166"
          y1="149.472"
          x2="68.2067"
          y2="173.057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_818_4477"
          x1="5.26511"
          y1="161.182"
          x2="69.2149"
          y2="187.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_818_4477"
          x1="6.42877"
          y1="161.103"
          x2="69.6476"
          y2="187.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_818_4477"
          x1="8.16421"
          y1="161.113"
          x2="70.0794"
          y2="187.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_818_4477"
          x1="12.4749"
          y1="165.745"
          x2="64.2476"
          y2="187.398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_818_4477"
          x1="7.96636"
          y1="154.143"
          x2="53.4159"
          y2="173.152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_818_4477"
          x1="4.67981"
          y1="161.281"
          x2="69.5617"
          y2="188.418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_818_4477"
          x1="64.3665"
          y1="132.999"
          x2="108.305"
          y2="168.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_818_4477"
          x1="64.2409"
          y1="132.871"
          x2="108.706"
          y2="169.192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_818_4477"
          x1="64.2732"
          y1="132.31"
          x2="108.994"
          y2="168.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_818_4477"
          x1="64.5376"
          y1="133.301"
          x2="108.476"
          y2="169.192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_818_4477"
          x1="65.0641"
          y1="131.812"
          x2="106.058"
          y2="165.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_818_4477"
          x1="66.7903"
          y1="139.277"
          x2="104.885"
          y2="170.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_818_4477"
          x1="75.2487"
          y1="115.046"
          x2="133.219"
          y2="134.369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_818_4477"
          x1="74.7725"
          y1="114.888"
          x2="133.695"
          y2="134.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_818_4477"
          x1="76.0608"
          y1="116.43"
          x2="133.361"
          y2="135.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_818_4477"
          x1="76.9189"
          y1="117.028"
          x2="132.641"
          y2="135.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_818_4477"
          x1="75.686"
          y1="114.441"
          x2="128.946"
          y2="132.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_818_4477"
          x1="81.6271"
          y1="121.238"
          x2="128.447"
          y2="136.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_818_4477"
          x1="57.0527"
          y1="147.858"
          x2="41.1411"
          y2="107.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_818_4477"
          x1="56.8754"
          y1="146.34"
          x2="41.7217"
          y2="107.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_818_4477"
          x1="56.5258"
          y1="144.833"
          x2="41.762"
          y2="107.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_818_4477"
          x1="52.2753"
          y1="142.025"
          x2="39.2342"
          y2="108.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_818_4477"
          x1="61.5713"
          y1="146.216"
          x2="50.5802"
          y2="118.147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_818_4477"
          x1="56.9099"
          y1="148.453"
          x2="40.6326"
          y2="106.884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <radialGradient
          id="paint71_radial_818_4477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.5695 2.65235) rotate(180) scale(2.50283 2.50077)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint72_radial_818_4477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(89.6466 40.5469) rotate(180) scale(2.50283 2.50076)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint73_radial_818_4477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.154 76.3291) rotate(180) scale(4.99906 4.99492)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <clipPath id="clip0_818_4477">
          <rect
            width="140"
            height="190"
            fill="white"
            transform="matrix(-1 0 0 -1 140 190)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
