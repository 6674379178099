import React from 'react';
import makeupartist from "../../../src/assets/images/makeup2.png";
import photographers from "../../../src/assets/images/photographer2.png";
import decorators from "../../../src/assets/images/decorators2.png";
import planners from "../../../src/assets/images/planners2.png";
import mandaps from "../../../src/assets/images/mandap2.png";
import caterers from "../../../src/assets/images/catering2.png";
import invitations from "../../../src/assets/images/invitation2.png";
import mehendi from "../../../src/assets/images/mehendi2.png";

interface ImageData {
  img: string;
  title: string;
}

const style = {
  galleryContainer: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 148px)',
    gridAutoFlow: 'column',
    gridAutoColumns: '50%',
    gap: '14px 10px',
    overflowX: 'auto' as const,
    scrollSnapType: 'x mandatory' as const,
    msOverflowStyle: 'none' as const,
    scrollbarWidth: 'none' as const,
    padding: '14px',
    WebkitScrollbar: {
      display: 'none'
    }
  },
  imageWrapper: {
    position: 'relative' as const,
    height: '148px',
    width: '148px',
    scrollSnapAlign: 'start' as const,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain' as const,
  },
  imageTitle: {
    position: 'absolute' as const,
    bottom: '12px',
    left: '12px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
  }
};

const imgDataList: ImageData[] = [
  { img: makeupartist, title: "Makeup artists" },
  { img: photographers, title: "Photographers" },
  { img: decorators, title: "Decorators" },
  { img: planners, title: "Planners" },
  { img: caterers, title: "Caterers" },
  { img: mandaps, title: "Mandaps" },
  { img: invitations, title: "Invitations" },
  { img: mehendi, title: "Mehndi artists" },
];

const TitleBarForMobile: React.FC = () => {
  return (
    <div style={style.galleryContainer}>
      {imgDataList.map((data) => (
        <div key={data.title} style={style.imageWrapper}>
          <img src={data.img} alt={data.title} style={style.image} />
          <div style={style.imageTitle}>{data.title}</div>
        </div>
      ))}
    </div>
  );
}

export default TitleBarForMobile;