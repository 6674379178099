import { Box, Container, Typography } from "@mui/material";
import React from "react";
import TitlebarImageList from "../TitleBarWithImg/TitleBarWithImg";
import "./style.css"
import TitleBarForMobile from "../TitleBarWithImg/TitleBarForMobile";

const OurService = () => {
  return (
    <>
      <Container sx={
        {
          width: {
            xs: "85%",
            md: "80%"
          },
          padding: "0px !important"
          // height:"100%"
          //     marginTop:{
          //   md:"0px",
          //   sm:"60px",
          //   xs:"60px"
          // } 
        }
      }>
        <Box >
          <Typography
            className="title-text"
            variant="h5"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: {
                xs: "20px",
                md: "40px"
              },
              fontStyle: "normal",
              fontWeight: "500",
              padding: {
                sm: "0% 0 0 0",   // Converted 60px top, 10px bottom into percentage assuming full width and height.
                xs: "6% 0 2% 0",   // 60px top, 10px bottom converted
                md: "4% 0 0 0",      // Converted 20px top to 3% and 0px for bottom, left, right
              },

            }}
          >
            Our Services
          </Typography>
        </Box>
        <div className="desktop">
          <TitlebarImageList />
        </div>
        <div className="mobile">
          <TitleBarForMobile />
        </div>
      </Container>
    </>
  );
};

export default OurService;
