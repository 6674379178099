import React, { useEffect, useRef, useState } from "react";
import BannerSection from "../BannerSection/BannerSection";
import PriceBannerSection from "../PriceBannerSection/PriceBannerSection";
import VideoSection from "../VideoSection/VideoSection";
import PartnersSection from "../PartnersSection/PartnersSection";
import FaqSection from "../Faq/FaqSection";
import HowWorkSection from "../HowWorkSection/HowWorkSection";
import HowItWorkForMob from "../HowItWorkForMob/HowItWorkForMob";
import { Box } from "@mui/material";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import gsap from "gsap";
import Scrollbar from "smooth-scrollbar"; // Adjusted import
import { ScrollTrigger } from "gsap/ScrollTrigger";
import OurService from "../OurService/OurService";
import ReactGA from "react-ga4";
import { GOOGLE_TRACKING_ID } from "../../utils/constants";

const Home: React.FC = () => {

  useEffect(() => {
    //api call for google analytics
    if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
      ReactGA.initialize(GOOGLE_TRACKING_ID);
      ReactGA.send({
        hitType: 'pageview', page: window.location.pathname
      })
    }
  }, [])

  const panels = ["blue", "red", "violet", "vilet", "green"];
  const [activePanel, setActivePanel] = useState(0);

  return (
    <>
      <div className="scroller">
        <Header />
        <BannerSection />
        <PriceBannerSection />
        <Box className="how-is-work">
          <Box sx={{ display: { xs: "none", md: "block" }, width: "100%" }}>
            <HowWorkSection activePanelNo={activePanel} />
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <HowItWorkForMob activePanelNo={activePanel} />
          </Box>
        </Box>
        <OurService />
        {/* <VideoSection /> */}
        <PartnersSection />
        <FaqSection />
        <Footer />
      </div>
    </>
  );
};

export default Home;
