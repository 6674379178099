import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import makeupartist from "../../../src/assets/images/makeup.png";
import photographers from "../../../src/assets/images/photographers.png";
import decorators from "../../../src/assets/images/decorators.png";
import planners from "../../../src/assets/images/planners.png";
import mandaps from "../../../src/assets/images/mandap.png";
import caterers from "../../../src/assets/images/catering.png";
import invitations from "../../../src/assets/images/invitation.png";
import mehendi from "../../../src/assets/images/mehendi.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  boxShadow: "none",
  textAlign: "center",
  color: theme.palette.text.secondary,
  position: "relative",
  overflow: "hidden",
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const Title = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 16,
  left: 24,
  right: 0,
  color: "#fff",
  padding: theme.spacing(1),
  textAlignLast: "left",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "normal",
}));

export default function TitleBarWithImg() {
  return (
    <Box sx={{ marginTop: "3%" }}>
      {/* Container for horizontal scrolling only on mobile */}
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          overflowX: { xs: "auto", md: "unset" }, // Horizontal scroll for mobile only
          padding: { xs: "10px", md: 0 },
          gap: { xs: "16px", md: "0px" },
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar on mobile
          msOverflowStyle: 'none', // Hide scrollbar on IE and Edge
          scrollbarWidth: 'none', // Hide scrollbar on Firefox
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 12, sm: 8, md: 12 }} // Set 4 columns for mobile to get two images per row
          sx={{
            flexWrap: { xs: "nowrap", md: "wrap" }, // No wrap for mobile to scroll horizontally
            width: { xs: "max-content", md: "100%" }, // Limit width for mobile to allow scrolling
          }}
        >
          {imgDataList.map((data, index) => (
            <Grid
              key={index}
              item
              xs={8} // 2 items per row on mobile (4 columns / 2 = 2 items)
              sm={2} // 2 items per row on small screens
              md={3} // 4 items per row on desktop
              sx={{ flexShrink: 0 }} // Prevent shrinking on mobile
            >
              <Item>
                <img
                  src={data.img}
                  alt={data.title}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "180px",
                    objectFit: "cover",
                  }}
                />
                <Title>{data.title}</Title>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

const imgDataList = [
  { img: makeupartist, title: "Makeup artists" },
  { img: photographers, title: "Photographers" },
  { img: decorators, title: "Decorators" },
  { img: planners, title: "Planners" },
  { img: caterers, title: "Caterers" },
  { img: mandaps, title: "Mandaps" },
  { img: invitations, title: "Invitations" },
  { img: mehendi, title: "Mehndi artists" },
];
