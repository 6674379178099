import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./style.css";
import EnterYourDetailsModal from "../EnterYourDetailsModal/EnterYourDetailsModal";
import { Stack } from "@mui/material";

// Custom Step Icon Component
interface StepIconProps {
  icon: number;
  active?: boolean;
  hover?: boolean; // Added hover
  onClick: () => void; // Added onClick
}

// Custom Step Icon Component
function CustomStepIcon({ icon, onClick, active, hover }: StepIconProps) {
  // You can replace with custom SVGs based on the icon number (1, 2, or 3)

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        background: "#F8F1DE",
        borderRadius: "50%"
      }}
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 24 24"
        fill="none"
        style={{
          position: "absolute", top: "0%", left: "1%",
          border: "2px solid #f7f1eb", borderRadius: "50%"

        }}
      >
        <circle
          cx="12.0001"
          cy="12"
          r="11.2333"
          // fill={active ? "#631041" : "#EFE7E3"}
          fill="#F8F1DE"
        // stroke="#631041"
        />
      </svg> */}

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12.0001"
          cy="12"
          r="11.7333"
        // fill={active ? "#631041" : "#FEFDF4"}
        // fill= "#FEFDF4"
        />
      </svg> */}
      <Typography
        variant="h3"
        sx={{
          color: "#631041",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "160%",
          border: "3px white solid",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          textAlign: "center"
        }}
      >
        {icon}
      </Typography>
    </div>
  );
}

const steps = [
  {
    description: `Our Relationship Manager will reach out to understand your requirements.`,
  },
  {
    description:
      "The Relationship Manager will share the best service providers that match your needs. ",
  },
  {
    description: `Our Relationship Manager will negotiate and get the best price from the service providers you are interested in.`,
  },
  {
    description:
      "Get exclusive discounts on your wedding shopping from the top brands.",
  },
  {
    description: `The Relationship Manager will assist
you throughout your entire wedding
planning journey.`,
  },
];

interface VerticalarStepperMobProps {
  handleStep: (data: number) => void;
  step: number; // Input number from another component
}

export const VerticalarStepperMob: React.FC<VerticalarStepperMobProps> = ({
  step,
  handleStep,
}) => {
  const [activeStep, setActiveStep] = React.useState(step);
  const [hoveredStep, setHoveredStep] = React.useState<number | null>(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    console.log("reset");
  };

  const handleStepClick = (step: number) => {
    handleStep(step);
    setActiveStep(step); // Navigate to clicked step
  };

  React.useEffect(() => {
    setActiveStep(step);
  }, [step]);

  return (
    <Box sx={{
      maxWidth: "90%", height: "auto",
      margin: "auto"
    }} >
      {/* <Stepper activeStep={activeStep} orientation="vertical" sx={{width:"95%", margin:"auto"}}> */}
      {steps.map((step, index) => (
        <Step key={step.description}>
          <StepLabel
            sx={{
              position: "relative",
              padding: "0ch 0ch",
              marginBottom: "15px"
            }}
            StepIconComponent={(props) => (
              <Stack sx={{
                position: "absolute",
                top: "50%",
                transform: "translate(0%, -50%)",
                zIndex: 2,
              }}>
                <CustomStepIcon
                  icon={index + 1}
                  active={index === activeStep}
                  hover={index === hoveredStep} // Pass hover state
                  onClick={() => handleStepClick(index)}
                />
              </Stack>
            )}
            onMouseEnter={() => setHoveredStep(index)} // Handle mouse enter
            onMouseLeave={() => setHoveredStep(null)} // Handle mouse leave
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "left",
                background: "#F8F1DE",
                borderRadius: "16px",
                padding: "10px 0px 10px 24px",
                marginRight: "10px",
              }}
            >
              {step.description}
            </Typography>
          </StepLabel>
          <StepContent>
            {/* Add your step content here if needed */}
          </StepContent>
        </Step>
      ))}
      {/* </Stepper> */}
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};