export const BackgroundMobSvg2: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="90"
      viewBox="0 0 66 90"
      fill="none"
    >
      <g opacity="0.1" clipPath="url(#clip0_645_28412)">
        <path
          d="M-2.3224 66.9423C-2.3224 66.9423 5.66299 60.7381 3.70789 51.6522C1.7528 42.5633 -3.22211 36.8467 -5.9057 34.0619C-8.58928 31.277 -9.09985 29.8893 -9.09985 29.8893C-9.09985 29.8893 -12.3469 34.4713 -13.6109 40.6567C-14.8748 46.8421 -13.1034 60.5443 -2.31928 66.9423H-2.3224Z"
          fill="url(#paint0_linear_645_28412)"
        />
        <path
          d="M-2.3224 66.9423C-2.3224 66.9423 5.66299 60.7381 3.70789 51.6522C1.7528 42.5633 -3.22211 36.8467 -5.9057 34.0619C-8.58928 31.277 -9.09985 29.8893 -9.09985 29.8893C-9.09985 29.8893 -12.3469 34.4713 -13.6109 40.6567C-14.8748 46.8421 -13.1034 60.5443 -2.31928 66.9423H-2.3224Z"
          stroke="url(#paint1_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M-7.6555 32.1459C-7.6555 32.1459 -1.23296 42.035 0.81242 48.4048C2.8578 54.7746 2.49044 61.3914 -1.21739 65.8608"
          stroke="url(#paint2_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M13.9971 36.9624C13.9971 36.9624 19.9402 32.346 18.4832 25.5824C17.0293 18.8188 13.3246 14.565 11.329 12.4927C9.33035 10.4205 8.95054 9.3891 8.95054 9.3891C8.95054 9.3891 6.53469 12.799 5.59451 17.4029C4.65432 22.0068 5.97121 32.2054 13.9971 36.9655V36.9624Z"
          fill="url(#paint3_linear_645_28412)"
        />
        <path
          d="M13.9971 36.9624C13.9971 36.9624 19.9402 32.346 18.4832 25.5824C17.0293 18.8188 13.3246 14.565 11.329 12.4927C9.33035 10.4205 8.95054 9.38911 8.95054 9.38911C8.95054 9.38911 6.53469 12.799 5.59451 17.4029C4.65432 22.0068 5.97121 32.2054 13.9971 36.9655V36.9624Z"
          stroke="url(#paint4_linear_645_28412)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M13.9969 36.9623C13.9969 36.9623 16.9482 30.2049 13.6794 22.5068C10.4105 14.8086 8.95041 9.38587 8.95041 9.38587"
          stroke="url(#paint5_linear_645_28412)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M8.69227 9.76101C8.69227 9.76101 8.2969 17.978 10.5727 24.8572C12.8484 31.7396 13.6454 36.7811 13.6454 36.7811"
          stroke="url(#paint6_linear_645_28412)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M10.0277 11.0675C10.0277 11.0675 14.8065 18.4281 16.3288 23.1695C17.8512 27.9109 17.5772 32.8367 14.8189 36.1591"
          stroke="url(#paint7_linear_645_28412)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M7.32539 12.274C7.32539 12.274 6.26067 21.7349 8.1068 27.8641C9.95294 33.9963 11.824 35.3622 11.824 35.3622"
          stroke="url(#paint8_linear_645_28412)"
          strokeWidth="0.52"
          strokeMiterlimit="10"
        />
        <path
          d="M-2.18243 95.4938C-2.18243 95.4938 -8.24384 77.9191 3.40889 59.9942C15.0647 42.0694 19.4637 24.9166 19.7563 18.9344"
          stroke="url(#paint9_linear_645_28412)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M27.3713 63.6323C18.4893 78.0409 2.77694 75.2624 -0.109005 77.3565C-2.99806 79.4474 -4.24957 83.1262 -3.60202 85.736L-3.69231 85.6828C-3.69231 85.6828 -10.4916 71.5305 -0.821928 61.757C8.84769 51.9804 16.0423 56.2061 29.6252 46.8514C29.6252 46.8514 33.165 54.2402 27.3744 63.6323H27.3713Z"
          fill="url(#paint10_linear_645_28412)"
        />
        <path
          d="M-3.91652 85.139C-3.91652 85.139 -6.76821 74.4278 -0.336327 68.9676C6.09867 63.5073 18.7912 59.0003 24.0276 54.809C29.2672 50.6177 29.6221 46.8514 29.6221 46.8514"
          stroke="url(#paint11_linear_645_28412)"
          strokeMiterlimit="10"
        />
        <path
          d="M-3.32811 81.8447C-3.32811 81.8447 -3.58028 74.6654 1.93944 71.5336C7.46227 68.4018 20.6062 62.0945 25.1858 58.2845C29.7622 54.4777 30.444 49.6362 30.444 49.6362"
          stroke="url(#paint12_linear_645_28412)"
          strokeMiterlimit="10"
        />
        <path
          d="M-0.00635081 77.1689C-0.00635081 77.1689 3.69214 72.7338 15.2515 68.6926C26.8108 64.6513 29.7839 58.594 29.7839 58.594"
          stroke="url(#paint13_linear_645_28412)"
          strokeMiterlimit="10"
        />
        <path
          d="M-6.04579 74.131C-6.04579 74.131 -3.20344 65.6296 3.07902 61.482C8.17222 58.1221 17.6551 54.1246 19.2926 52.2493"
          stroke="url(#paint14_linear_645_28412)"
          strokeMiterlimit="10"
        />
        <path
          d="M27.3713 63.6323C18.4893 78.0409 2.77694 75.2624 -0.109005 77.3565C-2.99806 79.4474 -4.24957 83.1262 -3.60202 85.736L-3.69231 85.6828C-3.69231 85.6828 -10.4916 71.5305 -0.821928 61.757C8.84769 51.9804 16.0423 56.2061 29.6252 46.8514C29.6252 46.8514 33.165 54.2402 27.3744 63.6323H27.3713Z"
          stroke="url(#paint15_linear_645_28412)"
          strokeMiterlimit="10"
        />
        <path
          d="M11.8736 45.1731C11.8736 45.1731 11.8673 35.0371 20.2201 31.0239C28.5728 27.0108 36.1223 27.4546 39.9578 27.8796C43.7964 28.3047 45.2004 27.8609 45.2004 27.8609C45.2004 27.8609 43.5816 33.243 39.4877 38.0344C35.3907 42.829 23.5169 49.8145 11.8767 45.1762L11.8736 45.1731Z"
          fill="url(#paint16_linear_645_28412)"
        />
        <path
          d="M11.8735 45.1731C11.8735 45.1731 11.8673 35.0371 20.2201 31.0239C28.5728 27.0108 36.1223 27.4546 39.9578 27.8796C43.7964 28.3047 45.2004 27.8609 45.2004 27.8609C45.2004 27.8609 43.5816 33.243 39.4877 38.0344C35.3907 42.829 23.5169 49.8145 11.8767 45.1762L11.8735 45.1731Z"
          stroke="url(#paint17_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M11.8735 45.1731C11.8735 45.1731 16.5932 36.4654 27.4271 33.6118C38.2611 30.7613 45.2004 27.8578 45.2004 27.8578"
          stroke="url(#paint18_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M45.0198 28.4391C45.0198 28.4391 36.6546 35.6247 27.4986 38.8596C18.3427 42.0945 12.3529 45.3981 12.3529 45.3981"
          stroke="url(#paint19_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M42.5383 28.0891C42.5383 28.0891 30.8171 29.0517 24.5502 31.3302C18.2833 33.6087 13.296 37.9532 12.0507 43.6323"
          stroke="url(#paint20_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M43.4819 31.9648C43.4819 31.9648 34.3509 40.8881 26.3437 43.9667C18.3366 47.0423 15.3541 46.1734 15.3541 46.1734"
          stroke="url(#paint21_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M15.6904 34.1368C15.9177 34.4713 8.7044 26.4918 16.2228 15.0431C24.1241 4.06628 35.1947 2.01593 39.961 2.07845C44.9608 2.03156 46.8256 1.78777 46.8381 1.73464C46.8536 1.76589 43.6315 7.60748 39.6559 13.1428C35.0702 18.9656 26.817 27.0357 15.6936 34.1368H15.6904Z"
          fill="url(#paint22_linear_645_28412)"
        />
        <path
          d="M15.6904 34.1368C15.9177 34.4713 8.7044 26.4918 16.2228 15.0431C24.1241 4.06628 35.1947 2.01594 39.961 2.07845C44.9608 2.03156 46.8256 1.78777 46.8381 1.73464C46.8536 1.76589 43.6315 7.60748 39.6559 13.1428C35.0702 18.9656 26.817 27.0357 15.6936 34.1368H15.6904Z"
          stroke="url(#paint23_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M15.6905 34.1368C15.7434 34.7244 13.3089 22.3755 25.1671 12.6771C36.8292 3.90999 46.9533 1.92842 46.8381 1.73776"
          stroke="url(#paint24_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M46.5115 2.38161C46.2562 2.35036 34.865 9.97036 26.8422 17.7904C18.2653 25.6823 16.2511 34.0399 16.1857 33.8649"
          stroke="url(#paint25_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M43.3263 2.04091C43.373 1.48769 27.589 4.79761 21.2039 12.1394C14.5323 18.6561 12.3343 27.7576 14.8903 32.8866"
          stroke="url(#paint26_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M44.1919 6.28856C43.7218 6.20729 33.2303 16.334 27.5674 23.0976C21.3877 29.8581 19.1243 32.0209 18.9656 31.9178"
          stroke="url(#paint27_linear_645_28412)"
          strokeWidth="0.7"
          strokeMiterlimit="10"
        />
        <path
          d="M-5.29534 29.3267C-5.12411 42.0507 6.06164 46.3639 7.11391 48.8299C8.16929 51.2991 7.5591 54.1527 6.13636 55.5811H6.2173C6.2173 55.5811 16.0457 49.1081 13.5832 39.0627C11.1237 29.0142 4.86306 28.9423 -0.283077 17.6467C-0.283077 17.6467 -5.40741 21.0316 -5.29534 29.3267Z"
          fill="url(#paint28_linear_645_28412)"
        />
        <path
          d="M6.56926 55.3185C6.56926 55.3185 12.531 49.53 10.4888 43.5165C8.44963 37.5031 2.01152 29.6736 0.252558 24.9416C-1.5064 20.2096 -0.286026 17.6467 -0.286026 17.6467"
          stroke="url(#paint29_linear_645_28412)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M7.45915 52.9681C7.45915 52.9681 10.3856 48.4424 8.03821 44.2855C5.68773 40.1254 -0.345663 30.9614 -1.82755 26.7326C-3.30944 22.5038 -1.88047 19.122 -1.88047 19.122"
          stroke="url(#paint30_linear_645_28412)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M7.11719 48.6705C7.11719 48.6705 6.44473 44.3792 0.557658 37.2905C-5.32942 30.2019 -4.91225 25.1479 -4.91225 25.1479"
          stroke="url(#paint31_linear_645_28412)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1787 49.0581C12.1787 49.0581 13.6233 42.4789 11.1732 37.3718C9.18694 33.2305 4.61986 26.9763 4.28986 25.1354"
          stroke="url(#paint32_linear_645_28412)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M-5.29534 29.3267C-5.12411 42.0507 6.06164 46.3639 7.11391 48.8299C8.16929 51.2991 7.5591 54.1527 6.13636 55.5811H6.2173C6.2173 55.5811 16.0457 49.1081 13.5832 39.0627C11.1237 29.0142 4.86306 28.9423 -0.283077 17.6467C-0.283077 17.6467 -5.40741 21.0316 -5.29534 29.3267Z"
          stroke="url(#paint33_linear_645_28412)"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M6.6527 75.6562C6.60289 75.5687 14.4015 74.8467 17.1006 68.9926C19.7406 63.0416 18.8098 57.647 18.1622 54.8809C17.5178 52.1211 17.7575 51.0928 17.7575 51.0928C17.7575 51.099 13.5765 52.3617 10.1021 55.4716C6.58421 58.5096 2.21949 67.6955 6.6527 75.6593V75.6562Z"
          fill="url(#paint34_linear_645_28412)"
        />
        <path
          d="M6.6527 75.6562C6.60289 75.5687 14.4015 74.8467 17.1006 68.9926C19.7406 63.0416 18.8098 57.647 18.1622 54.8809C17.5178 52.1211 17.7575 51.0928 17.7575 51.0928C17.7575 51.099 13.5765 52.3617 10.1021 55.4716C6.58421 58.5096 2.21949 67.6955 6.6527 75.6593V75.6562Z"
          stroke="url(#paint35_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M6.65281 75.6561C6.603 75.5717 12.9633 71.5992 14.5137 63.9385C16.0049 56.1841 17.7639 51.0958 17.7607 51.0895"
          stroke="url(#paint36_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M17.3096 51.2335C17.3096 51.2335 12.2195 57.4032 10.3516 64.0668C8.47117 70.7085 6.44136 75.3249 6.44447 75.3343"
          stroke="url(#paint37_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M17.7884 53.0212C17.7978 53.04 17.9348 61.4726 16.5463 65.9327C15.1733 70.4178 12.0352 74.0059 7.8075 75.3905"
          stroke="url(#paint38_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M14.613 52.4211C14.613 52.4211 8.19671 59.1879 6.46266 65.1795C4.71615 71.1523 5.63455 73.2652 5.62832 73.2527"
          stroke="url(#paint39_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M30.6493 65.2451C30.6493 65.2451 36.829 65.342 38.6128 60.6068C40.3874 55.8529 39.4565 51.4366 38.893 49.2393C38.3202 47.0296 38.4447 46.1701 38.4447 46.1701C38.4447 46.1701 35.5557 47.4922 33.0589 49.9613C30.6026 52.4961 27.1687 59.0222 30.6461 65.2451H30.6493Z"
          fill="url(#paint40_linear_645_28412)"
        />
        <path
          d="M30.6493 65.2451C30.6493 65.2451 36.829 65.342 38.6128 60.6068C40.3874 55.8529 39.4565 51.4366 38.893 49.2393C38.3202 47.0296 38.4447 46.1701 38.4447 46.1701C38.4447 46.1701 35.5557 47.4922 33.0589 49.9613C30.6026 52.4961 27.1687 59.0222 30.6461 65.2451H30.6493Z"
          stroke="url(#paint41_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M30.6496 65.2451C30.6496 65.2451 35.6805 62.7916 36.5585 56.6281C37.4488 50.4864 38.442 46.167 38.4451 46.1701"
          stroke="url(#paint42_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M38.1332 46.3139C38.155 46.3514 34.7709 51.6273 33.4447 56.6937C32.1527 61.8164 30.4778 64.9795 30.484 64.9857"
          stroke="url(#paint43_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M38.5507 47.7454C38.56 47.761 39.0177 54.6402 38.118 58.1877C37.2432 61.7727 34.9456 64.5106 31.574 65.1576"
          stroke="url(#paint44_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M36.2564 47.4141C36.2906 47.4734 31.8419 53.0587 30.4783 57.3969C29.1489 61.7945 29.8307 63.3604 29.837 63.3729"
          stroke="url(#paint45_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M-3.32204 95.9064C-3.22241 96.072 -2.14836 79.4005 13.3025 73.659C21.0419 70.8085 27.8474 67.9612 32.7912 64.945C37.7754 61.9945 40.8917 58.869 42.165 57.0156"
          stroke="url(#paint46_linear_645_28412)"
          strokeWidth="2.29"
          strokeMiterlimit="10"
        />
        <path
          d="M27.9903 85.7422C15.3071 89.5584 6.64619 82.7636 4.00931 83.1449C1.40667 83.5793 -0.623146 85.8547 -1.09947 87.98C-1.11192 87.9582 -1.12437 87.9363 -1.13682 87.9144C-1.09324 87.9894 -0.676067 75.2061 9.75006 73.0401C20.3256 71.1179 23.4918 76.7282 37.1805 77.7721C37.2023 77.8096 36.3026 83.3324 27.9872 85.7422H27.9903Z"
          fill="url(#paint47_linear_645_28412)"
        />
        <path
          d="M-1.08714 87.4549C-1.08714 87.4549 0.755879 78.6878 7.12239 77.6439C13.4111 76.4656 24.1018 79.4004 29.6713 79.5317C35.2222 79.6317 37.1866 77.7846 37.1804 77.7721"
          stroke="url(#paint48_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M0.394962 85.2328C0.394962 85.2328 2.74232 80.0288 7.56779 80.0726C12.2687 79.9132 23.9308 81.8072 28.9026 81.9104C33.8152 81.9104 36.5082 79.6631 36.5019 79.6506"
          stroke="url(#paint49_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M4.14615 83.0543C4.17105 83.0949 8.0252 81.129 17.4956 83.4419C27.0158 85.836 31.9938 84.1888 31.9595 84.1326"
          stroke="url(#paint50_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M1.50654 79.0411C1.47541 78.9911 6.46277 74.2653 12.4526 74.3966C17.2314 74.3778 25.5467 76.3094 27.5392 76.0406"
          stroke="url(#paint51_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M27.9903 85.7422C15.3071 89.5584 6.6462 82.7636 4.00931 83.1449C1.40667 83.5793 -0.623146 85.8547 -1.09947 87.98C-1.11192 87.9582 -1.12437 87.9363 -1.13682 87.9144C-1.09324 87.9894 -0.676069 75.2061 9.75006 73.0401C20.3256 71.1179 23.4918 76.7282 37.1805 77.7721C37.2023 77.8096 36.3026 83.3324 27.9872 85.7422H27.9903Z"
          stroke="url(#paint52_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5589 68.8863C25.5589 68.8863 30.045 63.2635 37.5416 64.6137C45.0445 65.9733 50.0443 69.4958 52.5348 71.4586C55.0161 73.4058 56.1586 73.7309 56.1555 73.7309C56.1617 73.7434 52.9458 76.4969 47.9958 77.3658C43.0738 78.2785 31.5238 76.5219 25.5558 68.8832L25.5589 68.8863Z"
          fill="url(#paint53_linear_645_28412)"
        />
        <path
          d="M25.5589 68.8863C25.5589 68.8863 30.045 63.2635 37.5416 64.6137C45.0445 65.9733 50.0443 69.4958 52.5348 71.4586C55.0161 73.4058 56.1586 73.7309 56.1555 73.7309C56.1617 73.7434 52.9458 76.4969 47.9958 77.3658C43.0738 78.2785 31.5238 76.5219 25.5558 68.8832L25.5589 68.8863Z"
          stroke="url(#paint54_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5589 68.8863C25.5589 68.8863 32.6944 66.1546 41.4581 69.352C50.2498 72.5932 56.1617 73.7434 56.1555 73.7309"
          stroke="url(#paint55_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M55.8035 74.0278C55.8502 74.109 47.1208 74.8185 39.2381 72.39C31.4053 70.0459 25.7829 69.2176 25.7891 69.2239"
          stroke="url(#paint56_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M54.2319 72.7213C54.2319 72.7213 45.7578 68.2018 40.4249 66.7265C35.1013 65.27 29.7403 65.5076 26.3656 68.1111"
          stroke="url(#paint57_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M53.3286 75.5592C53.3908 75.6624 43.2138 76.7532 36.1748 74.7029C29.1981 72.7588 27.5294 70.9804 27.5263 70.9741"
          stroke="url(#paint58_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M33.0562 64.395C33.0126 64.5919 31.6615 57.175 41.2533 52.9837C50.7952 49.0424 58.2327 50.8615 61.3085 52.6649C64.5151 54.3714 65.7355 54.7621 65.7604 54.7246C65.7791 54.7809 62.283 58.844 57.7439 61.2851C52.8001 63.8667 43.915 65.3826 33.0562 64.395Z"
          fill="url(#paint59_linear_645_28412)"
        />
        <path
          d="M33.0562 64.395C33.0126 64.5919 31.6615 57.175 41.2533 52.9837C50.7952 49.0424 58.2327 50.8615 61.3085 52.6649C64.5151 54.3714 65.7355 54.7621 65.7604 54.7246C65.7791 54.7809 62.283 58.844 57.7439 61.2851C52.8001 63.8667 43.915 65.3826 33.0562 64.395Z"
          stroke="url(#paint60_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M33.0558 64.3949C32.7881 64.6669 36.6671 56.9093 48.1985 55.109C59.1725 53.7213 65.7227 54.8215 65.7632 54.7277"
          stroke="url(#paint61_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M65.3926 55.1809C65.3179 55.2028 56.0125 57.7189 47.4481 59.2847C38.5972 60.8694 33.4604 64.5419 33.5196 64.4606"
          stroke="url(#paint62_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M63.4655 53.7995C63.6678 53.39 52.781 50.8208 45.7202 53.1056C38.8618 54.8715 33.476 59.2347 33.0432 63.3198"
          stroke="url(#paint63_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M62.8523 57.672C62.7091 57.6689 52.6503 61.5227 45.8884 63.0385C38.918 64.5732 36.3278 64.6669 36.2873 64.5794"
          stroke="url(#paint64_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M20.6091 52.3992C15.2824 59.8536 21.0138 66.9985 20.662 68.8519C20.2978 70.6835 18.6229 72.0149 17.0382 72.2369C17.0569 72.2494 17.0756 72.2587 17.0912 72.2712C17.0413 72.1869 26.6861 72.887 29.4413 66.2452C32.0937 59.4316 27.8162 56.6718 28.9307 47.5922C28.9276 47.5859 24.0679 47.5203 20.6091 52.4023V52.3992Z"
          fill="url(#paint65_linear_645_28412)"
        />
        <path
          d="M17.4459 72.265C17.4241 72.2275 24.0677 71.5649 25.3348 67.358C26.5863 63.126 25.5185 55.8154 26.2874 52.2336C27.0626 48.6611 28.9305 47.586 28.9305 47.586"
          stroke="url(#paint66_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M19.0805 71.3149C19.0805 71.3149 23.081 70.0459 23.302 66.711C23.5262 63.3823 23.3425 55.5342 24.099 52.3836C24.8711 49.2613 27.234 47.8079 27.234 47.8048"
          stroke="url(#paint67_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M20.7366 68.7613C20.7366 68.7613 22.178 66.0984 21.2098 59.516C20.2385 52.9306 22.6481 50.1114 22.6481 50.1114"
          stroke="url(#paint68_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M24.0457 71.1679C24.0457 71.1679 27.9777 68.1893 28.5101 64.2199C28.949 61.0162 28.454 55.3591 28.9895 54.1057"
          stroke="url(#paint69_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M20.6091 52.3992C15.2824 59.8536 21.0138 66.9985 20.662 68.8519C20.2978 70.6835 18.6229 72.0149 17.0382 72.2369C17.0569 72.2494 17.0756 72.2587 17.0912 72.2712C17.0413 72.1869 26.6861 72.887 29.4413 66.2452C32.0937 59.4316 27.8162 56.6718 28.9307 47.5922C28.9276 47.5859 24.0679 47.5203 20.6091 52.4023V52.3992Z"
          stroke="url(#paint70_linear_645_28412)"
          strokeWidth="1.52"
          strokeMiterlimit="10"
        />
        <path
          d="M17.319 0.112476C16.8772 0.112476 16.519 0.472108 16.519 0.915735C16.519 1.35936 16.8772 1.71899 17.319 1.71899C17.7609 1.71899 18.1191 1.35936 18.1191 0.915735C18.1191 0.472108 17.7609 0.112476 17.319 0.112476Z"
          fill="url(#paint71_radial_645_28412)"
        />
        <path
          d="M41.8698 18.0624C41.4279 18.0624 41.0697 18.422 41.0697 18.8656C41.0697 19.3093 41.4279 19.6689 41.8698 19.6689C42.3117 19.6689 42.6699 19.3093 42.6699 18.8656C42.6699 18.422 42.3117 18.0624 41.8698 18.0624Z"
          fill="url(#paint72_radial_645_28412)"
        />
        <path
          d="M50.6735 33.868C49.7897 33.868 49.0733 34.5873 49.0733 35.4745C49.0733 36.3618 49.7897 37.0811 50.6735 37.0811C51.5573 37.0811 52.2737 36.3618 52.2737 35.4745C52.2737 34.5873 51.5573 33.868 50.6735 33.868Z"
          fill="url(#paint73_radial_645_28412)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_645_28412"
          x1="5.23555"
          y1="59.0477"
          x2="-16.9707"
          y2="38.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_645_28412"
          x1="5.34272"
          y1="59.0928"
          x2="-17.0436"
          y2="38.19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_645_28412"
          x1="5.77787"
          y1="58.5215"
          x2="-14.5773"
          y2="39.5153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_645_28412"
          x1="19.6213"
          y1="31.0824"
          x2="3.09371"
          y2="15.6501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_645_28412"
          x1="19.7039"
          y1="31.1174"
          x2="3.04183"
          y2="15.5594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_645_28412"
          x1="19.7478"
          y1="30.9102"
          x2="3.20879"
          y2="15.4672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_645_28412"
          x1="19.244"
          y1="30.8514"
          x2="3.03554"
          y2="15.7171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_645_28412"
          x1="20.025"
          y1="30.6912"
          x2="4.8766"
          y2="16.5467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_645_28412"
          x1="16.512"
          y1="30.3497"
          x2="2.5353"
          y2="17.2993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_645_28412"
          x1="-7.82501"
          y1="59.4172"
          x2="24.9487"
          y2="55.1369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_645_28412"
          x1="-6.99556"
          y1="68.9413"
          x2="32.2983"
          y2="63.8094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_645_28412"
          x1="-6.36479"
          y1="68.5305"
          x2="31.9535"
          y2="63.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_645_28412"
          x1="-5.26612"
          y1="68.2156"
          x2="32.3761"
          y2="63.2994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_645_28412"
          x1="-1.0475"
          y1="70.0087"
          x2="30.8618"
          y2="65.8413"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_645_28412"
          x1="-7.37887"
          y1="65.02"
          x2="20.585"
          y2="61.3678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_645_28412"
          x1="-7.1418"
          y1="69.0236"
          x2="32.4608"
          y2="63.8514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_645_28412"
          x1="13.4636"
          y1="34.3368"
          x2="43.4152"
          y2="39.1047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_645_28412"
          x1="13.3593"
          y1="34.2789"
          x2="43.5569"
          y2="39.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_645_28412"
          x1="13.5421"
          y1="34.0614"
          x2="43.5183"
          y2="38.8332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_645_28412"
          x1="14.0187"
          y1="34.5525"
          x2="43.3953"
          y2="39.2289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_645_28412"
          x1="13.5422"
          y1="33.5835"
          x2="41.0001"
          y2="37.9545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_645_28412"
          x1="16.7987"
          y1="37.0528"
          x2="42.1258"
          y2="41.0846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_645_28412"
          x1="11.6054"
          y1="21.2613"
          x2="49.2597"
          y2="13.4737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_645_28412"
          x1="11.4987"
          y1="21.2836"
          x2="49.3665"
          y2="13.4518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_645_28412"
          x1="13.0186"
          y1="21.8063"
          x2="49.5326"
          y2="14.2545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_645_28412"
          x1="13.5869"
          y1="21.8475"
          x2="49.052"
          y2="14.5126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_645_28412"
          x1="11.9916"
          y1="20.8999"
          x2="45.8863"
          y2="13.8898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_645_28412"
          x1="16.8722"
          y1="22.1905"
          x2="46.2602"
          y2="16.1125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_645_28412"
          x1="14.7914"
          y1="46.0822"
          x2="-8.58796"
          y2="27.5918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40003C" />
          <stop offset="1" stopColor="#871A6E" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_645_28412"
          x1="14.5408"
          y1="45.575"
          x2="-8.25654"
          y2="27.5449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_645_28412"
          x1="13.9541"
          y1="44.9441"
          x2="-8.43975"
          y2="27.2331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_645_28412"
          x1="10.5489"
          y1="44.4601"
          x2="-8.43585"
          y2="29.4454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_645_28412"
          x1="16.5468"
          y1="43.7064"
          x2="-0.0901886"
          y2="30.5484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_645_28412"
          x1="14.8531"
          y1="46.1952"
          x2="-8.7097"
          y2="27.5597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B37839" />
          <stop offset="1" stopColor="#FFF99E" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_645_28412"
          x1="14.9858"
          y1="73.321"
          x2="9.0781"
          y2="53.7072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_645_28412"
          x1="15.0538"
          y1="73.5485"
          x2="8.89558"
          y2="53.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_645_28412"
          x1="15.1225"
          y1="73.1688"
          x2="9.2292"
          y2="53.6029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_645_28412"
          x1="14.7389"
          y1="72.8962"
          x2="8.95471"
          y2="53.6923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_645_28412"
          x1="15.6189"
          y1="73.2868"
          x2="10.187"
          y2="55.2527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_645_28412"
          x1="12.604"
          y1="71.3985"
          x2="7.46223"
          y2="54.3277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_645_28412"
          x1="36.8183"
          y1="63.8376"
          x2="32.0757"
          y2="48.0921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_645_28412"
          x1="36.8872"
          y1="64.0681"
          x2="31.8698"
          y2="47.4101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_645_28412"
          x1="36.8434"
          y1="63.4418"
          x2="32.1396"
          y2="47.825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_645_28412"
          x1="36.5589"
          y1="63.2206"
          x2="32.0047"
          y2="48.1004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_645_28412"
          x1="37.3313"
          y1="63.7157"
          x2="33.0069"
          y2="49.3584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_645_28412"
          x1="34.9745"
          y1="61.8869"
          x2="31.0682"
          y2="48.9178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_645_28412"
          x1="5.57048"
          y1="70.8025"
          x2="32.1921"
          y2="81.8838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_645_28412"
          x1="2.48187"
          y1="76.3494"
          x2="32.6723"
          y2="88.9163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_645_28412"
          x1="3.03042"
          y1="76.3119"
          x2="32.8758"
          y2="88.7352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_645_28412"
          x1="3.84869"
          y1="76.3165"
          x2="33.0786"
          y2="88.4836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_645_28412"
          x1="5.88076"
          y1="78.5106"
          x2="30.3225"
          y2="88.6846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_645_28412"
          x1="3.75531"
          y1="73.0151"
          x2="25.2119"
          y2="81.9465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_645_28412"
          x1="2.20594"
          y1="76.3962"
          x2="32.8364"
          y2="89.1463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_645_28412"
          x1="30.344"
          y1="62.9996"
          x2="51.137"
          y2="79.9032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_645_28412"
          x1="30.2848"
          y1="62.939"
          x2="51.327"
          y2="80.0452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_645_28412"
          x1="30.3001"
          y1="62.6731"
          x2="51.4632"
          y2="79.8776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_645_28412"
          x1="30.4246"
          y1="63.1424"
          x2="51.2176"
          y2="80.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_645_28412"
          x1="30.6727"
          y1="62.4375"
          x2="50.0721"
          y2="78.2082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_645_28412"
          x1="31.4867"
          y1="65.9733"
          x2="49.5143"
          y2="80.6288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_645_28412"
          x1="35.4743"
          y1="54.4956"
          x2="62.8293"
          y2="63.5702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_645_28412"
          x1="35.2498"
          y1="54.4206"
          x2="63.0541"
          y2="63.6442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_645_28412"
          x1="35.857"
          y1="55.1508"
          x2="62.8958"
          y2="64.1204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_645_28412"
          x1="36.2616"
          y1="55.4344"
          x2="62.5554"
          y2="64.1569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_645_28412"
          x1="35.6804"
          y1="54.2089"
          x2="60.8125"
          y2="62.5461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_645_28412"
          x1="38.4812"
          y1="57.4284"
          x2="60.5746"
          y2="64.7574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_645_28412"
          x1="26.8961"
          y1="70.038"
          x2="19.3326"
          y2="50.8143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7BA79" />
          <stop offset="1" stopColor="#FFFCD6" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_645_28412"
          x1="26.8125"
          y1="69.3189"
          x2="19.6093"
          y2="51.0109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_645_28412"
          x1="26.6477"
          y1="68.6051"
          x2="19.6298"
          y2="50.7679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_645_28412"
          x1="24.6438"
          y1="67.2751"
          x2="18.4448"
          y2="51.5194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_645_28412"
          x1="29.0264"
          y1="69.2602"
          x2="23.8018"
          y2="55.9812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_645_28412"
          x1="26.8288"
          y1="70.3197"
          x2="19.0915"
          y2="50.6541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </linearGradient>
        <radialGradient
          id="paint71_radial_645_28412"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.7113 1.25642) rotate(180) scale(1.17991 1.18457)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint72_radial_645_28412"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.2621 19.2063) rotate(180) scale(1.17991 1.18457)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <radialGradient
          id="paint73_radial_645_28412"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.458 36.1559) rotate(180) scale(2.3567 2.36601)"
        >
          <stop stopColor="#FFB566" />
          <stop offset="1" stopColor="#B8790E" />
        </radialGradient>
        <clipPath id="clip0_645_28412">
          <rect
            width="66"
            height="90"
            fill="white"
            transform="matrix(-1 0 0 -1 66 90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
