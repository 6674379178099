export const HeadPhoneSvg: React.FC = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2 9.70777V7.99493C2 3.28463 6.28571 2 8 2C9.71429 2 14 3.28463 14 7.99493V9.70777M2 9.70777C2 10.1832 2 10.6964 2.08434 11.0955C2.20954 11.688 2.85714 12.277 3.71429 12.277C4.57143 12.277 5.21903 11.688 5.34423 11.0955C5.42857 10.6964 5.42857 10.1832 5.42857 9.70777C5.42857 9.23233 5.42857 8.71917 5.34423 8.32004C5.21903 7.72752 4.57143 7.13851 3.71429 7.13851C2.85714 7.13851 2.20954 7.72752 2.08434 8.32004C2 8.71917 2 9.23233 2 9.70777ZM14 9.70777C14 10.1832 14 10.6964 13.9157 11.0955C13.7905 11.688 13.1429 12.277 12.2857 12.277C11.4286 12.277 10.781 11.688 10.6558 11.0955C10.5714 10.6964 10.5714 10.1832 10.5714 9.70777C10.5714 9.23233 10.5714 8.71917 10.6558 8.32004C10.781 7.72752 11.4286 7.13851 12.2857 7.13851C13.1429 7.13851 13.7905 7.72752 13.9157 8.32004C14 8.71917 14 9.23233 14 9.70777ZM14 9.70777C14 14.4181 11.8571 13.9899 8 13.9899"
      stroke="#545454"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
  