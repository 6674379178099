export const InstagramSvg: React.FC = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  style={{ display: "flex", alignItems: "center" }}
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M25.0704 8.67432H15.3561C11.7794 8.67432 8.87988 11.5738 8.87988 15.1505V24.8648C8.87988 28.4414 11.7794 31.341 15.3561 31.341H25.0704C28.647 31.341 31.5465 28.4414 31.5465 24.8648V15.1505C31.5465 11.5738 28.647 8.67432 25.0704 8.67432Z"
    stroke="black"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.2136 24.8647C17.531 24.8647 15.3564 22.6901 15.3564 20.0075C15.3564 17.3249 17.531 15.1504 20.2136 15.1504C22.8962 15.1504 25.0707 17.3249 25.0707 20.0075C25.0707 21.2956 24.5589 22.5311 23.6481 23.442C22.7372 24.3529 21.5017 24.8647 20.2136 24.8647Z"
    stroke="black"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M26.6894 12.7218C26.2423 12.7218 25.8799 13.0842 25.8799 13.5313C25.8799 13.9784 26.2423 14.3408 26.6894 14.3408C27.1365 14.3408 27.4989 13.9784 27.4989 13.5313C27.4989 13.0842 27.1365 12.7218 26.6894 12.7218Z"
    stroke="black"
    strokeWidth="2"
    strokeLinecap="round"
  />
</svg>
  );