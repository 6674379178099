import { AppBar, Box, Toolbar, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import appLogo from "../../assets/images/appLogo.svg";
import { HeadPhoneSvg } from "./HeadPhoneSvg";
import "./style.css";
import CustomContainer from "../CustomContainer/CustomContainer";
import { useNavigate } from "react-router-dom";
import useMobile from "../../customHook/useMobile";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();


  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <CustomContainer>
      <AppBar
        position="static"
        className="headerContainer"
        elevation={0}
        sx={{
          width: {
            xs: "85%",
            md: "100%"
          },
          margin: "auto",
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              {/* Logo and Title */}
              <Grid size={{ xs: 7, sm: 6 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid size={{ xs: 10, sm: 6 }}>
                    <img
                      src={appLogo}
                      alt="makemywedding"
                      className="appLogo"
                      style={{ cursor: "pointer" }}
                      onClick={handleLogoClick}
                    />
                    <Typography sx={{
                      marginTop: "-5%",
                      color: "#631041",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal"
                    }}>by matrimony.com group</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Contact Us Section */}
              <Grid size={{ xs: 4, sm: 6 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "flex-end",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <Grid
                    size={{ xs: 4, md: 0 }}
                    sx={{ display: { xs: "block", sm: "none" } }}
                  >
                    <HeadPhoneSvg />
                  </Grid>
                  <Grid size={{ sm: 4 }}>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        textDecorationLine: "none",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                      }}


                    >
                      Contact us
                    </Typography>
                  </Grid>
                  <Grid
                    columns={{ xs: 0, md: 4 }}
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      +91 8939402236
                    </Typography>
                  </Grid>
                </Grid>

                {/* for mobile */}
                <Box
                  sx={{
                    width: "100%",
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="tel:+918939402236"
                    style={{
                      textDecoration: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <HeadPhoneSvg />
                    <Typography
                      sx={{
                        color: "#631041",
                        textDecorationLine: "underline",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        marginLeft: "2%",
                      }}
                      onClick={() => {
                        createCustomerEventLog({
                          event_name: `cliked_on_contact_us_${isMobile ? "mobile" : "desktop"}`,
                          event_type: "click_event"
                        })
                      }}

                    >
                      Contact us
                    </Typography>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </CustomContainer>
  );
};

export default Header;
