import { Box } from "@mui/material";
import bannerImg from "../.././assets/images/bannerImg.png";
import BannerForm from "../BannerForm/BannerForm";
import "./style.css";

const MobileBanner: React.FC = () => {
  return (
    <Box
    className="mobile-banner"
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
<Box
  sx={{
    backgroundImage: `url(${bannerImg})`,
    boxShadow: "0px 8px 40px 0px rgba(224, 196, 117, 0.20)",
    width: "100%",
    height: "400px",
    flexShrink: "0",
    backgroundPosition: "center", // Centers the image better across all screen sizes
    backgroundSize: "cover", // Ensures the image covers the container
    backgroundRepeat: "no-repeat", // Prevents repetition of the image
  }}
></Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <BannerForm />
      </Box>
    </Box>
  );
};

export default MobileBanner;
