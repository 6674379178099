import { gql } from "@apollo/client";
import client from "./apolloClient";

// Define the query as a constant
const GET_ENQUIRY_DATA = gql`
  query FindFirstMakemywedding_enquiries(
    $where: Makemywedding_enquiriesWhereInput
  ) {
    findFirstMakemywedding_enquiries(where: $where) {
      id
      name
      phone
      is_otp_verified
      created_at
      updated_at
    }
  }
`;

// Reusable function to call the query
export const getEnquiryDataUsingMobileNumber = async (mobile: string) => {
  try {
    // Define the mutation variables
    const variables = {
      where: {
        phone: {
          equals: mobile,
        },
      },
    };

    // Execute the query using Apollo Client
    const result = await client.mutate({
      mutation: GET_ENQUIRY_DATA,
      variables,
    });

    // Return the result or data as needed
    return result.data.findFirstMakemywedding_enquiries;
  } catch (error) {
    console.error("Error update enquiry:", error);
    throw new Error("Failed to update enquiry");
  }
};
